import React from "react";
import { InfoBoxesTableHead } from "./InfoBoxesTableHead";
import { InfoBoxesList } from "./InfoBoxesList";

export const InfoBoxesTable = (props) => {
  const { list } = props;

  return (
      <table className="Table">
        <thead className="Thead">
          <InfoBoxesTableHead {...props} />
        </thead>
        <tbody>{!!list && <InfoBoxesList {...props} />}</tbody>
      </table>
  );
};
