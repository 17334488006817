import React, { Fragment } from "react";
import {
  Tooltip,
  TextSearchMarker, Input, ButtonIcon, Loading,
} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";

export const MyProductsList = (props) => {
  const {
    list,

    searchQuery,
    setIsModalObjectView,

    setModalTbProductAdd,
    setModalTbProductView,
    setModalTbProductArticleView,

    setModalObjectEdit,

    exchangeRate,
  } = props;


  const getPackageLength = (object) => {
    return (!!object && ((!!object.length && object.length) || (!!object.wb_product && !!object.wb_product.package_length && object.wb_product.package_length))) || 0;
  }

  const getPackageHeight = (object) => {
    return (!!object && ((!!object.height && object.height) || (!!object.wb_product && !!object.wb_product.package_height && object.wb_product.package_height))) || 0;
  }

  const getPackageWidth = (object) => {
    return (!!object && ((!!object.width && object.width) || (!!object.wb_product && !!object.wb_product.package_width && object.wb_product.package_width))) || 0;
  }

  const getPackageWeight = (object) => {
    return (!!object && ((!!object.weight && object.weight) || (!!object.wb_product && !!object.wb_product.package_weight && object.wb_product.package_weight))) || 0;
  }

  const getPrice = (object) => {
    return (!!object && ((!!object.price && object.price) ||
      (!!object.wb_product && !!object.wb_product.sale_price && object.wb_product.sale_price))) || 0;
  }

  const getAmount = (object) => {
    return (!!object && (!!object.amount && object.amount)) || 0;
  }

  return (
    !!list && (
      <Fragment>
        {list.map(item => (
          !!item && !!item.uuid &&
          <tr
            className={
              "Table__tr Table__tr_padding Table__tr_border Table__tr_hover "
            }
            key={"item_" + item.uuid}
          >
            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
              onClick={() => (
                setIsModalObjectView(item)
              )}
            >
              <div className="Td__text Td__text_center">
                {!!item.wb_product && !item.wb_product.status_update && (
                  <div style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    justifyContent: "flex-start",
                    background: "white",
                    padding: 8,
                    borderRadius: 9,
                    position: "relative",
                  }}>
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "nowrap",
                      justifyContent: "center",
                      alignItems: "center",
                      width: 56,
                      height: 56,
                      marginRight: 12,
                    }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <rect x="4" y="4" width="32" height="32" rx="16" stroke="#2196F3" strokeWidth="4"/>
                      </svg>
                    </div>
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "nowrap",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}>
                      {!!item.wb_product.wb_article_number && (
                          <a
                            target={"_blank"}
                            href={`https://www.wildberries.ru/catalog/${item.wb_product.wb_article_number}/detail.aspx`}
                            style={{
                              cursor: "pointer",
                              color: "#2196f3",
                              textDecoration: "underline",
                              fontSize: 20
                            }}
                          >
                            <TextSearchMarker
                              text={item.wb_product.wb_article_number}
                              search={searchQuery}
                            />
                          </a>
                        ) ||
                        "-"}
                      <span style={{
                        marginTop: 4,
                        fontSize: 12,
                        fontWeight: 400,
                        opacity: .75,
                        textAlign: "left",
                      }}>Поиск товара...</span>
                    </div>
                  </div>
                )}


                {!!item.wb_product && !!item.wb_product.status_update && item.wb_product.status_update !== 200 && (
                  <div style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    justifyContent: "flex-start",
                    background: "white",
                    padding: 8,
                    borderRadius: 9,
                    position: "relative",
                  }}>
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "nowrap",
                      justifyContent: "center",
                      alignItems: "center",
                      width: 56,
                      height: 56,
                      marginRight: 12,
                    }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <rect x="2.99652" y="2.55469" width="34.007" height="34.007" rx="17.0035" stroke="#FC6A6A" strokeWidth="4"/>
                        <path d="M20 21.6667L15.8333 25.8333C15.5972 26.0694 15.3194 26.1875 15 26.1875C14.6805 26.1875 14.4028 26.0694 14.1666 25.8333C13.9305 25.5972 13.8125 25.3194 13.8125 25C13.8125 24.6806 13.9305 24.4028 14.1666 24.1667L18.3333 20L14.1666 15.8333C13.9305 15.5972 13.8125 15.3194 13.8125 15C13.8125 14.6806 13.9305 14.4028 14.1666 14.1667C14.4028 13.9306 14.6805 13.8125 15 13.8125C15.3194 13.8125 15.5972 13.9306 15.8333 14.1667L20 18.3333L24.1666 14.1667C24.4028 13.9306 24.6805 13.8125 25 13.8125C25.3194 13.8125 25.5972 13.9306 25.8333 14.1667C26.0694 14.4028 26.1875 14.6806 26.1875 15C26.1875 15.3194 26.0694 15.5972 25.8333 15.8333L21.6666 20L25.8333 24.1667C26.0694 24.4028 26.1875 24.6806 26.1875 25C26.1875 25.3194 26.0694 25.5972 25.8333 25.8333C25.5972 26.0694 25.3194 26.1875 25 26.1875C24.6805 26.1875 24.4028 26.0694 24.1666 25.8333L20 21.6667Z" fill="#FC6A6A"/>
                      </svg>
                    </div>
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "nowrap",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}>
                      {!!item.wb_product.wb_article_number && (
                          <a
                            target={"_blank"}
                            href={`https://www.wildberries.ru/catalog/${item.wb_product.wb_article_number}/detail.aspx`}
                            style={{
                              cursor: "pointer",
                              color: "#FC6A6A",
                              textDecoration: "underline",
                              fontSize: 20
                            }}
                          >
                            <TextSearchMarker
                              text={item.wb_product.wb_article_number}
                              search={searchQuery}
                            />
                          </a>
                        ) ||
                        "-"}
                      <span style={{
                        marginTop: 4,
                        fontSize: 12,
                        fontWeight: 400,
                        opacity: .75,
                        textAlign: "left",
                      }}>Не удалось найти товар</span>
                    </div>
                  </div>
                )}

                {!!item.wb_product && !!item.wb_product.status_update && item.wb_product.status_update === 200 && (
                  <div style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    justifyContent: "flex-start",
                    background: "white",
                    padding: 8,
                    borderRadius: 9,
                    position: "relative",
                  }}>
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "nowrap",
                      justifyContent: "center",
                      alignItems: "center",
                      width: 56,
                      height: 56,
                      marginRight: 12,
                    }}>
                      {!!item.wb_product.images &&
                        !!item.wb_product.images[0].data && (
                          <div
                            key={item.uuid + ":" + item.wb_product.images[0].uuid}
                            style={{
                              backgroundImage: `url("${ROOT_URL + "/api/integration/wb/product/image/"+ item.wb_product.images[0].uuid}")`,
                              borderRadius: 7,
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              width: 56,
                              height: 56
                            }}
                          >
                          </div>
                        ) || "-"}
                    </div>
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "nowrap",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}>
                      {!!item.wb_product.wb_article_number && (
                          <a
                            target={"_blank"}
                            href={`https://www.wildberries.ru/catalog/${item.wb_product.wb_article_number}/detail.aspx`}
                            style={{
                              cursor: "pointer",
                              color: "#2196f3",
                              textDecoration: "underline",
                              fontSize: 14
                            }}
                          >
                            <TextSearchMarker
                              text={item.wb_product.wb_article_number}
                              search={searchQuery}
                            />
                          </a>
                        ) ||
                        "-"}
                      {!!item.wb_product.name && (
                        <span style={{
                          marginTop: 4,
                          fontSize: 14,
                          fontWeight: 700,
                          textAlign: "left",
                        }}>
                          <TextSearchMarker
                            text={item.wb_product.name}
                            search={searchQuery}
                          />
                        </span>
                      )}
                      {!!item.wb_product.wb_subject && (
                        <span style={{
                          fontSize: 14,
                          fontWeight: 400,
                          textAlign: "left",
                        }}>
                          {item.wb_product.wb_subject.name}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </td>



            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">

                {!!item.wb_product && !!item.wb_product.status_update && item.wb_product.status_update === 200 && (
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    background: "white",
                    padding: "4px 8px",
                    borderRadius: 9,
                    position: "relative",
                    cursor: "pointer",
                  }}
                       onClick={() => setModalObjectEdit(item)}>
                    <div style={{
                      display: "flex",
                      flexFlow: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}>
                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        marginRight: 12
                      }}>
                  <span style={{
                    fontSize: 12,
                    fontWeight: 400,
                    opacity: .75,
                    textAlign: "left",
                  }}>Цена (руб)</span>
                        <span style={{
                          marginTop: 2,
                          fontSize: 14,
                          fontWeight: 700,
                          textAlign: "left",
                        }}>
                          {(getPrice(item) &&
                              new Intl.NumberFormat("ru-RU").format(
                                (getPrice(item) / 100).toFixed(2)
                              )) ||
                            "-"}
                        </span>
                      </div>
                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        justifyContent: "center",
                        alignItems: "flex-start",
                      }}>
                  <span style={{
                    fontSize: 12,
                    fontWeight: 400,
                    opacity: .75,
                    textAlign: "left",
                  }}>Цена закупа (руб)</span>
                        <span style={{
                          marginTop: 2,
                          fontSize: 14,
                          fontWeight: 700,
                          textAlign: "left",
                        }}>
                           {!!item.amount ?
                               new Intl.NumberFormat("ru-RU").format(
                                 (getAmount(item) / 100).toFixed(2)
                               ) : ((!!exchangeRate && !!exchangeRate.cny && !!item.taobao_product && !item.taobao_article_number && !!item.taobao_product.price && (
                                 new Intl.NumberFormat("ru-RU").format(((item.taobao_product.price * exchangeRate.cny) / 10000).toFixed(2))
                          )) || (!!exchangeRate && !!exchangeRate.cny && !item.taobao_product && !!item.taobao_article_number && !!item.taobao_price && (
                             new Intl.NumberFormat("ru-RU").format(((item.taobao_price * exchangeRate.cny) / 10000).toFixed(2))
                           ))) || "-"}
                        </span>
                      </div>
                    </div>
                    <div style={{
                      display: "flex",
                      flexFlow: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}>
                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        marginRight: 12
                      }}>
                  <span style={{
                    fontSize: 12,
                    fontWeight: 400,
                    opacity: .75,
                    textAlign: "left",
                  }}>Габарит ДхВхШ (см)</span>
                        <span style={{
                          marginTop: 2,
                          fontSize: 14,
                          fontWeight: 700,
                          textAlign: "left",
                        }}>
                          {(getPackageLength(item) &&
                              new Intl.NumberFormat("ru-RU").format(
                                (getPackageLength(item) / 10).toFixed(2)
                              )) ||
                            "-"}
                          х
                          {(getPackageHeight(item) &&
                              new Intl.NumberFormat("ru-RU").format(
                                (getPackageHeight(item) / 10).toFixed(2)
                              )) ||
                            "-"}
                          х
                          {(getPackageWidth(item) &&
                              new Intl.NumberFormat("ru-RU").format(
                                (getPackageWidth(item) / 10).toFixed(2)
                              )) ||
                            "-"}
                        </span>
                      </div>
                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        justifyContent: "center",
                        alignItems: "flex-start"
                      }}>
                  <span style={{
                    fontSize: 12,
                    fontWeight: 400,
                    opacity: .75,
                    textAlign: "left",
                  }}>Вес (кг)</span>
                        <span style={{
                          marginTop: 2,
                          fontSize: 14,
                          fontWeight: 700,
                          textAlign: "left",
                        }}>
                          {(getPackageWeight(item) &&
                            new Intl.NumberFormat("ru-RU").format(
                              (getPackageWeight(item) / 1000).toFixed(3)
                            )) || "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

              </div>
            </td>

            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">

                {!!item.wb_product && !!item.wb_product.status_update && item.wb_product.status_update === 200 &&
                  !item.taobao_product && !item.taobao_article_number && (
                  <div style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    justifyContent: "flex-start",
                    background: "#FFE0D6",
                    padding: 6,
                    borderRadius: 9,
                    position: "relative",
                    border: "2px solid #FFE0D6",
                    cursor: "pointer",
                  }}
                       onClick={() => setModalTbProductAdd(item)}>
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "nowrap",
                      justifyContent: "center",
                      alignItems: "center",
                      width: 56,
                      height: 56,
                    }}>
                      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22 26H12C11.4333 26 10.9583 25.8083 10.575 25.425C10.1917 25.0417 10 24.5667 10 24C10 23.4333 10.1917 22.9583 10.575 22.575C10.9583 22.1917 11.4333 22 12 22H22V12C22 11.4333 22.1917 10.9583 22.575 10.575C22.9583 10.1917 23.4333 10 24 10C24.5667 10 25.0417 10.1917 25.425 10.575C25.8083 10.9583 26 11.4333 26 12V22H36C36.5667 22 37.0417 22.1917 37.425 22.575C37.8083 22.9583 38 23.4333 38 24C38 24.5667 37.8083 25.0417 37.425 25.425C37.0417 25.8083 36.5667 26 36 26H26V36C26 36.5667 25.8083 37.0417 25.425 37.425C25.0417 37.8083 24.5667 38 24 38C23.4333 38 22.9583 37.8083 22.575 37.425C22.1917 37.0417 22 36.5667 22 36V26Z" fill="#FF875C"/>
                      </svg>
                    </div>
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "nowrap",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}>
                      <span style={{
                        fontSize: 16,
                        fontWeight: 400,
                        textAlign: "left",
                        color: "#FF6129",
                      }}>Подобрать товар</span>
                    </div>
                  </div>
                  )}

                {!!item.wb_product && !!item.wb_product.status_update && item.wb_product.status_update === 200 &&
                  !!item.taobao_product && !item.taobao_article_number && (
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  justifyContent: "flex-start",
                  background: "white",
                  padding: 8,
                  borderRadius: 9,
                  position: "relative",
                  cursor: "pointer",
                }}
                     onClick={() => setModalTbProductView(item)}>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 56,
                    height: 56,
                    marginRight: 12,
                  }}>
                    {(!!item.taobao_product.image_url && (
                      <div
                        style={{
                          backgroundImage: `url("${item.taobao_product.image_url}")`,
                          borderRadius: 7,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          width: 56,
                          height: 56
                        }}
                      >
                      </div>
                    )) || "-"}
                  </div>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}>

                    {(!!item.taobao_product.taobao_article_number &&
                      <a
                        href={`https://detail.1688.com/offer/${item.taobao_product.taobao_article_number}.html`}
                        style={{
                          cursor: "pointer",
                          color: "#2196f3",
                          fontSize: 14,
                          textDecoration: "underline",
                        }}
                        target={"_blank"}
                      >
                        {item.taobao_product.taobao_article_number}
                      </a>
                    )}

                    <div style={{
                      display: "flex",
                      flexFlow: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      marginTop: 2,
                    }}>
                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        marginRight: 8,
                        color: "#ff6129",
                        padding: "2px 4px",
                        borderRadius: 5,
                        backgroundColor: "#ff612915"
                      }}>
                  <span style={{
                    fontSize: 12,
                    fontWeight: 400,
                    opacity: .75,
                    textAlign: "left"
                  }}>Цена (юань)</span>
                        <span style={{
                          marginTop: 2,
                          fontSize: 16,
                          fontWeight: 700,
                          textAlign: "left",
                        }}>
                           {(!!item.taobao_product.price && (<>{new Intl.NumberFormat("ru-RU").format(
                             (item.taobao_product.price / 100).toFixed(2))}&nbsp;<span style={{
                             fontSize: 17
                           }}>¥</span></>)) || "-"}
                        </span>
                      </div>
                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        padding: 2
                      }}>
                  <span style={{
                    fontSize: 12,
                    fontWeight: 400,
                    opacity: .75,
                    textAlign: "left",
                  }}>Цена (руб)</span>
                        <span style={{
                          marginTop: 2,
                          fontSize: 16,
                          fontWeight: 700,
                          textAlign: "left",
                        }}>
                          {(!!exchangeRate && !!exchangeRate.cny && !!item.taobao_product.price && (<>{new Intl.NumberFormat("ru-RU").format(
                            ((item.taobao_product.price * exchangeRate.cny) / 10000).toFixed(2))}&nbsp;<span style={{
                            fontSize: 17
                          }}>₽</span></>)) || "-"}
                        </span>
                      </div>
                    </div>


                  </div>
                </div>
                  )}


                {!!item.wb_product && !!item.wb_product.status_update && item.wb_product.status_update === 200 &&
                  !item.taobao_product && !!item.taobao_article_number && (
                  <div style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    justifyContent: "flex-start",
                    background: "white",
                    padding: 8,
                    borderRadius: 9,
                    position: "relative",
                    cursor: "pointer",
                  }}
                       onClick={() => setModalTbProductArticleView(item)}
                  >
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "nowrap",
                      justifyContent: "center",
                      alignItems: "center",
                      width: 56,
                      height: 56,
                      marginRight: 12,
                    }}>
                      -
                    </div>
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "nowrap",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}>

                      {(!!item.taobao_article_number &&
                        <a
                          href={`https://detail.1688.com/offer/${item.taobao_article_number}.html`}
                          style={{
                            cursor: "pointer",
                            color: "#2196f3",
                            fontSize: 14,
                            textDecoration: "underline",
                          }}
                          target={"_blank"}
                        >
                          {item.taobao_article_number}
                        </a>
                      )}

                      <div style={{
                        display: "flex",
                        flexFlow: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        marginTop: 2,
                      }}>
                        <div style={{
                          display: "flex",
                          flexDirection: "column",
                          flexWrap: "nowrap",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          marginRight: 8,
                          color: "#ff6129",
                          padding: "2px 4px",
                          borderRadius: 5,
                          backgroundColor: "#ff612915"
                        }}>
                  <span style={{
                    fontSize: 12,
                    fontWeight: 400,
                    opacity: .75,
                    textAlign: "left"
                  }}>Цена (юань)</span>
                          <span style={{
                            marginTop: 2,
                            fontSize: 16,
                            fontWeight: 700,
                            textAlign: "left",
                          }}>
                           {(!!item.taobao_price && (<>{new Intl.NumberFormat("ru-RU").format(
                             (item.taobao_price / 100).toFixed(2))}&nbsp;<span style={{
                             fontSize: 17
                           }}>¥</span></>)) || "-"}
                        </span>
                        </div>
                        <div style={{
                          display: "flex",
                          flexDirection: "column",
                          flexWrap: "nowrap",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          padding: 2
                        }}>
                  <span style={{
                    fontSize: 12,
                    fontWeight: 400,
                    opacity: .75,
                    textAlign: "left",
                  }}>Цена (руб)</span>
                          <span style={{
                            marginTop: 2,
                            fontSize: 16,
                            fontWeight: 700,
                            textAlign: "left",
                          }}>
                          {(!!exchangeRate && !!exchangeRate.cny && !!item.taobao_price && (<>{new Intl.NumberFormat("ru-RU").format(
                            ((item.taobao_price * exchangeRate.cny) / 10000).toFixed(2))}&nbsp;<span style={{
                            fontSize: 17
                          }}>₽</span></>)) || "-"}
                        </span>
                        </div>
                      </div>


                    </div>
                  </div>
                )}

              </div>
            </td>
          </tr>
        ))}
      </Fragment>
    )
  );
};
