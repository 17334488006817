import React from "react";
import {Modal,} from "@vokymlak/kabinet-ui";

function ModalTaobaoSearchLinkView(props) {
  if (!props.link) {
    props.handleClose();
  }

  return (
    !!props.link && (
      <Modal handleClose={() => props.handleClose()}>
        <div className="Form">
          <div className="Form__title">
            Ссылка для подбора товара
          </div>

          <div className="Form__field">
            <div className="Field__text" style={{
              fontSize: 16,
              fontWeight: 400
            }}>
              <a
                target={"_blank"}
                href={`${props.link}`}
                style={{
                  cursor: "pointer",
                  color: "#2196f3",
                  textDecoration: "underline",
                }}
              >
                {props.link}
              </a>
            </div>
          </div>

          <div className="Form__actions"
               style={{
                 justifyContent: "flex-end"
               }}>
            <div
              className="Action__link Action__link_gray"
              onClick={() => props.handleClose()}
            >
              Закрыть
            </div>
          </div>
        </div>
      </Modal>
    )
  );
}

export default ModalTaobaoSearchLinkView;
