import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  isValidateNullText,
  jsonFormatText,
  Modal,
  Input,
  useNotification,
  useObject, Textarea,
} from "@vokymlak/kabinet-ui";

export const ModalEditTariffPromoCode = (props) => {
  const [isDelete, setIsDelete] = useState(false);

  const inputPromoCode = useRef(null);
  const inputPercentDiscount = useRef(null);
  const inputTitle = useRef(null);
  const inputDescription = useRef(null);

  const { notification } = useNotification();

  const {
    object: [tariff],
  } = useObject({
    apiUrl: "/api/tariff-promo-code/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const changeEdit = (event) => {
    event.preventDefault();

    const promoCode = jsonFormatText(inputPromoCode.current.value);
    const percentDiscount = jsonFormatText(inputPercentDiscount.current.value);
    const title = jsonFormatText(inputTitle.current.value);
    const description = jsonFormatText(inputDescription.current.value);

    if (
      // isValidateNullText(tariffCost) ||
      // isValidateNullText(requestCount) ||
      isValidateNullText(promoCode) ||
      isValidateNullText(percentDiscount) ||
      isValidateNullText(title)
    ) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      promo_code: promoCode,
      percent_discount: numberConvert(percentDiscount),
      title: title,
      description: description,
    });

    axios
      .put("/api/tariff-promo-code/" + props.object.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };


  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("/api/tariff-promo-code/" + props.object.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Промо-код удалён", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!tariff && (
      <Modal handleClose={() => props.handleClose()}>
        {!isDelete && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div
              className="Form__title"
              style={{fontSize: 19, fontWeight: 700}}
            >
              {(!!tariff.title && tariff.title) || "-"}
              <div className="Text__signature">Промо-код</div>
            </div>

            <div className="Form__field">
              <Input reference={inputTitle} label="Название *"
                     value={!!tariff.title && tariff.title}/>
            </div>

            <div className="Form__field">
              <Textarea reference={inputDescription} label="Описание *"
                        value={!!tariff.description && tariff.description}/>
            </div>

            <div className="Form__field">
              <Input reference={inputPromoCode} label="Промо-код *"
                     value={!!tariff.promo_code && tariff.promo_code}/>
            </div>

            <div className="Form__field">
              <Input reference={inputPercentDiscount} label="Скидка по промо-коду, % *" type={"number"}
                     value={!!tariff.percent_discount && (tariff.percent_discount / 100)}/>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            <div className="Form__actions">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>
              <div className="Action__link" onClick={() => props.handleClose()}>
                Отмена
              </div>
            </div>
          </form>
        )}
        {!!isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div
              className="Form__title"
              style={{fontSize: 19, fontWeight: 700}}
            >
              {(!!tariff.tariff_type && tariff.tariff_type.name) || "-"}
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Продолжить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsDelete(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
};
