import React, { useEffect, useRef, useState } from "react";
import {useList, useAction} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";

function useWbReturnCost() {
  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
  } = useList({
    apiUrl: "/api/wb-return",
    rootUrl: ROOT_URL,
  });

  const { setAction } = useAction("info", () => updateList());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "info"]);
    } else {
      setAction("info");
    }
  }, [list]);

  const [modalImport, setModalImport] = useState(null);
  const [isModalKeyEdit, setIsModalKeyEdit] = useState(null);

  return {
    list,
    setList,
    count,

    setIsUpdateList,
    isUpdateList,

    setModalImport,
    modalImport,

    isModalKeyEdit,
    setIsModalKeyEdit,
  };
}

export default useWbReturnCost;
