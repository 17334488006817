import React, {useRef, useState} from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Input,
  Modal,
  jsonFormatText,
  isValidateNullText, Select, useList, getAccountFio, Textarea,
} from "@vokymlak/kabinet-ui";

export const ModalAddTariff = (props) => {
  const {
    list: [listTariffs],
  } = useList({
    apiUrl: "/api/tariffs/list",
    rootUrl: ROOT_URL,
  });

  const {
    list: [listClients],
  } = useList({
    apiUrl: "/api/clients/list",
    rootUrl: ROOT_URL,
  });

  const inputTariffDate = useRef(null);
  const selectClientId = useRef(null);
  const selectTariffId = useRef(null);
  const inputCountMonths = useRef(null);
  const inputFinalAmount = useRef(null);
  const inputNote = useRef(null);

  const { notification } = useNotification();

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const changeAdd = (event) => {
    event.preventDefault();

    const tariffDate = inputTariffDate.current.value;
    const clientId = selectClientId.current.value;
    const tariffId = selectTariffId.current.value;
    const countMonths = jsonFormatText(inputCountMonths.current.value);
    const finalAmount = jsonFormatText(inputFinalAmount.current.value);
    const note = jsonFormatText(inputNote.current.value);

    if (clientId.length === 0 || clientId === "null" ||
      tariffId.length === 0 || tariffId === "null") {
      notification("Укажите клиента и тариф", "red");
      return;
    }

    if (
      isValidateNullText(countMonths) ||
      isValidateNullText(tariffDate)
    ) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      date_start: !!tariffDate && tariffDate.length > 0 ? tariffDate : null,
      count_months: Number(countMonths),
      final_amount: numberConvert(finalAmount),
      note: note,
      client_id:
        clientId.length > 0
          ? clientId === "null"
            ? null
            : Number(clientId)
          : null,
      tariff_id:
        tariffId.length > 0
          ? tariffId === "null"
            ? null
            : Number(tariffId)
          : null,
    });

    axios
      .post("/api/tariff-selling/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Тариф оформлен", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getTariffsSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.id,
        title: item.tariff_type.name,
      };

      options.push(option);
    });

    return options;
  };


  const getAccountsSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.account.id,
        title: getAccountFio(item.account),
      };

      options.push(option);
    });

    return options;
  };

  return (
    !!listClients && !!listTariffs && (
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">
          Новое
          <div className="Text__signature">Оформление тарифа</div>
        </div>

        <div className="Form__field">
          <Select
            isFixed={true}
            reference={selectClientId}
            options={
              !!listClients
                ? getAccountsSelectOptions(listClients)
                : getAccountsSelectOptions([])
            }
            label="Клиент *"
          />
        </div>

        <div className="Form__field">
          <Select
            isFixed={true}
            reference={selectTariffId}
            options={
              !!listTariffs
                ? getTariffsSelectOptions(listTariffs)
                : getTariffsSelectOptions([])
            }
            label="Тариф *"
          />
        </div>

        <div className="Form__field">
          <Input
            reference={inputTariffDate}
            type="date"
            isFixed={true}
            label="Дата начала тарифа"
          />
        </div>

        <div className="Form__field">
          <Input reference={inputCountMonths} label="Количество месяцев *" type={"number"}/>
        </div>

        <div className="Form__field">
          <Input reference={inputFinalAmount} label="Финальная стоимость" type={"number"}/>
        </div>

        <div className="Form__field">
          <Textarea reference={inputNote} label="Примечание"/>
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Оформить
          </button>
        </div>
      </form>
    </Modal>
    )
  );
};
