import React, { useState } from "react";
import axios from "axios";
import {
  getErrorMessageByKey,
  useNotification,
  Modal, Loading,
} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";
import styleTumbler from "../../../styles/modules/Tumbler.module.scss";
import {toast} from "sonner";

function ModalMpstatsSubjectsImport(props) {
  const [isRequest, setIsRequest] = useState(false);

  const { notification } = useNotification();

  const [tumbler, setTumbler] = useState("summary");

  const changeImport = () => {
    setIsRequest(true)

    if (!!props.list) {
      props.list.forEach(async item => {
        await axios
          .get(
            "/api/mpstats-subjects/import/"+tumbler+"/"+item.wb_subject.wb_number,
            {
              withCredentials: false,
              baseURL: ROOT_URL,
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("tokenSession"),
              },
            }
          )
          .then(() => {
            props.successfulRequest();
            toast.success("Успешный импорт данных");
          })
          .catch((error) => {
            toast.error(getErrorMessageByKey(error.response.data.error));
          });
      });

      setIsRequest(false);
      toast.success("Импорт начался");
      props.handleClose();
    }
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <div className="Form">
        <div className="Form__title">
          Импорт ({(!!props.list && props.list.length) || "0"})
          <div className="Text__signature">MPSTATS данные</div>
        </div>

        <div className="Form__field">
          <ul className={styleTumbler._} style={{
            justifyContent: "space-between"
          }}>
            <li className={styleTumbler._link}>
              <a
                className={
                  styleTumbler._text +
                  ((!!tumbler &&
                      tumbler === "summary" &&
                      " " + styleTumbler.active + " ") ||
                    "")
                }
                onClick={() => setTumbler("summary")}
                style={{
                  cursor: "pointer",
                }}
              >
                Суммарный
              </a>
            </li>

            <li className={styleTumbler._link}>
              <a
                className={
                  styleTumbler._text +
                  ((!!tumbler &&
                      tumbler === "sellers" &&
                      " " + styleTumbler.active + " ") ||
                    "")
                }
                onClick={() => setTumbler("sellers")}
                style={{
                  cursor: "pointer",
                }}
              >
                Продавцы
              </a>
            </li>

            <li className={styleTumbler._link}>
              <a
                className={
                  styleTumbler._text +
                  ((!!tumbler &&
                      tumbler === "geography" &&
                      " " + styleTumbler.active + " ") ||
                    "")
                }
                onClick={() => setTumbler("geography")}
                style={{
                  cursor: "pointer",
                }}
              >
                География
              </a>
            </li>
          </ul>
        </div>

        <div className="Form__field Form__field_last">
          <ul className={styleTumbler._} style={{
            justifyContent: "space-between"
          }}>
            <li className={styleTumbler._link}>
              <a
                className={
                  styleTumbler._text +
                  ((!!tumbler &&
                      tumbler === "subject" &&
                      " " + styleTumbler.active + " ") ||
                    "")
                }
                onClick={() => setTumbler("subject")}
                style={{
                  cursor: "pointer",
                }}
              >
                Товары
              </a>
            </li>
            <li className={styleTumbler._link}>
              <a
                className={
                  styleTumbler._text +
                  ((!!tumbler &&
                      tumbler === "check-rates" &&
                      " " + styleTumbler.active + " ") ||
                    "")
                }
                onClick={() => setTumbler("check-rates")}
                style={{
                  cursor: "pointer",
                }}
              >
                Реклама
              </a>
            </li>
          </ul>
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue"
                  onClick={!isRequest ? () => changeImport() : () => null}>
            {!isRequest
              ? "Импортировать"
              : <Loading size="medium" color="blue" />}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalMpstatsSubjectsImport;
