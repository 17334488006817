import React from "react";
import ModalWbReturnCostImport from "./ModalWbReturnCostImport";
import {
  ButtonIcon,
} from "@vokymlak/kabinet-ui";
import { WbReturnCostTable } from "./WbReturnCostTable";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import ModalWbReturnCostEdit from "./ModalWbReturnCostEdit";

function WbReturnCostPage(props) {
  const {
    list,
    setList,
    count,

    setIsUpdateList,
    isUpdateList,

    setModalImport,
    modalImport,

    setIsModalKeyEdit,
    isModalKeyEdit,
  } = props;

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="Page">
        <div
          className="Page__title"
          style={{
            marginTop: 0,
            marginBottom: 6,
          }}
        >
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
                marginRight: 6,
              }}
            >
              <div>
                WB стоимость возврата товара продавцу
                {!!list && (
                  <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                    ({count})
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="Title__settings">
            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 12 }}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            <ButtonIcon
              tooltipText={"Редактировать"}
              iconName={"custom-edit"}
              iconColor={"#202020"}
              size={36}
              style={{ marginLeft: 12 }}
              onClick={() => setIsModalKeyEdit(true)}
            />

            <ButtonIcon
              tooltipText={"Импорт"}
              iconName={"custom-cloud-upload"}
              iconColor={"#202020"}
              size={26}
              style={{ marginLeft: 12 }}
              onClick={() => setModalImport(true)}
            />
          </div>
        </div>

        {!!list && (
          <div
            className="Page__content"
            style={{
              position: "relative",
              height: "calc(100% - 48px - 12px)",
            }}
          >
            <div
              style={{
                overflow: "auto",
                width: "100%",
                height: "100%",
              }}
            >
              <WbReturnCostTable {...props} />
            </div>
          </div>
        )}

        {modalImport && (
          <ModalWbReturnCostImport
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalImport(false)}
          />
        )}

        {isModalKeyEdit && (
          <ModalWbReturnCostEdit
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalKeyEdit(false)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default WbReturnCostPage;
