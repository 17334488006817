import React, {useEffect, useRef, useState} from "react";
import {useAction, useList, useLocalStorage, useObject, useUnroll} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";
import {useParams} from "react-router-dom";

function useMyCalculation(myTariff) {
  let { calculationUuid } = useParams();

  const [filterMyCalculation, setFilterMyCalculation] = useLocalStorage(
    "calculation",
    {
      isFbo: true,
      fulfillmentPrice: 2000,
      taxPercent: 700,
      deliveryPreUsdPrice: 400,
      marketingPercent: 2000,
      additionalExpenses: 0,
    }
  );

  const [isFbo, setIsFbo] = useState(filterMyCalculation.isFbo);
  const [fulfillmentPrice, setFulfillmentPrice] = useState(filterMyCalculation.fulfillmentPrice || 2000);
  const [taxPercent, setTaxPercent] = useState(filterMyCalculation.taxPercent || 700);
  const [deliveryPreUsdPrice, setDeliveryPreUsdPrice] = useState(filterMyCalculation.deliveryPreUsdPrice || 400);
  const [marketingPercent, setMarketingPercent] = useState(filterMyCalculation.marketingPercent || 2000);
  const [additionalExpenses, setAdditionalExpenses] = useState(filterMyCalculation.additionalExpenses || 0);


  const [selectStorage, setSelectStorage] = useState(null);

  useEffect(() => {
    setFilterMyCalculation({
      isFbo,
      fulfillmentPrice,
      taxPercent,
      deliveryPreUsdPrice,
      marketingPercent,
      additionalExpenses,
    });
  }, [
    isFbo,
    fulfillmentPrice,
    taxPercent,
    deliveryPreUsdPrice,
    marketingPercent,
    additionalExpenses,
  ]);

  const [isModalFilterEdit, setIsModalFilterEdit] = useState(false);

  const {
    list: [wbDelivery],
  } = useList({
    apiUrl: "/api/wb-delivery",
    rootUrl: ROOT_URL,
  });

  const {
    list: [wbReturnCost],
  } = useList({
    apiUrl: "/api/wb-return",
    rootUrl: ROOT_URL,
  });

  const {
    object: [exchangeRate],
  } = useObject({
    apiUrl: "/api/info-exchange-rate",
    rootUrl: ROOT_URL,
  });

  const {
    object: [object],
    update: [isUpdateObject, setIsUpdateObject],
  } = useObject({
    apiUrl: "/api/my/calculation/" + calculationUuid,
    rootUrl: ROOT_URL,
  });

  const {
    list: [list, count, setList, allCount],
    update: [isUpdateList, setIsUpdateList, updateList],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
  } = useList({
    apiUrl: "/api/my/calculations/" + calculationUuid,
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 25,
    isSearch: true,
  });

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const inputSearchText = useRef(null);
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  const onChangeSearchText = () => {
    setSearchQuery(inputSearchText.current.value);
  };

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  const { setAction } = useAction("my-calculation", () => updateList());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "my-calculation"]);
    } else {
      setAction("my-calculation");
    }
  }, [list]);

  return {
    exchangeRate,

    wbDelivery,
    wbReturnCost,

    object,

    list,
    setList,
    count,
    allCount,

    setIsUpdateList,
    isUpdateList,

    isUpdateObject,
    setIsUpdateObject,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    isFbo,
    setIsFbo,

    fulfillmentPrice,
    setFulfillmentPrice,
    taxPercent,
    setTaxPercent,
    deliveryPreUsdPrice,
    setDeliveryPreUsdPrice,
    marketingPercent,
    setMarketingPercent,
    additionalExpenses,
    setAdditionalExpenses,

    isModalFilterEdit,
    setIsModalFilterEdit,
    filterMyCalculation,
    setFilterMyCalculation,

    selectStorage,
    setSelectStorage,
  };
}

export default useMyCalculation;
