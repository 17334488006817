import React, {Fragment, useState} from "react";
import {ModalAddTariff} from "./ModalAddTariff";
import {ModalEditTariff} from "./ModalEditTariff";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {ButtonIcon, getAccountFio, Loading, Pagination, Search, Select, TextSearchMarker,} from "@vokymlak/kabinet-ui";
import moment from "moment";
import ModalTariffsSellingScheduler from "./ModalTariffsSellingScheduler";

function isDateBeforeToday(date) {
  const currentDate = moment();
  const inputDate = moment(date, 'YYYY-MM-DD');

  return inputDate.isBefore(currentDate);
}

export const TariffsSellingPage = (props) => {
  const {
    profile,

    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,

    modalEditTariff,
    setModalEditTariff,
    isModalAddTariff,
    setIsModalAddTariff,
  } = props;


  const [isModalScheduler, setIsModalScheduler] = useState(false);

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Оформление тарифов
            {!!list && (
              <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({count})
              </span>
            )}
            <span style={{width: 220, marginLeft: 16}}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{marginLeft: 8}}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 8}}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 100,
                      title: 100,
                    },
                    {
                      value: 1000,
                      title: 1000,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{marginLeft: 8}}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}

            <ButtonIcon
              tooltipText={"Планировщик"}
              iconName={"custom-priority"}
              iconColor={"#202020"}
              size={24}
              style={{marginLeft: 12}}
              onClick={() => setIsModalScheduler(true)}
            />

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{marginLeft: 12}}
              onClick={() => setIsModalAddTariff(true)}
            />
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {list.map((item) => (
                <Fragment key={"item_" + item.uuid}>
                  <li
                    className={
                      isUnroll(item.uuid) ? "List__item active" : "List__item"
                    }
                    onClick={() => changeUnroll(item.uuid)}
                    style={{
                      padding: "10px 12px",
                      position: "relative",
                    }}
                  >
                    <div className="Item__title">
                      <div className="Element__title" style={{
                        flexDirection: "column",
                        alignItems: "flex-start"
                      }}>
                        <div style={{}}>
                          {!!item.client && getAccountFio(item.client)}
                        </div>

                        <div style={{
                          backgroundColor:
                            item.payment_type !== 'CASH' && item.payment_type !== 'CARD' &&
                            item.payment_type !== 'ONLINE'
                              ? item.payment_type === 'CHECK'
                                ? "#0064fc33"
                                : item.payment_type === 'PROMO'
                                  ? "rgba(165, 35, 197,0.2)"
                                  : item.payment_type === 'TRIAL'
                                    ? "rgb(87,0,248,0.2)"
                                    : 'rgba(175,76,99,0.2)'
                              : "#4CAF5033",
                          padding: "6px 8px 6px",
                          borderRadius: 5,
                          marginBottom: 4
                        }}
                        >
                          <span style={{
                            fontSize: 13,
                            fontWeight: 400,
                            marginRight: 12
                          }}>Оптала
                          </span>
                          <span style={{
                            marginRight: 2,
                            fontSize: 15,
                          }}>
                            {item.payment_type === 'TRIAL' && 'Пробный'}
                            {item.payment_type === 'PROMO' && 'Промо'}
                            {item.payment_type === 'CASH' && 'Наличными'}
                            {item.payment_type === 'CARD' && 'Картой'}
                            {item.payment_type === 'ONLINE' && 'Онлайн'}
                            {item.payment_type === 'CHECK' && 'По счёту'}
                            {item.payment_type !== 'TRIAL' &&
                              item.payment_type !== 'PROMO' &&
                              item.payment_type !== 'CHECK' &&
                              item.payment_type !== 'CASH' &&
                              item.payment_type !== 'CARD' &&
                              item.payment_type !== 'ONLINE' &&
                              'Не указана'}
                          </span>
                        </div>

                        {!!item.payment_token && (
                          <div style={{
                            backgroundColor: "#ffc10733",
                            padding: "6px 8px 6px",
                            borderRadius: 5,
                            marginBottom: 4
                          }}>
                            <span style={{
                              fontSize: 13,
                              fontWeight: 400,
                              marginRight: 12
                            }}>Платёж
                            </span>
                            {item.payment_token &&
                              <span style={{marginRight: 8}}>
                                <TextSearchMarker text={item.payment_token}
                                                  search={searchQuery}/></span>}
                          </div>
                        )}

                        <div style={{
                          fontSize: 15,
                          fontWeight: 400
                        }}>
                          {!!item.created_at && moment(item.created_at.substring(0, 19)).format("DD.MM.YYYY HH:mm")}
                          {" "}
                          {!!item.manager && "(" + getAccountFio(item.manager) + ")"}
                        </div>
                      </div>

                      {item.is_paid && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            position: "absolute",
                            top: -8,
                            left: 0,
                            justifyContent: " flex-end",
                            width: "100%",
                            padding: "0 8px",
                          }}
                        >
                          {!!item.date_start && !isDateBeforeToday(item.date_start.substring(0, 19)) && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#0064ff",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              Ожидает
                            </div>
                          )}

                          {!!item.date_start && !!item.date_completion && isDateBeforeToday(item.date_start.substring(0, 19)) && !isDateBeforeToday(item.date_completion.substring(0, 19)) && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#2e7d32",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              Активный
                            </div>
                          )}

                          {!!item.date_completion && isDateBeforeToday(item.date_completion.substring(0, 19)) && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#202020",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              Завершён
                            </div>
                          )}
                        </div>
                      )}

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          position: "absolute",
                          top: -8,
                          right: 0,
                          justifyContent: "flex-start",
                          width: "100%",
                          padding: "0 8px",
                        }}
                      >
                        {!!item.cancelled_at && (
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: 700,
                              backgroundColor: "#ff0000",
                              display: "inline-block",
                              color: "white",
                              padding: "2px 4px 2px",
                              borderRadius: 3,
                              margin: "0 2px",
                            }}
                          >
                            Отменён
                          </div>
                        )}

                        {!item.is_paid && !item.cancelled_at && (
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: 700,
                              backgroundColor: "#0064ff",
                              display: "inline-block",
                              color: "white",
                              padding: "2px 4px 2px",
                              borderRadius: 3,
                              margin: "0 2px",
                            }}
                          >
                            Не оплачен
                          </div>
                        )}

                        {item.is_paid && (
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: 700,
                              backgroundColor: "#ffe082",
                              display: "inline-block",
                              color: "#202020",
                              padding: "2px 4px 2px",
                              borderRadius: 3,
                              margin: "0 2px",
                            }}
                          >
                            Оплачен!
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="Item__settings">
                      <div className="Element__datetime">
                        {item.tariff_promo_code && (
                          <div style={{
                            padding: "4px 8px",
                            backgroundColor: "#C5232333",
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 21,
                              fontWeight: 700,
                            }}>
                              {(!!item.tariff_promo_code.promo_code && (
                                <TextSearchMarker text={item.tariff_promo_code.promo_code.toUpperCase()}
                                                  search={searchQuery}/>
                              )) || "-"}
                            </div>
                            <div style={{
                              fontSize: 10,
                              fontWeight: 400,
                              opacity: .75,
                            }}>
                              Промо-код
                            </div>
                          </div>
                        )}

                        {!!item.is_for_trial && (
                          <div style={{
                            padding: "4px 8px",
                            backgroundColor: "rgb(87,0,248,0.2)",
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 21,
                              fontWeight: 700,
                            }}>
                              Пробный
                            </div>
                            <div style={{
                              fontSize: 10,
                              fontWeight: 400,
                              opacity: .75,
                            }}>
                              период
                            </div>
                          </div>
                        )}

                        <div style={{
                          padding: "4px 8px",
                          backgroundColor: "#20202010",
                          borderRadius: 4,
                          marginLeft: 12,
                          textAlign: "right"
                        }}>
                          <div style={{
                            fontSize: 21,
                            fontWeight: 700,
                          }}>
                            {(!!item.tariff_type && item.tariff_type.name) ||
                              "-"}
                          </div>
                          <div style={{
                            fontSize: 10,
                            fontWeight: 400,
                            opacity: .75,
                          }}>
                            Тариф
                          </div>
                        </div>

                        <div style={{
                          height: 32,
                          width: 2,
                          backgroundColor: "#20202010",
                          borderRadius: 4,
                          marginLeft: 12,
                          textAlign: "right"
                        }}>
                        </div>

                        <div style={{
                          padding: "4px 8px",
                          backgroundColor: "#20202010",
                          borderRadius: 4,
                          marginLeft: 12,
                          textAlign: "right"
                        }}>
                          <div style={{
                            fontSize: 21,
                            fontWeight: 700,
                          }}>
                            {(!!item.final_amount && (<>{new Intl.NumberFormat("ru-RU").format(
                                (item.final_amount / 100).toFixed(2))}&nbsp;<span style={{
                                fontSize: 13,
                                fontWeight: 400
                              }}>руб</span></>)) ||
                              "-"}
                          </div>
                          <div style={{
                            fontSize: 10,
                            fontWeight: 400,
                            opacity: .75,
                          }}>
                            Стоимость
                          </div>
                        </div>

                        <div style={{
                          height: 32,
                          width: 2,
                          backgroundColor: "#20202010",
                          borderRadius: 4,
                          marginLeft: 12,
                          textAlign: "right"
                        }}>
                        </div>

                        {!item.is_for_trial && (
                          <div style={{
                            padding: "4px 8px",
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 21,
                              fontWeight: 700,
                            }}>
                              {(!!item.count_months && item.count_months) ||
                                "-"}
                            </div>
                            <div style={{
                              fontSize: 10,
                              fontWeight: 400,
                              opacity: .75,
                            }}>
                              Месяцев
                            </div>
                          </div>
                        )}

                        {!!item.is_for_trial && (
                          <div style={{
                            padding: "4px 8px",
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 21,
                              fontWeight: 700,
                            }}>
                              {(!!item.count_trial_days && item.count_trial_days) ||
                                "-"}
                            </div>
                            <div style={{
                              fontSize: 10,
                              fontWeight: 400,
                              opacity: .75,
                            }}>
                              Дней
                            </div>
                          </div>
                        )}

                        <div style={{
                          padding: "4px 8px",
                          backgroundColor: "#20202010",
                          borderRadius: 4,
                          marginLeft: 12,
                          textAlign: "right"
                        }}>
                          <div style={{
                            fontSize: 21,
                            fontWeight: 700,
                          }}>
                            {(!!item.date_start && moment(item.date_start.substring(0, 19)).format("DD.MM.YYYY")) ||
                              "-"}
                          </div>
                          <div style={{
                            fontSize: 10,
                            fontWeight: 400,
                            opacity: .75,
                          }}>
                            Дата начала
                          </div>
                        </div>

                        <div style={{
                          padding: "4px 8px",
                          backgroundColor: "#20202010",
                          borderRadius: 4,
                          marginLeft: 12,
                          textAlign: "right"
                        }}>
                          <div style={{
                            fontSize: 21,
                            fontWeight: 700,
                          }}>
                            {(!!item.date_completion && moment(item.date_completion.substring(0, 19)).format("DD.MM.YYYY")) ||
                              "-"}
                          </div>
                          <div style={{
                            fontSize: 10,
                            fontWeight: 400,
                            opacity: .75,
                          }}>
                            Дата завершения
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  {isUnroll(item.uuid) && (
                    <li className="List__content">
                      {profile.uuid !== item.uuid && (
                        <div className="Content__header">
                          <div className="Header__title">
                          </div>
                          <div className="Header__settings">
                            <ButtonIcon
                              tooltipText={"Редактировать"}
                              iconName={"custom-edit"}
                              iconColor={"#202020"}
                              size={36}
                              style={{marginLeft: 12}}
                              onClick={() => setModalEditTariff(item)}
                            />
                          </div>
                        </div>
                      )}
                    </li>
                  )}
                </Fragment>
              ))}
            </ul>
          </div>
        )}

        {!!modalEditTariff && (
          <ModalEditTariff
            object={modalEditTariff}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalEditTariff(null)}
          />
        )}
        {!!isModalAddTariff && (
          <ModalAddTariff
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalAddTariff(false)}
          />
        )}

        {isModalScheduler && (
          <ModalTariffsSellingScheduler
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalScheduler(false)}
          />
        )}
      </div>
    </ScrollBlock>
  );
};
