import React, {Fragment, useState} from "react";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {ButtonIcon, Icon, Loading, Pagination, Search, TextSearchMarker, Tooltip} from "@vokymlak/kabinet-ui";
import {NavLink} from "react-router-dom";
import useMyCalculations from "./useMyCalculations";
import ModalMyProductInfo from "../MyProducts/ModalMyProductInfo";
import ModalMyCalculationsInfo from "./ModalMyCalculationsInfo";
import {ROOT_URL} from "../../../config/url";
import {
  getAmount,
  getPackageHeight,
  getPackageLength, getPackageWeight,
  getPackageWidth,
  getWbPrice
} from "../MyCalculation/housekeeperCalculationNew";

function MyCalculationsPage({myTariff}) {
  const props = useMyCalculations(myTariff);
  const {
    list,
    setList,
    count,
    allCount,

    setIsUpdateList,
    isUpdateList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    inputSearchText,
    onChangeSearchText,
    searchQuery,
  } = props;

  const [isModalInfo, setIsModalInfo] = useState(false);

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
                marginRight: 12,
              }}
            >
              <div>
                Расчёт по категориям
              </div>
            </div>

            <ButtonIcon
              // tooltipText={
              //   "В&nbsp;данном&nbsp;разделе&nbsp;вы&nbsp;сможете&nbsp;получить&nbsp;два\n" +
              //   "расчета&nbsp;юнит-экономики&nbsp;добавленных&nbsp;вами&nbsp;товаров.\n" +
              //   "В&nbsp;первом&nbsp;расчете&nbsp;будет&nbsp;краткий&nbsp;расчет&nbsp;юнит-экономики, " +
              //   "плюс&nbsp;первичные&nbsp;данные&nbsp;по&nbsp;аналитике&nbsp;ниши.\n" +
              //   "Во&nbsp;втором&nbsp;расчете,&nbsp;вы&nbsp;увидите&nbsp;расширенную\n" +
              //   "юнит-экономику&nbsp;включающую&nbsp;в&nbsp;себя&nbsp;ориентировочный&nbsp;объем&nbsp;закупки,\n" +
              //   "основанный&nbsp;на&nbsp;аналитике&nbsp;объема&nbsp;продаж&nbsp;в&nbsp;нише,\n" +
              //   "оборачиваемость&nbsp;в&nbsp;нише,&nbsp;расчет&nbsp;потенциальных&nbsp;продаж,\n" +
              //   "а&nbsp;так&nbsp;же&nbsp;расширенные&nbsp;данные&nbsp;аналитике&nbsp;в&nbsp;нише"
              // }
              tooltipText={
                "О&nbsp;разделе"
              }
              iconName={"custom-info"}
              iconColor={"#20202075"}
              size={22}
              style={{marginRight: 16}}
              onClick={() => setIsModalInfo(true)}
            />

            {(allCount > 0) && (
              <span style={{width: 160, marginRight: 16}}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
            )}
          </div>

          <div className="Title__settings">

            {!!count && count > pageLimit && (
              <div style={{marginLeft: 8}}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}

            {/*<ButtonIcon*/}
            {/*  tooltipText={"Обновить"}*/}
            {/*  iconName={"custom-change-circle"}*/}
            {/*  iconColor={"#20202075"}*/}
            {/*  size={28}*/}
            {/*  style={{ marginLeft: 12 }}*/}
            {/*  onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}*/}
            {/*/>*/}
          </div>
        </div>

        {/*<hr className={"Page__hr"} style={{marginBottom: 6}}/>*/}

        {!list && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!list && list.length > 0 && (
          <div
            className="Page__content"
            style={{
              position: "relative",
              // height: "calc(100% - 48px - 26px - 2px - 10px - 28px)",
            }}
          >

            <ul className="List">
              {list.map((item) => (
                <Fragment key={"item_" + item.uuid}>
                  <NavLink to={"/my/calculation/" + item.uuid}>
                    <li
                      className={
                        isUnroll(item.uuid)
                          ? "List__item active"
                          : "List__item"
                      }
                      // onClick={() => changeUnroll(item.uuid)}
                      style={{paddingLeft: 12}}
                    >
                      <div className="Item__title">
                        <div className="Element__title">
                          <div>
                            <div>
                              {(!!item.name && (
                                <TextSearchMarker
                                  text={item.name}
                                  search={searchQuery}
                                />
                              )) || "-"}
                            </div>
                            <div
                              style={{
                                fontSize: 12,
                                fontWeight: 400,
                              }}
                            >
                              Товаров:&nbsp;{(!!item.count_my_products && item.count_my_products) || "0"}
                            </div>

                          {!!item.top_my_product && (
                          <div className="Item__title" style={{
                            marginTop: 4
                          }}>
                            <div className="Element__title">
                              {!!item.top_my_product.wb_product && !!item.top_my_product.wb_product.status_update && item.top_my_product.wb_product.status_update === 200 && (
                                <div style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  justifyContent: "flex-start",
                                  // background: "white",
                                  // padding: 8,
                                  borderRadius: 9,
                                  position: "relative",
                                }}>
                                  <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flexWrap: "nowrap",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: 36,
                                    height: 36,
                                    marginRight: 8,
                                    // backgroundColor: "white"
                                  }}>
                                    {!!item.top_my_product.wb_product.images &&
                                      !!item.top_my_product.wb_product.images[0].data && (
                                        <div
                                          key={item.uuid + ":" + item.top_my_product.wb_product.images[0].uuid}
                                          style={{
                                            backgroundImage: `url("${ROOT_URL + "/api/integration/wb/product/image/" + item.top_my_product.wb_product.images[0].uuid}")`,
                                            borderRadius: 7,
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "cover",
                                            width: 36,
                                            height: 36
                                          }}
                                        >
                                        </div>
                                      ) || "-"}
                                  </div>
                                  <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flexWrap: "nowrap",
                                    justifyContent: "center",
                                    alignItems: "flex-start",
                                  }}>
                                  <span>
                                  {!!item.top_my_product.wb_product && !!item.top_my_product.wb_product.wb_article_number && (
                                      <span
                                        style={{
                                          // cursor: "pointer",
                                          // color: "#2196f3",
                                          // textDecoration: "underline",
                                          fontSize: 12
                                        }}
                                      >
                                        {item.top_my_product.wb_product.wb_article_number}
                                      </span>
                                    ) ||
                                    "-"}

                                    {(!!item.top_my_product.taobao_product && !!item.top_my_product.taobao_product.taobao_article_number && (
                                      <span style={{fontSize: 12}}>&nbsp;|&nbsp;</span>
                                    ))}

                                    {(!!item.top_my_product.taobao_product && !!item.top_my_product.taobao_product.taobao_article_number &&
                                      <span
                                        style={{
                                          // cursor: "pointer",
                                          // color: "#2196f3",
                                          fontSize: 12,
                                          // textDecoration: "underline",
                                        }}
                                      >
                                        {item.top_my_product.taobao_product.taobao_article_number}
                                      </span>
                                    )}
                                  </span>

                                    {!!item.top_my_product.wb_product.name && (
                                      <span style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        textAlign: "left",
                                      }}>
                                  {item.top_my_product.wb_product.name}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          )}

                          </div>
                        </div>
                      </div>
                      <div className="Item__settings">
                        <div className="Element__datetime">
                          <Tooltip
                            text={"Перейти"}
                            style={{
                              marginLeft: 8,
                              marginRight: 12
                            }}
                          >
                            <div style={{height: 24}}>
                              {/*<NavLink to={"/my/calculation/" + item.uuid}>*/}
                              <Icon
                                name={"custom-circle-arrow-right"}
                                size={24}
                                color={"#202020"}
                              />
                              {/*</NavLink>*/}
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </li>
                  </NavLink>

                  {isUnroll(item.uuid) && (
                    <li className="List__content">
                      <div className="Content__header">
                        <div className="Header__title">

                        </div>
                        <div className="Header__settings">

                          {/*<ButtonIcon*/}
                          {/*  tooltipText={"Редактировать"}*/}
                          {/*  iconName={"custom-edit"}*/}
                          {/*  iconColor={"#202020"}*/}
                          {/*  size={36}*/}
                          {/*  style={{ marginLeft: 12 }}*/}
                          {/*  onClick={() => setModalEditAccount(item.account)}*/}
                          {/*/>*/}
                        </div>
                      </div>
                    </li>
                  )}
                </Fragment>
              ))}
            </ul>

          </div>
        )}

        {(allCount === 0) && (
          <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 28,
            fontWeight: 400
          }}>
            <span style={{
              opacity: .33
            }}>
              Пока нет товаров для расчёта по категориям
            </span>
          </div>
        )}

        {(allCount > 0) &&
          (!!list && list.length === 0) && (
            <div style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              flexWrap: "nowrap",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 28,
              fontWeight: 400,
              opacity: .33,
              marginBottom: 16
            }}>
              По результатам поиска ничего не найдено
            </div>
          )}

        {isModalInfo && (
          <ModalMyCalculationsInfo
            handleClose={() => setIsModalInfo(false)}
          />
        )}

      </div>
    </ScrollBlock>
  );
}

export default MyCalculationsPage;
