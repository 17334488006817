import {useRef, useState} from "react";
import {ROOT_URL} from "../../../config/url";
import axios from "axios";
import {getErrorMessageByKey, useNotification} from "@vokymlak/kabinet-ui";

export default function useParser() {
    const [isRequest, setIsRequest] = useState(false);
    const [link, setLink] = useState(null);

    const {notification} = useNotification();

    const inputTicketFile = useRef(null);
    const [files, setFiles] = useState(null);

    const fileSend = (event) => {
        const files = event.target.files;
        setFiles(Array.from(files));
    };

    const onChangeParser = () => {
        setIsRequest(true)
        setLink(null)

        if (!files || (!!files && files.length === 0)) {
            notification("Прикрепите файл", "red");
            return;
        }

        if (!!files && files.filter(f => f.size > 5242880).length > 0) {
            notification("Один из файлов превышает допустимый размер в 5Мб", "red");
            return;
        }

        if (!!files && files.reduce(function(previousValue, currentValue) {
            return {
                size: previousValue.size + currentValue.size
            }
        }).size > 52428800) {
            notification("Общий размер файлов превышает 50Мб", "red");
            return;
        }

        if (!!files && files.length > 10) {
            notification("Можно прикрепить не больше 10 файлов за раз", "red");
            return;
        }

        let formData = new FormData();
        files.forEach(f => {
            formData.append("files", f);
        })

        axios.post('/api/parser/taobao/products', formData,
            {
                withCredentials: false,
                baseURL: ROOT_URL,
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: 'Bearer ' + localStorage.getItem('tokenSession')
                }
            })
            .then(response => {
                setIsRequest(false)
                setLink(!!response.data ? response.data : null)
            }).catch(error => {
            setIsRequest(false)
            notification(getErrorMessageByKey(error.response.data.error), "red");
        });
    }

    const openLink = (url) => {
        // Открываем ссылку в новом окне браузера.
        // noopener,noreferrer,resizable,scrollbars,status (доп. параметры).
        const newWindow = window.open(url, "_blank", "noopener, noreferrer");
        if (newWindow) {
            newWindow.opener = null;
        }
    };

    return {
        inputTicketFile,
        fileSend,

        link,
        onChangeParser,
        isRequest,
        openLink,
    };
}
