import React from "react";
import { MyProductsTableHead } from "./MyProductsTableHead";
import { MyProductsList } from "./MyProductsList";

export const MyProductsTable = (props) => {
  const { list, exchangeRate } = props;

  return (!!list && !!exchangeRate &&
      <table className="Table">
        <thead className="Thead">
          <MyProductsTableHead {...props} />
        </thead>
        <tbody>
          <MyProductsList {...props} />
        </tbody>
      </table>
  );
};
