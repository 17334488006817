import React, {useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {getErrorMessageByKey, Loading, Modal, useNotification, useObject,} from "@vokymlak/kabinet-ui";
import {useViewer} from "../../../components/Viewer";
import ModalTaobaoSearchLinkView from "./ModalTaobaoSearchLinkView";
import ModalTaobaoProductAdd from "./ModalTaobaoProductAdd";
import {toast} from "sonner";

function ModalTbProductAdd(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/my/product/" + props.object.uuid + "/taobao-offer-result",
    rootUrl: ROOT_URL,
  });

  const [isRequest, setIsRequest] = useState(null);
  const [isRequestLink, setIsRequestLink] = useState(false);
  const [modalTaobaoSearchLink, setModalTaobaoSearchLink] = useState(null);
  const [isModalTaobaoProductAdd, setIsModalTaobaoProductAdd] = useState(false);

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const changeAdd = (item) => {
    setIsRequest(item)

    const jsonData = JSON.stringify({
      taobao_article_number: String(item.id),
      name: item.subject,
      price: !!item.originalPrice ? numberConvert(item.originalPrice) : 0,
      image_url: item.offerPicUrl,
    });

    axios
      .post("/api/my/product/" + props.object.uuid + "/add/taobao-product", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        toast.success("Товар выбран");
        props.handleClose();
      })
      .catch((error) => {
        toast.error(getErrorMessageByKey(error.response.data.error));
      })
      .finally(() => {
        setIsRequest(null);
      });
  };

  const changeLink = () => {
    setIsRequestLink(true);

    axios
      .get("/api/my/product/" + props.object.uuid + "/taobao-search-link", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(response => {
        // console.log(response.data)
        // if (!!response.data) { openLink(response.data.taobao_search_link) }
        // notification("Ссылка открыта", "green");

        setModalTaobaoSearchLink(response.data.taobao_search_link);
      })
      .catch((error) => {
        toast.error(getErrorMessageByKey(error.response.data.error));
      })
      .finally(() => {
        setIsRequestLink(false);
      });
  };

  const {viewer} = useViewer();

  const changeViewer = (image, mimeType) => {
    viewer(image, mimeType);
  };

  const openLink = (url) => {
    // Открываем ссылку в новом окне браузера.
    // noopener,noreferrer,resizable,scrollbars,status (доп. параметры).
    const newWindow = window.open(url, "_blank", "noopener, noreferrer");
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  return (
    <>
      <Modal handleClose={() => props.handleClose()} contentStyle={{
        minWidth: 340,
        maxWidth: 1028,
        width: "auto",
      }}>
        <div className="Form">
          <div className="Form__title">
            {/*Подобрать товар*/}
            {!!props.object && !!props.object.wb_product && !!props.object.wb_product.name && props.object.wb_product.name}
            {/*&nbsp;{(!!props.object && !!props.object.wb_product && !!props.object.wb_product.wb_article_number && "(" + props.object.wb_product.wb_article_number + ")") || ""}*/}
            {/*<div className="Text__signature">Подбор товара</div>*/}
            {(!!props.object && !!props.object.wb_product && !!props.object.wb_product.wb_article_number && (
              <div className="Text__signature">{props.object.wb_product.wb_article_number}</div>
            ))}
          </div>

          <div className="Form__title" style={{
            fontWeight: 400
          }}>
            Подбор товара
          </div>

          <div className="Form__field" style={{
            backgroundColor: "#eee",
            borderRadius: 19,
            display: "inline-block",
            padding: 12
          }}>
            <div style={{
              display: "flex",
            }}>
              <div>
                {!!props.object.wb_product && !!props.object.wb_product.images &&
                  !!props.object.wb_product.images[0].data && (
                    <div
                      style={{
                        cursor: "pointer"
                      }}
                      key={props.object.uuid + ":" + props.object.wb_product.images[0].uuid}
                      onClick={(event) => (event.preventDefault(), changeViewer(props.object.wb_product.images[0].uuid, ROOT_URL + "/api/integration/wb/product/image/" + props.object.wb_product.images[0].uuid))}>
                      <div
                        style={{
                          backgroundImage: `url("${ROOT_URL + "/api/integration/wb/product/image/" + props.object.wb_product.images[0].uuid}")`,
                          borderRadius: 11,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          width: 140,
                          height: 140
                        }}
                      >
                      </div>
                    </div>
                  ) || "-"}
              </div>

              <div style={{
                backgroundColor: "white",
                borderRadius: 11,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "12px 16px",
                marginRight: 12,
                marginLeft: 12,
              }}>
                <div style={{
                  display: "flex",
                  alignItems: "center"
                }}>
                  <div style={{
                    fontSize: 42,
                    fontWeight: 700,
                    marginRight: 12
                  }}>
                    1
                  </div>
                  <div style={{
                    fontSize: 14,
                    fontWeight: 400,
                  }}>
                    Найдите товар <br/>
                    на 1688
                  </div>
                </div>
                <div style={{
                  width: 224
                }}>
                  <button className="Action__button Action__button_all Action__button_blue"
                          onClick={isRequestLink ? () => null : () => changeLink()}>
                    {isRequestLink ?
                      <Loading size="medium" color="blue"/> : "Сформировать ссылку"}
                  </button>
                </div>
              </div>

              <div style={{
                backgroundColor: "white",
                borderRadius: 11,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "12px 16px"
              }}>
                <div style={{
                  display: "flex",
                  alignItems: "center"
                }}>
                  <div style={{
                    fontSize: 42,
                    fontWeight: 700,
                    marginRight: 12
                  }}>
                    2
                  </div>
                  <div style={{
                    fontSize: 14,
                    fontWeight: 400,
                  }}>
                    Укажите артикул <br/>
                    товара с 1688
                  </div>
                </div>
                <div>
                  <button className="Action__button Action__button_all Action__button_blue"
                          onClick={() => setIsModalTaobaoProductAdd(true)}>
                    Добавить товар
                  </button>
                </div>
              </div>
            </div>
          </div>

          <br/>

          <div className="Form__field" style={{
            textAlign: "center",
            marginTop: 16,
            marginBottom: 24,
            opacity: .5,
            width: "100%",
          }}>
            Или выберите товар из представленных ниже
          </div>

          <div className="Form__field Form__field_last" style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignContent: "flex-start",
            justifyContent: "flex-start",
            alignItems: "flex-start"
          }}>
            {!object && <Loading size="medium" color="blue"/>}

            {!!object && !!object.taobao_offer_result && !!object.taobao_offer_result.data && !!object.taobao_offer_result.data.offerList &&
              !!object.taobao_offer_result.data.offerList.offers &&
              object.taobao_offer_result.data.offerList.offers
                .sort((a, b) => (!!a.originalPrice ? Number(a.originalPrice) : 0) - (!!b.originalPrice ? Number(b.originalPrice) : 0))
                .map((item, index) => (
                  <div key={"item_" + index} style={{
                    border: "2px solid #eee",
                    backgroundColor: "#eee",
                    borderRadius: 11,
                    margin: 4,
                    width: 188,
                    padding: 6,
                    display: "flex",
                    flexDirection: "column"
                  }}>
                    {(!!item.offerPicUrl && (
                      <div
                        style={{
                          backgroundImage: `url("${item.offerPicUrl}")`,
                          borderRadius: 5,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          width: 172,
                          height: 172
                        }}
                      >
                      </div>
                    )) || "-"}
                    <span style={{
                      marginTop: 4,
                      fontSize: 8,
                    }}>
                      {(!!item.subject && item.subject) || "-"}
                    </span>

                    {(!!item.id &&
                      <a
                        href={`https://detail.1688.com/offer/${item.id}.html`}
                        style={{
                          cursor: "pointer",
                          color: "#2196f3",
                          marginTop: 4,
                          fontSize: 20,
                          textDecoration: "underline",
                        }}
                        target={"_blank"}
                      >
                        {item.id}
                      </a>
                    )}


                    <div style={{
                      display: "flex",
                      flexFlow: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      marginTop: 8,
                    }}>
                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        marginRight: 12,
                        border: "2px solid #ff6129",
                        color: "#ff6129",
                        padding: 4,
                        borderRadius: 5,
                      }}>
                  <span style={{
                    fontSize: 12,
                    fontWeight: 400,
                    opacity: .75,
                    textAlign: "left"
                  }}>Цена (юань)</span>
                        <span style={{
                          marginTop: 2,
                          fontSize: 20,
                          fontWeight: 700,
                          textAlign: "left",
                        }}>
                           {(!!item.originalPrice && (<>{new Intl.NumberFormat("ru-RU").format(
                             (Number(item.originalPrice)).toFixed(2))}&nbsp;<span style={{
                             fontSize: 17
                           }}>¥</span></>)) || "-"}
                        </span>
                      </div>
                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        padding: 6
                      }}>
                  <span style={{
                    fontSize: 12,
                    fontWeight: 400,
                    opacity: .75,
                    textAlign: "left",
                  }}>Цена (руб)</span>
                        <span style={{
                          marginTop: 2,
                          fontSize: 20,
                          fontWeight: 700,
                          textAlign: "left",
                        }}>
                          {(!!props.exchangeRate && !!props.exchangeRate.cny && !!item.originalPrice && (<>{new Intl.NumberFormat("ru-RU").format(
                            (((Number(item.originalPrice) * 100) * props.exchangeRate.cny) / 10000).toFixed(2))}&nbsp;
                            <span style={{
                              fontSize: 17
                            }}>₽</span></>)) || "-"}
                        </span>
                      </div>
                    </div>

                    <div
                      className={
                        "Action__button Action__button_gray Action__button_mini"
                      }
                      style={{
                        textAlign: "center",
                        marginTop: 8
                      }
                      }
                      onClick={(!!isRequest && (isRequest.id === item.id)) ? () => null : () => changeAdd(item)}
                    >

                      {/*{(!!isRequest && (isRequest.id === item.id)) ?*/}
                      {/*  <Loading size="small" color="blue"/> : "Выбрать"}*/}
                      Выбрать
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </Modal>

      {!!modalTaobaoSearchLink && (
        <ModalTaobaoSearchLinkView
          link={modalTaobaoSearchLink}
          handleClose={() => setModalTaobaoSearchLink(null)}
        />
      )}

      {!!isModalTaobaoProductAdd && (
        <ModalTaobaoProductAdd
          object={props.object}
          successfulRequest={() => (props.successfulRequest(), props.handleClose())}
          handleClose={() => setIsModalTaobaoProductAdd(false)}
        />
      )}
    </>
  );
}

export default ModalTbProductAdd;
