import React from "react";

export const MyCalculationTableHeadNew = (props) => {
  const {
    isFbo
  } = props;

  return (
    <tr className="Table__tr Table__tr_padding Table__tr_border">
      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title">Тип</div>
      </th>

      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title">Закупка</div>
      </th>

      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title">Упаковка</div>
      </th>

      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title">Доставка</div>
      </th>

      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#bbdefb",
        }}
      >
        <div className="Th__title">Себестоимость</div>
      </th>

      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#cc99ff",
        }}
      >
        <div className="Th__title">Комиссия</div>
      </th>

      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#cc99ff",
        }}
      >
        <div className="Th__title">Логистика</div>
      </th>

      {isFbo && (
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#cc99ff",
          }}
        >
          <div className="Th__title">Хранение</div>
        </th>
      )}

      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#cc99ff",
        }}
      >
        <div className="Th__title">Расходы на рекламу</div>
      </th>

      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#bbdefb",
        }}
      >
        <div className="Th__title">Себестоимость с учетом комиссий ВБ</div>
      </th>

      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#c5e1a5",
        }}
      >
        <div className="Th__title">Цена продажи</div>
      </th>

      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#c5e1a5",
        }}
      >
        <div className="Th__title">Выплата на р/с</div>
      </th>

      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#c5e1a5",
        }}
      >
        <div className="Th__title">Налог</div>
      </th>

      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#c5e1a5",
        }}
      >
        <div className="Th__title">Прибыль с единицы</div>
      </th>

      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#bbdefb",
        }}
      >
        <div className="Th__title">Прибыль в процентах</div>
      </th>
    </tr>
  );
};
