import React, { useEffect, useRef, useState } from "react";
import { ROOT_URL } from "../../../config/url";
import {useList, useAction, useObject} from "@vokymlak/kabinet-ui";

function useInfoExchangeRates() {
  const [selectSpecification, setSelectSpecification] = useState(null);

  const {
    object: [object, setObject],
    update: [isUpdateObject, setIsUpdateObject, changeUpdate],
  } = useObject({
    apiUrl: "/api/info-exchange-rate",
    rootUrl: ROOT_URL,
  });

  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
  } = useList({
    apiUrl: "/api/info-exchange-rates",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 25,
    isSearch: true,
  });

  const inputSearchText = useRef(null);
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  const onChangeSearchText = () => {
    setSearchQuery(inputSearchText.current.value);
  };

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  const { setAction } = useAction("info", () => updateList());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "info"]);
    } else {
      setAction("info");
    }
  }, [list]);

  const [modalSpecificationImport, setModalSpecificationImport] = useState(null);

  return {
    object,
    setObject,

    setIsUpdateObject,
    isUpdateObject,

    list,
    setList,
    count,

    setIsUpdateList,
    isUpdateList,

    setModalSpecificationImport,
    modalSpecificationImport,

    selectSpecification,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    inputSearchText,
    onChangeSearchText,
    searchQuery,
  };
}

export default useInfoExchangeRates;
