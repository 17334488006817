import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  isValidateNullText,
  jsonFormatText,
  Modal,
  Input,
  useNotification,
  useObject, getAccountFio,
} from "@vokymlak/kabinet-ui";

export const ModalEditTariff = (props) => {
  const [isAvailable, setIsAvailable] = useState(false);
  const [isForSale, setIsForSale] = useState(false);

  const [isDelete, setIsDelete] = useState(false);

  const inputTariffCost = useRef(null);
  const inputRequestCount = useRef(null);

  const { notification } = useNotification();

  const {
    object: [tariff],
  } = useObject({
    apiUrl: "/api/tariff-selling/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackResponse: (data) => {},
    callbackError: () => {
      props.handleClose();
    },
  });

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  // const changeEdit = (event) => {
  //   event.preventDefault();
  //
  //   const tariffCost = jsonFormatText(inputTariffCost.current.value);
  //   const requestCount = jsonFormatText(inputRequestCount.current.value);
  //
  //   if (
  //     isValidateNullText(tariffCost) ||
  //     isValidateNullText(requestCount)
  //   ) {
  //     notification("Заполните все обязательные поля", "red");
  //     return;
  //   }
  //
  //   const jsonData = JSON.stringify({
  //     tariff_cost: numberConvert(tariffCost),
  //     request_count: Number(requestCount),
  //     is_available: isAvailable,
  //     is_for_sale: isForSale,
  //   });
  //
  //   axios
  //     .put("/api/tariff/" + props.object.uuid + "/edit", jsonData, {
  //       withCredentials: false,
  //       baseURL: ROOT_URL,
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + localStorage.getItem("tokenSession"),
  //       },
  //     })
  //     .then(() => {
  //       props.successfulRequest();
  //       notification("Данные сохранены", "green");
  //       props.handleClose();
  //     })
  //     .catch((error) => {
  //       notification(getErrorMessageByKey(error.response.data.error), "red");
  //     });
  // };


  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("/api/tariff-selling/" + props.object.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Тариф удалён", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!tariff && (
      <Modal handleClose={() => props.handleClose()}>
        {!isDelete && (
          <form className="Form">
            <div
              className="Form__title"
              style={{ fontSize: 19, fontWeight: 700 }}
            >
              {!!tariff.client && getAccountFio(tariff.client)} ({(!!tariff.tariff_type && tariff.tariff_type.name) || "-"})
              <div className="Text__signature">Тариф</div>
            </div>

            <div className="Form__actions">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>
              <div className="Action__link" onClick={() => props.handleClose()}>
                Отмена
              </div>
            </div>
          </form>
        )}

        {!!isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div
              className="Form__title"
              style={{ fontSize: 19, fontWeight: 700 }}
            >
              {!!tariff.client && getAccountFio(tariff.client)} ({(!!tariff.tariff_type && tariff.tariff_type.name) || "-"})
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Продолжить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsDelete(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
};
