const formatPercent = (percent) => {
  if (!!percent && percent > 0) {
    return percent / 100 / 100
  }
  return 0;
}

export const getAnalysisData = (object) => {
  const data = {
    checkRates: null,
    geography: null,
    sellers: null,
    subject: null,
    summary: null,
  }

  if (!!object && !!object.analysis_data && !!object.analysis_data.mpstats_check_rates) {
    data.checkRates = JSON.parse(object.analysis_data.mpstats_check_rates);
  }

  if (!!object && !!object.analysis_data && !!object.analysis_data.mpstats_geography) {
    data.geography = JSON.parse(object.analysis_data.mpstats_geography);
  }

  if (!!object && !!object.analysis_data && !!object.analysis_data.mpstats_sellers) {
    data.sellers = JSON.parse(object.analysis_data.mpstats_sellers);
  }

  if (!!object && !!object.analysis_data && !!object.analysis_data.mpstats_subject) {
    data.subject = JSON.parse(object.analysis_data.mpstats_subject);
  }

  if (!!object && !!object.analysis_data && !!object.analysis_data.mpstats_summary) {
    data.summary = JSON.parse(object.analysis_data.mpstats_summary);
  }

  return data;
}

export const getBatchCount = (analysisData) => {
  if (!!analysisData && !!analysisData.sellers && analysisData.sellers.length > 0) {
    let sales = 0
    let limit = 1

    analysisData.sellers.forEach(seller => {
      if (limit < 100) {
        sales += !!seller.sales ? seller.sales : 0
        limit += 1;
      }
    })

    return Math.round(sales / limit)
  }

  return 0;
}

const getCheckRateCpm = (analysisData) => {
  if (!!analysisData && !!analysisData.checkRates) {
    let cpm = 0

    if (!!analysisData.checkRates.data && analysisData.checkRates.data.length > 0) {
      analysisData.checkRates.data.forEach(check => {
        if (cpm === 0) {
          cpm = check.cpm
        }
      })
    }

    return Math.round(cpm * 1.1 * 100)
  }

  return 0;
}

const getBuyout = (analysisData) => {
  if (!!analysisData.summary &&
    !!analysisData.summary.summary &&
    !!analysisData.summary.summary.buyout &&
    !!analysisData.summary.summary.buyout.with_return) {

    return analysisData.summary.summary.buyout.with_return * 100
  }

  return 0;
}


export const getWbWarehouse = (wbDelivery, wbReturnCost, wbStorageType, selectStorage) => {
  let wbWarehouse = null;
  let wbWarehouseReturn = null;

  if (!!selectStorage) {
    wbDelivery = wbDelivery.filter(w => w.wb_warehouse.name.toLowerCase() === selectStorage.office_name.toLowerCase());
    wbReturnCost = wbReturnCost.filter(w => w.wb_warehouse.name.toLowerCase() === selectStorage.office_name.toLowerCase());
  }

  if (!!wbDelivery) {
    if (wbStorageType === "BOX") {
      wbWarehouse = wbDelivery.filter(w => w.box_delivery_and_storage_visible_expr).reduce((prev, current) => {
        return (prev.box_delivery_and_storage_expr > current.box_delivery_and_storage_expr) ? prev : current;
      });

      wbWarehouse.deliveryBase = wbWarehouse.box_delivery_base
      wbWarehouse.deliveryLiter = wbWarehouse.box_delivery_liter
      wbWarehouse.storageBase = wbWarehouse.box_storage_base
      wbWarehouse.storageLiter = wbWarehouse.box_storage_liter

      wbWarehouse.deliveryExpr = wbWarehouse.box_delivery_and_storage_expr
      wbWarehouse.storageExpr = wbWarehouse.box_delivery_and_storage_expr

      wbWarehouse.returnExpr = 0
    }

    if (wbStorageType === "PALLET") {
      wbWarehouse = wbDelivery.filter(w => w.pallet_visible_expr).reduce((prev, current) => {
        return (prev.pallet_delivery_expr + prev.pallet_storage_expr >
          current.pallet_delivery_expr + current.pallet_storage_expr) ? prev : current;
      });
      wbWarehouse.deliveryBase = wbWarehouse.pallet_delivery_value_base
      wbWarehouse.deliveryLiter = wbWarehouse.pallet_delivery_value_liter
      wbWarehouse.storageBase = wbWarehouse.pallet_storage_value_expr
      wbWarehouse.storageLiter = wbWarehouse.pallet_storage_value_expr

      wbWarehouse.deliveryExpr = wbWarehouse.pallet_delivery_expr
      wbWarehouse.storageExpr = wbWarehouse.pallet_storage_expr

      wbWarehouse.returnExpr = 0
    }
  }

  if (!!wbWarehouse && !!wbReturnCost && wbReturnCost.filter(w => w.wb_warehouse_id === wbWarehouse.wb_warehouse_id).length > 0) {
    wbWarehouseReturn = wbReturnCost.filter(w => w.wb_warehouse_id === wbWarehouse.wb_warehouse_id)[0];
    wbWarehouse.returnExpr = wbWarehouseReturn.srg_return_expr
  }

  // console.log(wbWarehouse)

  return wbWarehouse
}

export const housekeeperCalculationNew = (
  object, subject, exchangeRate,
  isFbo, fulfillmentPrice, taxPercent, deliveryPreUsdPrice, marketingPercent, additionalExpenses,
  wbDelivery, wbReturnCost, wbWarehouseType, analysisData, selectStorage) => {
  // console.log(object, subject, exchangeRate)
  const calculation = {
    countBatches: (!!analysisData && getBatchCount(analysisData)) || 0, // Средний объем продаж в нише (MPSTATS).

    packageLength: getPackageLength(object),
    packageHeight: getPackageHeight(object),
    packageWidth: getPackageWidth(object),
    packageWeight: getPackageWeight(object), // Вес изделия.
    wbPrice: getWbPrice(object), // Цена продажи изделия.

    packageVolume: 0, // Расчёт объёма изделия.

    packageAmount: fulfillmentPrice, // Фулфилмент 20 руб (Стоимость упаковки).
    taxPercent: taxPercent, // Налог 7%.
    deliveryPreUsdPrice: deliveryPreUsdPrice, // Цена доставки за 1 кг в $ (4$).
    marketingPercent: marketingPercent, // Расходы на рекламу 20%.
    additionalExpenses: additionalExpenses, // Доп. расходы.

    cnyPrice: (!!exchangeRate && !!exchangeRate.cny && exchangeRate.cny) || 0,
    usdPrice: (!!exchangeRate && !!exchangeRate.usd && exchangeRate.usd) || 0,

    purchasePrice: 0, // Расчёт цены закупки.

    isFbo,
    wbCommissionPercent: getWbCommissionPercent(subject, isFbo),
    wbCommissionAmount: getWbCommissionAmount(getWbCommissionPercent(subject, isFbo), getWbPrice(object)),
    wbWarehouse: !!wbWarehouseType ? getWbWarehouse(wbDelivery, wbReturnCost, wbWarehouseType, selectStorage) : null,
    wbLogistic: 0, // Расчёт логистики.
    wbStorage: 0, // Расчёт хранения.

    turnover: getTurnover(subject), // Оборачиваемость (WB).
    buyout: getBuyout(analysisData), // Процент выкупа (MPSTATS).

    adPrice: getAdPrice(marketingPercent, getWbPrice(object)), // Реклама на 1 товар.
    taxProductPrice: getTaxProductPrice(taxPercent, getWbPrice(object)),// Налог с 1 товара.

    // -----

    adProductPrice: 0, // Реклама на 1 товар.
    deliveryPrice: 0, // Расчёт доставки на 1 товар.
    costPrice: 0, // Расчёт себестоимости на 1 товар.

    costPriceWb: 0, // Расчёт себестоимости 1 товара c ВБ.
    paymentToThe: 0, // Выплаты на Р/С за 1 товар.
    netProfitOne: 0, // Прибыль с единицы.
    profitPercent: 0, // Расчёт прибыли в %.

    // -----

    batchesWeight: 0, // Расчёт веса партии.

    potentialSalesMonth: 0, // Расчёт потенциальных продаж (мес).
    costSalesMonth: 0, // Вложения в партию (мес).

    adBudget: 0, // Расчёт рекламный бюджет на партию.
    commissionBudget: 0, // Расчёт коммиссия бюджет на партию.
    logisticBudget: 0, // Расчёт логисти бюджет на партию.
    storageBudget: 0, // Расчёт хранение бюджет на партию.

    // -----

    grossParticipation: 0, // Валовый доход с партии.
    partyExpenses: 0, // Расходы на партию.
    taxParticipation: 0, // Налог с партии.
    profitParty: 0, // Прибыль с партии.
    profitPercentParty: 0 // Прибыль в процентах партия.
  }

  {
    // Расчёт цены закупки на 1 товар.
    calculation.purchasePrice = getPurchasePrice(object, calculation.cnyPrice)

    // Расчёт объёма изделия.
    calculation.packageVolume = getPackageVolume(calculation)

    // Расчёт реклама на 1 товар.
    calculation.adProductPrice = getAdProductPrice(calculation)

    // Расчёт доставки на 1 товар.
    calculation.deliveryPrice = getDeliveryPrice(calculation)
  }

  {
    // Расчёт себестоимости.
    calculation.costPrice = Math.round(getCostPrice(calculation))

    // Расчёт логистики.
    calculation.wbLogistic = Math.round(getWbLogistic(calculation))

    // Расчёт хранения.
    calculation.wbStorage = Math.round((isFbo ? getWbStorage(calculation) : 0))

    // Расчёт себестоимости 1 товара c ВБ.
    calculation.costPriceWb = Math.round(getCostPriceWb(calculation))

    // Расчёт выплата на р/с.
    calculation.paymentToThe = Math.round(getPaymentToThe(calculation))

    // Расчёт прибыли с единицы (руб.).
    calculation.netProfitOne = Math.round(getNetProfitOne(calculation))

    // Расчёт прибыли в %.
    calculation.profitPercent = Math.round(getProfitPercent(calculation))
  }

  // Расчёт потенциальных продаж (мес).
  calculation.potentialSalesMonth = Math.round(getPotentialSalesMonth(calculation))

  // Расчёт веса партии.
  calculation.batchesWeight = Math.round(getBatchesWeight(calculation))

  // Расчёт стоимость товаров (мес).
  calculation.costSalesMonth = Math.round(getCostSalesMonth(calculation))

  // Расчёт рекламный бюджет на партию.
  calculation.adBudget = Math.round(getAdBudget(calculation))

  // Расчёт коммиссия бюджет на партию.
  calculation.commissionBudget = Math.round(getCommissionBudget(calculation))

  // Расчёт логисти бюджет на партию.
  calculation.logisticBudget = Math.round(getLogisticBudget(calculation))

  // Расчёт хранение бюджет на партию.
  calculation.storageBudget = Math.round(getStorageBudget(calculation))

  // -----

  // Валовый доход с партии.
  calculation.grossParticipation = Math.round(getGrossParticipation(calculation))

  // Расходы на партию.
  calculation.partyExpenses = Math.round(getPartyExpenses(calculation))

  // Налог с партии.
  calculation.taxParticipation = Math.round(getTaxParticipation(calculation))

  // Прибыль с партии.
  calculation.profitParty = Math.round(getProfitParty(calculation))

  // Прибыль в процентах партия.
  calculation.profitPercentParty = Math.round(getProfitPercentParty(calculation))

  return calculation
}

// ----------------------------------------------------------------------------------------------------

export const getPackageLength = (object) => {
  return (!!object && ((!!object.length && object.length) ||
    (!!object.wb_product && !!object.wb_product.package_length && object.wb_product.package_length))) || 0;
}

export const getPackageHeight = (object) => {
  return (!!object && ((!!object.height && object.height) ||
    (!!object.wb_product && !!object.wb_product.package_height && object.wb_product.package_height))) || 0;
}

export const getPackageWidth = (object) => {
  return (!!object && ((!!object.width && object.width) ||
    (!!object.wb_product && !!object.wb_product.package_width && object.wb_product.package_width))) || 0;
}

export const getPackageWeight = (object) => {
  return (!!object && ((!!object.weight && object.weight) ||
    (!!object.wb_product && !!object.wb_product.package_weight && object.wb_product.package_weight))) || 0;
}

export const getWbPrice = (object) => {
  return (!!object && ((!!object.price && object.price) ||
    (!!object.wb_product && !!object.wb_product.sale_price && object.wb_product.sale_price))) || 0;
}

export const getAmount = (object) => {
  return (!!object && (!!object.amount && object.amount)) || 0;
}

export const getWbCommissionPercent = (subject, isFbo) => {
  return (!!subject && !!subject.wb_commission &&
    (isFbo ? subject.wb_commission.percent : subject.wb_commission.percent_fbs)) || 0;
}

const getWbCommissionAmount = (wbCommissionPercent, wbPrice) => {
  return wbPrice * formatPercent(wbCommissionPercent);
}

const getAdPrice = (marketingPercent, wbPrice) => {
  return wbPrice * formatPercent(marketingPercent);
}

const getAdProductPrice = (calculation) => {
  // Расчёт реклама на 1 товар.
  return calculation.wbPrice * formatPercent(calculation.marketingPercent);
}

const getTaxProductPrice = (taxPercent, wbPrice) => {
  return formatPercent(taxPercent) * wbPrice * formatPercent(9800);
}

const getPurchasePrice = (object, cnyPrice) => {
  // Расчёт цены закупки на 1 товар.
  if (!!object.amount) {
    return object.amount;
  }

  return ((((!!object && !!object.taobao_product && !object.taobao_article_number && !!object.taobao_product.price && object.taobao_product.price) ||
      (!!object && !object.taobao_product && !!object.taobao_article_number && !!object.taobao_price && object.taobao_price)) || 0)
    * cnyPrice / 100);
}

const getPackageVolume = (calculation) => {
  // Расчёт объёма изделия.
  return ((calculation.packageWidth / 1000) * (calculation.packageLength / 1000) * (calculation.packageHeight / 1000)) * 1000;
}

const getTurnover = (subject) => {
  return (!!subject && !!subject.wb_availability && !!subject.wb_availability.turnover && subject.wb_availability.turnover) || 0;
}

const getDeliveryPrice = (calculation) => {
  // Расчёт доставки на 1 товар (руб.) = (Цена в долл. за кг * Вес в кг) * Цена за долл.
  return (((calculation.deliveryPreUsdPrice / 100) * (calculation.packageWeight / 1000)) * calculation.usdPrice)
}

// ----------------------------------------------------------------------------------------------------

const getCostPrice = (calculation) => {
  // Расчёт себестоимости (руб.) = Расчёт закупки + Стоимость упаковки + Цена доставки.
  return calculation.purchasePrice + calculation.packageAmount +
    calculation.deliveryPrice
}

const getWbLogistic = (calculation) => {
  const deliveryBase = (!!calculation.wbWarehouse && !!calculation.wbWarehouse.deliveryBase && calculation.wbWarehouse.deliveryBase) || 0
  const l = calculation.packageVolume

  let r = 0
  if (Math.ceil(l) > 1) {
    r = Math.ceil(l)-1
  }

  // return (deliveryBase + r*deliveryLiter) * formatPercent(keeping)
  return (deliveryBase/formatPercent(calculation.buyout)+1/formatPercent(calculation.buyout)*(1-formatPercent(calculation.buyout))*50) * 100

  // return (!!calculation.wbWarehouse && !!calculation.wbWarehouse.deliveryBase && calculation.wbWarehouse.deliveryBase * 100) || 0
}

const getWbStorage = (calculation) => {
  const storageBase = (!!calculation.wbWarehouse && !!calculation.wbWarehouse.storageBase && calculation.wbWarehouse.storageBase) || 0
  const storageLiter = (!!calculation.wbWarehouse && !!calculation.wbWarehouse.storageLiter && calculation.wbWarehouse.storageLiter) || 0
  const keeping = (!!calculation.wbWarehouse && !!calculation.wbWarehouse.storageExpr && calculation.wbWarehouse.storageExpr * 100) || 0
  const l = calculation.packageVolume

  let r = 0
  if (Math.ceil(l) > 1) {
    r = Math.ceil(l)-1
  }

  // return (storageBase + r*storageLiter) * formatPercent(keeping)
  return (storageBase + r*storageLiter) * 15 * 100

  // return (!!calculation.wbWarehouse && !!calculation.wbWarehouse.storageBase && calculation.wbWarehouse.storageBase * 100) || 0
}

const getCostPriceWb = (calculation) => {
  // Расчёт себестоимости 1 товара c ВБ.
  return calculation.costPrice +
    calculation.wbCommissionAmount +
    calculation.wbLogistic +
    calculation.wbStorage +
    calculation.adProductPrice
}

const getPaymentToThe = (calculation) => {
  // Расчёт выплата на р/с.
  return calculation.wbPrice * formatPercent(9800) -
    calculation.costPrice -
    calculation.wbCommissionAmount -
    calculation.wbLogistic -
    calculation.wbStorage -
    calculation.adProductPrice;
}

const getNetProfitOne = (calculation) => {
  // Расчёт прибыли с единицы (руб.).
  return calculation.paymentToThe - calculation.taxProductPrice;
}

const getProfitPercent = (calculation) => {
  // Расчёт прибыли в %.
  //return (calculation.netProfitOne - calculation.costPrice) / calculation.costPrice * 10000
  return calculation.netProfitOne / calculation.costPrice * 10000
}


// ----------------------------------------------------------------------------------------------------

const getPotentialSalesMonth = (calculation) => {
  // Расчёт потенциальных продаж (мес).
  return Math.round(30 / calculation.turnover * calculation.countBatches);
}

const getBatchesWeight = (calculation) => {
  // Расчёт веса партии = Расчёт потенциальных продаж (мес) * Вес товара.
  return calculation.potentialSalesMonth * calculation.packageWeight
}

const getCostSalesMonth = (calculation) => {
  // Расчёт стоимость товаров (мес) = Расчёт потенциальных продаж (мес) * Расчёт себестоимости.
  return calculation.potentialSalesMonth * calculation.costPrice + calculation.additionalExpenses
}

const getAdBudget = (calculation) => {
  // Расчёт рекламный бюджет на партию.
  return calculation.adProductPrice * calculation.potentialSalesMonth
}

const getCommissionBudget = (calculation) => {
  // Расчёт коммиссия бюджет на партию.
  return calculation.wbCommissionAmount * calculation.potentialSalesMonth
}

const getLogisticBudget = (calculation) => {
  // Расчёт логисти бюджет на партию.
  return calculation.wbLogistic * calculation.potentialSalesMonth
}

const getStorageBudget = (calculation) => {
  // Расчёт хранение бюджет на партию.
  return calculation.wbStorage * calculation.potentialSalesMonth
}

// ----------------------------------------------------------------------------------------------------

const getGrossParticipation = (calculation) => {
  // Валовый доход с партии.
  return calculation.wbPrice * calculation.potentialSalesMonth * formatPercent(9800)
}

const getPartyExpenses = (calculation) => {
  // Расходы на партию.
  return calculation.costSalesMonth  +
    calculation.adBudget +
    calculation.commissionBudget +
    calculation.logisticBudget +
    calculation.storageBudget
}

const getTaxParticipation = (calculation) => {
  // Налог с партии.
  return calculation.taxProductPrice * calculation.potentialSalesMonth
}

const getProfitParty = (calculation) => {
  // Прибыль с партии.
  return calculation.grossParticipation -
    calculation.partyExpenses -
    calculation.taxParticipation
}

const getProfitPercentParty = (calculation) => {
  // Прибыль в процентах партия.
  //return (calculation.profitParty - calculation.costSalesMonth) / calculation.costSalesMonth * 10000
  return calculation.profitParty / calculation.costSalesMonth * 10000
}
