import React from "react";
import {
  ButtonIcon, Pagination, Search, Select,
} from "@vokymlak/kabinet-ui";
import { InfoExchangeRatesTable } from "./InfoExchangeRatesTable";
import moment from "moment/moment";
import styleContent from "../../../styles/modules/Block.module.scss";
import styleCard from "../../../styles/modules/Card.module.scss";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";

function InfoExchangeRatesPage(props) {
  const {
    object,
    setObject,

    setIsUpdateObject,
    isUpdateObject,

    list,
    setList,
    count,

    setIsUpdateList,
    isUpdateList,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,
  } = props;

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="Page">
        <div
          className="Page__title"
          style={{
            marginTop: 0,
            marginBottom: 6,
          }}
        >
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
                marginRight: 6,
              }}
            >
              <div>
                Курс обмена
                {!!list && (
                  <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                    ({count})
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="Title__settings">
            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 12 }}
              onClick={() => (setObject(null), setIsUpdateObject(!isUpdateObject),
                setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 25,
                      title: 25,
                    },
                    {
                      value: 1000,
                      title: 1000,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{ marginLeft: 8 }}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}
          </div>
        </div>

        {!!object && (
          <div className="Page__content">
            <div
              className={styleContent._block + " " + styleContent._block_bottom}
            >
              <div className={styleContent._block__title}>
                {(!!object.date && moment(object.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")) || "-"}
              </div>
              <div className={styleContent._block__content}>
                <div className={styleCard._block}>
                  <div
                    className={styleCard._block__number}
                  >
                    {(!!object.cny && new Intl.NumberFormat("ru").format((Number(object.cny) / 100).toFixed(2))) || 0}
                  </div>
                  <div
                    className={styleCard._block__title}
                  >
                    Юань
                  </div>
                </div>

                <div style={{
                  height: 88,
                  width: 2,
                  backgroundColor: "#20202010",
                  borderRadius: 4,
                  marginRight: 8,
                  marginBottom: 8,
                  textAlign: "right"
                }}>
                </div>

                <div className={styleCard._block}>
                  <div
                    className={styleCard._block__number}
                  >
                    {(!!object.usd && new Intl.NumberFormat("ru").format((Number(object.usd) / 100).toFixed(2))) || 0}
                  </div>
                  <div
                    className={styleCard._block__title}
                  >
                    Доллар
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {!!list && (
          <div
            className="Page__content"
            style={{
              position: "relative",
              height: "calc(100% - 48px - 26px - 2px - 10px - 28px)",
            }}
          >
            <div
              style={{
                overflow: "auto",
                width: "100%",
                height: "100%",
              }}
            >
              <InfoExchangeRatesTable {...props} />
            </div>
          </div>
        )}
      </div>
    </ScrollBlock>
  );
}

export default InfoExchangeRatesPage;
