import React, {useRef, useState} from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Input,
  Modal,
  jsonFormatText,
  isValidateNullText, Icon,
} from "@vokymlak/kabinet-ui";

const getPhoneNumber = (value) => {
  if (!value) {
    value = '';
  }
  return value.replace(/\D/g, '').length > 11
    ? value.replace(/\D/g, '').substring(0, 11)
    : value.replace(/\D/g, '');
};

export const ModalAddClient = (props) => {
  const inputLastName = useRef(null);
  const inputFirstName = useRef(null);
  const inputPatronymic = useRef(null);
  // const inputLogin = useRef(null);
  const inputPassword = useRef(null);
  const inputEmail = useRef(null);
  const inputPosition = useRef(null);
  const [isActivated, setIsActivated] = useState(true);
  const inputPhone = useRef(null);

  const { notification } = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const lastName = jsonFormatText(inputLastName.current.value);
    const firstName = jsonFormatText(inputFirstName.current.value);
    const patronymic = jsonFormatText(inputPatronymic.current.value);
    // const login = jsonFormatText(inputLogin.current.value);
    const password = inputPassword.current.value.trim();
    const email = jsonFormatText(inputEmail.current.value);
    const phone = jsonFormatText(getPhoneNumber(inputPhone.current.value));
    const position = jsonFormatText(inputPosition.current.value);

    if (
      // isValidateNullText(lastName) ||
      isValidateNullText(firstName) ||
      isValidateNullText(email) ||
      isValidateNullText(phone) ||
      // isValidateNullText(login) ||
      !password ||
      password.length === 0
    ) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      last_name: lastName,
      first_name: firstName,
      patronymic: patronymic,
      // login: login,
      password: password.length > 0 ? password : null,
      email: email,
      position: position,
      is_activated: isActivated,
      phone: phone,
    });

    axios
      .post("/api/client/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Клиент добавлен", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">
          Новый
          {/*<div className="Text__signature">Клиент</div>*/}
        </div>

        <div className="Form__field">
          <Input reference={inputFirstName} label="Имя *"/>
        </div>

        <div className="Form__field">
          <Input reference={inputLastName} label="Фамилия"/>
        </div>

        <div className="Form__field">
          <Input reference={inputPatronymic} label="Отчество"/>
        </div>

        <div className="Form__field">
          <Input reference={inputPosition} label="Должность"/>
        </div>

        {/*<div className="Form__field">*/}
        {/*  <Input reference={inputLogin} label="Логин *"/>*/}
        {/*</div>*/}

        <div className="Form__field">
          <Input type={"email"} reference={inputEmail} label="Электронная почта *"/>
        </div>

        <div className="Form__field">
          <Input
            reference={inputPhone}
            label="Телефон (79991234567) *"
          />
        </div>

        <div className="Form__field">
          <Input reference={inputPassword} label="Пароль *"/>
        </div>

        <div className="Form__field Form__field_last">
          <div
            className="Button__swith"
            style={{
              padding: "8px",
              width: "100%",
              border: isActivated
                ? "2px solid #ffe082"
                : "2px dashed #20202015",
              backgroundColor: isActivated ? "#ffe082" : "#ffffff",
              borderRadius: 6,
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => setIsActivated(!isActivated)}
          >
            <div
              className="Swith__text"
              style={{
                fontSize: 17,
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              Доступ к работе
            </div>
            <div
              className={
                "Swith Swith_gray" +
                ((isActivated && " Swith_active Swith_active_gray") || "")
              }
            ></div>
          </div>
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Добавить
          </button>
        </div>

        <div className="Form__actions Form__actions_center">
          <div className="Action__link" onClick={() => props.handleClose()}>
            Отмена
          </div>
        </div>
      </form>
    </Modal>
  );
};
