import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  Modal,
  getErrorMessageByKey,
  useNotification,
  useObject,
} from "@vokymlak/kabinet-ui";
import {useViewer} from "../../../components/Viewer";

function ModalWbProductView(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "api/wb-product/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const [isDelete, setIsDelete] = useState(false);

  const { notification } = useNotification();

  const { viewer } = useViewer();

  const changeViewer = (image, mimeType) => {
    viewer(image, mimeType);
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
        {!isDelete && (
          <form className="Form">
            <div className="Form__title">
              {!!object.name && object.name }
              &nbsp;{(!!object.wb_article_number && "(" + object.wb_article_number + ")") || "" }
              <div className="Text__signature">Товар</div>
            </div>

            <div className="Form__field">
              <div className="Field__label">
                  Артикул WB
              </div>
              <div className="Field__text">
                {(!!object.wb_article_number && object.wb_article_number) || "-" }
              </div>
            </div>

            <div className="Form__field">
              <div className="Field__label">
                Название
              </div>
              <div className="Field__text">
                {(!!object.name && object.name) || "-" }
              </div>
            </div>

            <div className="Form__field">
              <div className="Field__label">
                Цена (руб.)
              </div>
              <div className="Field__text" style={{
                fontSize: 21,
                fontWeight: 600
              }}>
                {(!!object.sale_price &&
                    new Intl.NumberFormat("ru-RU").format(
                      (object.sale_price / 100).toFixed(2)
                    )) ||
                  "-"}
              </div>
            </div>

            <div className="Form__field">
              <div className="Field__label">
                Вес упаковки (кг)
              </div>
              <div className="Field__text">
                {(!!object.package_weight &&
                    new Intl.NumberFormat("ru-RU").format(
                      (object.package_weight / 1000).toFixed(3)
                    )) ||
                  "-"}
              </div>
            </div>

            <div className="Form__field">
              <div className="Field__label">
                Длина упаковки (см)
              </div>
              <div className="Field__text">
                {(!!object.package_length &&
                    new Intl.NumberFormat("ru-RU").format(
                      (object.package_length / 10).toFixed(2)
                    )) ||
                  "-"}
              </div>
            </div>

            <div className="Form__field">
              <div className="Field__label">
                Высота упаковки (см)
              </div>
              <div className="Field__text">
                {(!!object.package_height &&
                    new Intl.NumberFormat("ru-RU").format(
                      (object.package_height / 10).toFixed(2)
                    )) ||
                  "-"}
              </div>
            </div>

            <div className="Form__field">
              <div className="Field__label">
                Ширина упаковки (см)
              </div>
              <div className="Field__text">
                {(!!object.package_width &&
                    new Intl.NumberFormat("ru-RU").format(
                      (object.package_width / 10).toFixed(2)
                    )) ||
                  "-"}
              </div>
            </div>

            <div className="Form__field">
              {!!object.images &&
                !!object.images[0].data && (
                  <img
                    src={`${ROOT_URL + "/api/integration/wb/product/image/"+ object.images[0].uuid}`}
                    key={object.uuid + ":" + object.images[0].uuid}
                    width={292}
                    style={{
                      borderRadius: 5,
                    }}
                  >
                  </img>
                ) || "-"}
            </div>

            <div className="Form__field">
              <div className="Field__label">
                Описание
              </div>
              <div className="Field__text" style={{
                fontSize: 11
              }}>
                {(!!object.description && object.description) || "-" }
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalWbProductView;
