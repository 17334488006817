import React from "react";
import { WbProductsTableHead } from "./WbProductsTableHead";
import { WbProductsList } from "./WbProductsList";

export const WbProductsTable = (props) => {
  const { list } = props;

  return (!!list &&
      <table className="Table">
        <thead className="Thead">
          <WbProductsTableHead {...props} />
        </thead>
        <tbody>
          <WbProductsList {...props} />
        </tbody>
      </table>
  );
};
