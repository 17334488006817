import React, {useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {
  getErrorMessageByKey,
  Input,
  isValidateNullText,
  jsonFormatText,
  Modal,
  useNotification,
} from "@vokymlak/kabinet-ui";
import {toast} from "sonner";

function ModalMyProductAdd(props) {

  const [isRequest, setIsRequest] = useState(false);
  const inputArticle = useRef(null);

  function validateString(str) {
    // Проверка на наличие только цифр
    const isNumeric = /^[0-9]+$/.test(str);

    // Проверка на отсутствие ведущих нулей
    const noLeadingZero = str[0] !== '0';

    // Возвращаем true, если строки удовлетворяют оба условия
    return isNumeric && noLeadingZero;
  }

  const changeAdd = () => {

    const article = jsonFormatText(inputArticle.current.value);

    if (isValidateNullText(article)) {
      toast.error("Заполните все обязательные поля");
      return;
    }

    if (!validateString(article)) {
      toast.error("Не правильный формат артикула");
      return;
    }

    setIsRequest(true)

    const jsonData = JSON.stringify({
      wb_article: article,
    });

    axios
      .post("/api/my/product/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        toast.success("Товар добавлен");
        props.handleClose();
      })
      .catch((error) => {
        // console.error(error)
        if (error.response.status === 402) {
          toast.error("Превышен лимит запросов на сегодня");
        } else {
          toast.error(getErrorMessageByKey(error.response.data.error));
        }
      })
      .finally(() => {
        setIsRequest(false);
      });
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <div className="Form">
        <div className="Form__title">
          Новый товар
          {/*<div className="Text__signature">Товар</div>*/}
        </div>

        <div className="Form__field Form__field_last">
          <Input reference={inputArticle}
                 type="text" label="Артикул WB *"/>
        </div>

        {/*<div className="Form__actions Form__actions_center">*/}
        {/*  <button className="Action__button Action__button_all Action__button_blue"*/}
        {/*          onClick={!isRequest ? () => changeAdd() : () => null}>*/}
        {/*    {!isRequest*/}
        {/*      ? "Добавить"*/}
        {/*      : <Loading size="medium" color="blue"/>}*/}
        {/*  </button>*/}
        {/*</div>*/}

        <div className="Form__actions"
             style={{
               justifyContent: "flex-end"
             }}>
          <div className="Action__link"
               onClick={() => props.handleClose()}
               style={{
                 marginRight: 24
               }}>
            Отмена
          </div>
          <button className="Action__button Action__button_maxi Action__button_blue"
                  onClick={!isRequest ? () => changeAdd() : () => null}>
            Добавить
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalMyProductAdd;
