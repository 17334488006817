import React, {Fragment, useEffect, useState} from "react";
import styleContent from "../../../styles/modules/Block.module.scss";
import styleCard from "../../../styles/modules/Card.module.scss";
import {getWbCommissionPercent} from "./housekeeperCalculationNew";

export const AnalysisSubject = ({analysisData, subject, isFbo, selectStorage, setSelectStorage}) => {
  const [mpSummary, setMpSummary] = useState(null);
  const [mpGeography, setMpGeography] = useState(null);

  const getObjectByJson = (json) => {
    return JSON.parse(json);
  }

  useEffect(() => {
    if (!!analysisData.mpstats_summary) {
      setMpSummary(getObjectByJson(analysisData.mpstats_summary))
    }
    if (!!analysisData.mpstats_geography) {
      setMpGeography(getObjectByJson(analysisData.mpstats_geography))
    }
  }, [analysisData])


  const getBuyoutWithReturn = (summary) => {
    if (!!summary && !!summary.buyout && !!summary.buyout.with_return) {
      return summary.buyout.with_return * 100
    }

    return 0;
  }

  const getMonopolization = (summary) => {
    if (!!summary && !!summary.monopolization && !!summary.monopolization.monopolization) {
      return summary.monopolization.monopolization
    }

    return 0;
  }

  const getRevenueMissed = (summary) => {
    if (!!summary && !!summary.revenue && !!summary.revenue.missed) {
      return summary.revenue.missed
    }

    return 0;
  }

  const getRevenue = (summary) => {
    if (!!summary && !!summary.revenue) {
      return Number(summary.revenue.value)
    }

    return 0;
  }

  function getStorages(storageData) {
    if (!!storageData) {
      // Сортируем массив по убыванию значения percent
      const sortedData = storageData.sort((a, b) => b.percent - a.percent).filter(a => a.district === 'ЦФО');

      // Возвращаем первые три объекта из отсортированного массива
      return sortedData.slice(0, 3);
    }

    return [];
  }

  return (
    (!!mpSummary || !!mpGeography) && (
      <div className={styleContent._block + " " + styleContent._block_bottom}>
        <div className={styleContent._block__content}
             style={{
               alignItems: "center"
             }}>
          <div className={styleCard._block}
               style={{
                 // backgroundColor: "#f1f1f1",
                 backgroundColor: "#a811da25",
                 // border: "2px solid #a811da33",
                 margin: "6px 8px 6px 0"
               }}>
            <div
              className={styleCard._block__number}
              style={{fontSize: 21}}
            >
              {(getWbCommissionPercent(subject, isFbo) && (<>{new Intl.NumberFormat("ru-RU").format(
                (getWbCommissionPercent(subject, isFbo) / 100).toFixed(2))}<span
                style={{
                  fontSize: 9,
                  opacity: .75,
                  marginLeft: 4
                }}>%</span></>)) || "-"}
            </div>
            <div
              className={styleCard._block__title}
            >
              Комиссия&nbsp;WB
            </div>
          </div>

          {!!mpSummary.summary && (
            <div className={styleCard._block}
                 style={{
                   // backgroundColor: "#f1f1f1",
                   backgroundColor: "white",
                   // border: "2px solid #dbdbdb",
                   margin: "6px 8px 6px 0"
                 }}>
              <div
                className={styleCard._block__number}
                style={{fontSize: 21}}
              >
                {(getRevenue(mpSummary.summary) && (<>{new Intl.NumberFormat("ru-RU").format(
                  (getRevenue(mpSummary.summary)).toFixed(2))}<span
                  style={{
                    fontSize: 9,
                    opacity: .75,
                    marginLeft: 4
                  }}>руб.</span></>)) || "-"}
              </div>
              <div
                className={styleCard._block__title}
              >
                Выручка
              </div>
            </div>
          )}

          {!!mpSummary.summary && (
            <div className={styleCard._block}
                 style={{
                   // backgroundColor: "#f1f1f1",
                   backgroundColor: "white",
                   // border: "2px solid #dbdbdb",
                   margin: "6px 8px 6px 0"
                 }}>
              <div
                className={styleCard._block__number}
                style={{fontSize: 21}}
              >
                {(getRevenueMissed(mpSummary.summary) && (<>{new Intl.NumberFormat("ru-RU").format(
                  (getRevenueMissed(mpSummary.summary)).toFixed(2))}<span
                  style={{
                    fontSize: 9,
                    opacity: .75,
                    marginLeft: 4
                  }}>руб.</span></>)) || "-"}
              </div>
              <div
                className={styleCard._block__title}
              >
                Упущенная выручка
              </div>
            </div>
          )}

          {!!mpSummary.summary && (
            <div className={styleCard._block}
                 style={{
                   // backgroundColor: "#f1f1f1",
                   backgroundColor: "white",
                   // border: "2px solid #dbdbdb",
                   margin: "6px 8px 6px 0"
                 }}>
              <div
                className={styleCard._block__number}
                style={{fontSize: 21}}
              >
                {(getMonopolization(mpSummary.summary) &&
                  (<>{getMonopolization(mpSummary.summary)}</>)) || "-"}
              </div>
              <div
                className={styleCard._block__title}
              >
                Монополизация
              </div>
            </div>
          )}

          {!!mpSummary.summary && (
            <div className={styleCard._block}
                 style={{
                   // backgroundColor: "#f1f1f1",
                   backgroundColor: "white",
                   // border: "2px solid #dbdbdb",
                   margin: "6px 8px 6px 0"
                 }}>
              <div
                className={styleCard._block__number}
                style={{fontSize: 21}}
              >
                {(getBuyoutWithReturn(mpSummary.summary) && (<>{new Intl.NumberFormat("ru-RU").format(
                  (getBuyoutWithReturn(mpSummary.summary) / 100).toFixed(2))}<span
                  style={{
                    fontSize: 9,
                    opacity: .75,
                    marginLeft: 4
                  }}>%</span></>)) || "-"}
              </div>
              <div
                className={styleCard._block__title}
              >
                Выкуп с учетов возврата
              </div>
            </div>
          )}

          {!!mpGeography.storage_data && (
            <div className={styleContent._block__content} style={{
              border: "2px solid #dbdbdb",
              paddingTop: 4,
              paddingBottom: 4,
              paddingLeft: 8,
              borderRadius: 13,
              position: "relative"
            }}>
              <div style={{
                backgroundColor: "#f1f1f1",
                padding: "0 4px",
                position: "absolute",
                top: -8,
                left: 12,
                fontSize: 12
              }}>Склады отгрузки
              </div>
              {!!mpGeography.storage_data && getStorages(mpGeography.storage_data).map((storage, index) => (
                <div className={styleCard._block + " " + styleCard._block_hover}
                     style={{
                       // backgroundColor: "#f1f1f1",
                       backgroundColor: "white",
                       border: !!selectStorage && selectStorage.office_name === storage.office_name
                         ? "2px solid #0061fc"
                       : "2px solid #ffffff",
                       margin: "4px 8px 4px 0",
                       cursor: "pointer"
                     }}
                     key={'storage_' + index}
                     onClick={!!selectStorage && selectStorage.office_name === storage.office_name
                       ? () => setSelectStorage(null)
                       : () => setSelectStorage(storage)}
                >
                  <div
                    className={styleCard._block__number}
                    style={{fontSize: 21}}
                  >
                    {(storage.percent && (<>{new Intl.NumberFormat("ru-RU").format(
                      (storage.percent).toFixed(2))}<span
                      style={{
                        fontSize: 9,
                        opacity: .75,
                        marginLeft: 4
                      }}>%</span></>)) || "-"}
                  </div>
                  <div
                    className={styleCard._block__title}
                  >
                    {storage.office_name}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  );
}
