import React, {useState} from "react";
import { ScrollBlock } from "../../../components/ScrollBlock/ScrollBlock";
import {ButtonIcon, Pagination, Search, Select} from "@vokymlak/kabinet-ui";
import ModalWbProductAdd from "./ModalWbProductAdd";
import ModalWbProductView from "./ModalWbProductView";
import {WbProductsTable} from "./WbProductsTable";
import ModalTbProductAdd from "./ModalTbProductAdd";
import ModalTbProductView from "./ModalTbProductView";

function WbProductsPage(props) {
  const {
    list,
    setList,
    count,

    setIsUpdateList,
    isUpdateList,

    setIsModalObjectAdd,
    modalObjectAdd,
    setIsModalObjectView,
    modalObjectView,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    inputSearchText,
    onChangeSearchText,
    searchQuery,


    selectContracts,
    setSelectContracts,

    listProductCategories,
    setListProductCategories,
    isUpdateProductCategories,
    setIsUpdateProductCategories,
    changeSelectProductCategory,

    modalTbProductAdd,
    setModalTbProductAdd,
    modalTbProductView,
    setModalTbProductView,

    modalProductEdit,
    setModalProductEdit,


    isError,
    setIsError,
  } = props;

  const getProductCategoriesSelectOptions = (list) => {
    const options = [
      {
        value: '',
        title: 'Все',
      },
      {
        value: 'null',
        title: 'Без ниши',
      }
    ];

    list.map((item) => {
      const option = {
        value: item.uuid,
        title: item.name,
      };

      options.push(option);
    });

    return options;
  };

  const [modalMyCalculationAdd, setModalMyCalculationAdd] = useState(null)

  return (
      <ScrollBlock style={{ width: "100%", height: "100%" }}>
        <div className="Page">
          <div className="Page__title">
            <div className="Title__text">
              <div
                className="Title__text"
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  fontSize: 19,
                  fontWeight: 700,
                  marginRight: 6,
                }}
              >
                <div>
                  WB товары
                  {!!list && (
                    <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                    ({count})
                  </span>
                  )}
                </div>
              </div>
              <span style={{ width: 160, marginLeft: 16 }}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>

              <span
                style={{
                  marginLeft: 12,
                  width: 180,
                  fontWeight: 500
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={''}
                  options={
                    !!listProductCategories
                      ? getProductCategoriesSelectOptions(listProductCategories)
                      : getProductCategoriesSelectOptions([])
                  }
                  label="Категория"
                  onChange={(event) => changeSelectProductCategory(event.target.value)}
                />
              </span>

              <ButtonIcon
                tooltipText={"Ошибки"}
                iconName={"custom-alert"}
                iconColor={isError ? "#202020" : "#20202075"}
                size={24}
                style={{
                  marginLeft: 16,
                }}
                onClick={() => setIsError(!isError)}
              />

              {!!selectContracts && selectContracts.length > 0 && (
                <div
                  style={{
                    backgroundColor: "#0061ff",
                    padding: "0 4px",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 24,
                    marginLeft: 12,
                  }}
                >
                  <div
                    style={{
                      padding: "4px 6px",
                      color: "white",
                      fontSize: 14,
                      pointerEvents: "auto",
                      fontWeight: 700,
                      cursor: "default"
                    }}
                  >
                    {selectContracts.length}
                  </div>
                  <ButtonIcon
                    tooltipText={"Отменить"}
                    iconName={"custom-circle-close"}
                    iconColor={"white"}
                    size={18}
                    onClick={() => setSelectContracts([])}
                  />
                </div>
              )}
            </div>

            <div className="Title__settings">
                <ButtonIcon
                  tooltipText={"Обновить"}
                  iconName={"custom-change-circle"}
                  iconColor={"#20202075"}
                  size={28}
                  style={{ marginLeft: 12 }}
                  onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
                />

                {!!count && !!pageLimit && (
                  <div
                    style={{
                      marginLeft: 12,
                      width: 64,
                    }}
                  >
                    <Select
                      isFixed={true}
                      isDefaultOption={false}
                      value={pageLimit}
                      options={[
                        {
                          value: 100,
                          title: 100,
                        },
                        {
                          value: 1000,
                          title: 1000,
                        },
                      ]}
                      label="Лимит"
                      onChange={(event) => setPageLimit(Number(event.target.value))}
                    />
                  </div>
                )}

                {!!count && count > pageLimit && (
                  <div style={{ marginLeft: 8 }}>
                    <Pagination
                      totalRecords={count}
                      pageLimit={pageLimit}
                      currentPage={pageNumber}
                      onPageChanged={(data) => changePage(data)}
                    />
                  </div>
                )}

                <ButtonIcon
                  tooltipText={"Добавить"}
                  iconName={"custom-add"}
                  iconColor={"#0064ff"}
                  size={36}
                  style={{ marginLeft: 12 }}
                  onClick={() => setIsModalObjectAdd(true)}
                />
            </div>
          </div>

          <hr className={"Page__hr"} style={{marginBottom: 6}}/>

            {!!list && (
              <div
                className="Page__content"
                style={{
                  position: "relative",
                  height: "calc(100% - 48px - 12px)",
                }}
              >
                <div
                  style={{
                    overflow: "auto",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <WbProductsTable {...props} />
                </div>
              </div>
            )}

            {modalObjectAdd && (
              <ModalWbProductAdd
                successfulRequest={() => (setIsUpdateList(!isUpdateList), setIsUpdateProductCategories(!isUpdateProductCategories))}
                handleClose={() => setIsModalObjectAdd(false)}
              />
            )}

          {!!modalObjectView && (
            <ModalWbProductView
              object={modalObjectView}
              successfulRequest={() => (setIsUpdateList(!isUpdateList), setIsUpdateProductCategories(!isUpdateProductCategories))}
              handleClose={() => setIsModalObjectView(null)}
            />
          )}



          {/*{modalTbProductAdd && (*/}
          {/*  <ModalTbProductAdd*/}
          {/*    object={modalTbProductAdd}*/}
          {/*    exchangeRate={exchangeRate}*/}
          {/*    successfulRequest={() => setIsUpdateList(!isUpdateList)}*/}
          {/*    handleClose={() => setModalTbProductAdd(null)}*/}
          {/*  />*/}
          {/*)}*/}

          {/*{modalTbProductView && (*/}
          {/*  <ModalTbProductView*/}
          {/*    object={modalTbProductView}*/}
          {/*    exchangeRate={exchangeRate}*/}
          {/*    successfulRequest={() => setIsUpdateList(!isUpdateList)}*/}
          {/*    handleClose={() => setModalTbProductView(null)}*/}
          {/*  />*/}
          {/*)}*/}


        </div>
      </ScrollBlock>
  );
}

export default WbProductsPage;
