import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  Modal,
  getErrorMessageByKey,
  useNotification,
  useObject, Input,
} from "@vokymlak/kabinet-ui";
import {toast} from "sonner";

function ModalMyProductEdit(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "api/my/product/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputWeight = useRef(null);
  const inputLength = useRef(null);
  const inputHeight = useRef(null);
  const inputWidth = useRef(null);
  const inputPrice = useRef(null);
  const inputAmount = useRef(null);

  const numberConvertV = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(3)).replace(".", ""))
      : null;
  };

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const numberConvertL = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(1)).replace(".", ""))
      : null;
  };



  const changeEdit = (event) => {
    event.preventDefault();

    const weight = inputWeight.current.value;
    const length = inputLength.current.value;
    const height = inputHeight.current.value;
    const width = inputWidth.current.value;
    const price = inputPrice.current.value;
    const amount = inputAmount.current.value;


    const jsonData = JSON.stringify({
      weight: numberConvertV(weight),
      length: numberConvertL(length),
      height: numberConvertL(height),
      width: numberConvertL(width),
      price: numberConvert(price),
      amount: numberConvert(amount),
    });

    axios
      .put("/api/my/product/" + props.object.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        toast.success("Данные сохранены");
        props.handleClose();
      })
      .catch((error) => {
        toast.error(getErrorMessageByKey(error.response.data.error));
      });
  };


  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>

        <form className="Form" onSubmit={(event) => changeEdit(event)}>
          <div className="Form__title">
            {!!object.wb_product && !!object.wb_product.name && object.wb_product.name}
            {/*&nbsp;{(!!object.wb_product && !!object.wb_product.wb_article_number && "(" + object.wb_product.wb_article_number + ")") || "" }*/}
            {(!!object.wb_product && !!object.wb_product.wb_article_number && (
              <div className="Text__signature">{object.wb_product.wb_article_number}</div>
            ))}
            {/*<div className="Text__signature">Изменение характеристик</div>*/}
          </div>

          <div className="Form__title" style={{
            fontWeight: 400
          }}>
            Изменение характеристик
          </div>

          <div className="Form__field">
            <Input
              reference={inputPrice}
              type="number"
              label="Цена (руб)"
              value={!!object.price && object.price / 100}
              maxNumber={999_999_999}
              minNumber={0}
              stepNumber={0.01}
            />
          </div>

          <div className="Form__field">
            <Input
              reference={inputAmount}
              type="number"
              label="Цена закупа (руб)"
              value={!!object.amount && object.amount / 100}
              maxNumber={999_999_999}
              minNumber={0}
              stepNumber={0.01}
            />
          </div>


          <div className="Form__field">
            <Input
              reference={inputWeight}
              type="number"
              label="Вес (кг)"
              value={!!object.weight && object.weight / 1000}
              maxNumber={99_999}
              minNumber={0}
              stepNumber={0.01}
            />
          </div>

          <div className="Form__field">
            <Input
              reference={inputLength}
              type="number"
              label="Длина (см)"
              value={!!object.length && object.length / 10}
              maxNumber={99_999}
              minNumber={0}
              stepNumber={0.01}
            />
          </div>

          <div className="Form__field">
            <Input
              reference={inputHeight}
              type="number"
              label="Высота (см)"
              value={!!object.height && object.height / 10}
              maxNumber={99_999}
              minNumber={0}
              stepNumber={0.01}
            />
          </div>

          <div className="Form__field">
            <Input
              reference={inputWidth}
              type="number"
              label="Ширина (см)"
              value={!!object.width && object.width / 10}
              maxNumber={99_999}
              minNumber={0}
              stepNumber={0.01}
            />
          </div>

          {/*<div className="Form__actions Form__actions_center">*/}
          {/*  <button className="Action__button Action__button_all Action__button_blue">*/}
          {/*    Применить*/}
          {/*  </button>*/}
          {/*</div>*/}

          <div className="Form__actions"
               style={{
                 justifyContent: "flex-end"
               }}>
            <div className="Action__link"
                 onClick={() => props.handleClose()}
                 style={{
                   marginRight: 24
                 }}>
              Отмена
            </div>
            <button className="Action__button Action__button_maxi Action__button_blue">
              Применить
            </button>
          </div>

        </form>

      </Modal>
    )
  );
}

export default ModalMyProductEdit;
