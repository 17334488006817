
export const TARIFF_DISCOUNTS = [
  {
    days: 30,
    discount: 0
  },
  {
    days: 90,
    discount: 10
  },
  {
    days: 180,
    discount: 20
  },
  {
    days: 360,
    discount: 30
  }
];

export const TARIFF_OPTIONS = [
  {
    tag: "ELEMENTARY",
    title: 'ПРОФИ',
    subtitle: 'Идеальный способ начать и достичь успеха без затрат',
    options: [
      {
        title: 'Поиск товара в Китае',
        option: true
      },
      {
        title: 'Расчёт Unit экономики',
        option: true
      },
      {
        title: 'Аналитика ниши',
        option: true
      },
      {
        title: 'Расширенный отчёт по юнит экономике',
        option: false
      },
      {
        title: 'Расширенная аналитика ниши',
        option: false
      },
      {
        title: 'Прогноз объёма закупки',
        option: false
      },
      {
        title: 'ТОП ниши',
      },
    ]
  },
  {
    tag: "PROFESSIONAL",
    title: 'ЭКСПЕРТ',
    subtitle: 'Полный набор мощных инструментов для успеха',
    options: [
      {
        title: 'Поиск товара в Китае',
        option: true
      },
      {
        title: 'Расчёт Unit экономики',
        option: true
      },
      {
        title: 'Аналитика ниши',
        option: true
      },
      {
        title: 'Расширенный отчёт по юнит экономике',
        option: true
      },
      {
        title: 'Расширенная аналитика ниши',
        option: true
      },
      {
        title: 'Прогноз объёма закупки',
        option: true
      },
      {
        title: 'ТОП ниши',
        option: false
      },
    ]
  },
  {
    tag: "CORPORATE",
    title: 'ГУРУ',
    subtitle: 'Мощное решение для поддержки вашего бизнеса',
    options: [
      {
        title: 'Поиск товара в Китае',
        option: true
      },
      {
        title: 'Расчёт Unit экономики',
        option: true
      },
      {
        title: 'Аналитика ниши',
        option: true
      },
      {
        title: 'Расширенный отчёт по юнит экономике',
        option: true
      },
      {
        title: 'Расширенная аналитика ниши',
        option: true
      },
      {
        title: 'Прогноз объёма закупки',
        option: true
      },
      {
        title: 'ТОП ниши',
        option: true
      },
    ]
  }
]
