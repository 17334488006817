import React, {useEffect, useRef, useState} from "react";
import {useAction, useList, useNotification, useObject, useUnroll} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";
import {useParams} from "react-router-dom";

function useMyTop(myTariff) {
  const {
    list: [list, count, setList, allCount],
    update: [isUpdateList, setIsUpdateList, updateList],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
  } = useList({
    apiUrl: "/api/my/top",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 10,
    isSearch: true,
  });

  const inputSearchText = useRef(null);
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  const onChangeSearchText = () => {
    setSearchQuery(inputSearchText.current.value.trim());
  };

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const { setAction } = useAction("my-top", () => updateList());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "my-top"]);
    } else {
      setAction("my-top");
    }
  }, [list]);

  return {
    list,
    setList,
    count,
    allCount,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    inputSearchText,
    onChangeSearchText,
    searchQuery,
  };
}

export default useMyTop;
