import React, {Fragment} from "react";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import styleContent from "../../../styles/modules/Block.module.scss";
import styleCard from "../../../styles/modules/Card.module.scss";
import moment from "moment/moment";
import ModalNewTariffPay from "./ModalNewTariffPay";
import {Pagination} from "@vokymlak/kabinet-ui";
import NewTariffPay from "./NewTariffPay";

function isDateBeforeToday(date) {
  const currentDate = moment();
  const inputDate = moment(date, 'YYYY-MM-DD');

  return inputDate.isBefore(currentDate);
}

function MyTariffPage(props) {
  const {
    isNotTariff,

    list,
    setList,
    count,

    object,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    setIsUpdateObject,
    isUpdateObject,

    isUpdateList,
    setIsUpdateList,

    setIsModalObjectAdd,
    modalObjectAdd,
    modalObjectView,
    setIsModalObjectView,
    modalObjectEdit,
    setModalObjectEdit,
  } = props;

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
                marginRight: 6,
              }}
            >
              <div>
                Тарифный план
              </div>
            </div>
          </div>

          <div className="Title__settings">
          </div>
        </div>


        {/*<div className={"Page__hr"}></div>*/}


        <div
          className="Page__content"
          style={{
            position: "relative",
            // height: "calc(100% - 48px - 26px - 2px - 10px - 28px)",
          }}
        >
          <div
            style={{
              overflow: "auto",
              width: "100%",
              // height: "100%",
            }}
          >
            {!!object && (
              <div
                className={styleContent._block + " " + styleContent._block_bottom}
              >
                {/*<div className={styleContent._block__title}>*/}
                {/*  Текущий тариф*/}
                {/*</div>*/}
                <div className={styleContent._block__content}>
                  <div className={styleCard._block}>
                    <div
                      className={styleCard._block__number}
                      style={{
                        fontSize: 24,
                        marginBottom: 8
                      }}
                    >
                      {(!!object.tariff_type && object.tariff_type.name) ||
                        "-"}
                    </div>
                    <div
                      className={styleCard._block__title}
                    >
                      Тарифный&nbsp;план
                    </div>
                  </div>

                  <div style={{
                    width: 2,
                    backgroundColor: "#20202010",
                    borderRadius: 4,
                    marginRight: 8,
                    marginBottom: 8,
                    textAlign: "right"
                  }}>
                  </div>

                  <div className={styleCard._block}>
                    <div
                      className={styleCard._block__number}
                      style={{
                        fontSize: 24,
                        marginBottom: 8
                      }}
                    >
                      {(!!object.day_request && object.day_request.request_counts) || "-"}
                    </div>
                    <div
                      className={styleCard._block__title}
                    >
                      Отправлено&nbsp;запросов&nbsp;/&nbsp;день
                    </div>
                  </div>

                  <div className={styleCard._block}>
                    <div
                      className={styleCard._block__number}
                      style={{
                        fontSize: 24,
                        marginBottom: 8
                      }}
                    >
                      {(!!object.day_request_count && object.day_request_count) || "-"}
                    </div>
                    <div
                      className={styleCard._block__title}
                    >
                      Лимит&nbsp;запросов&nbsp;/&nbsp;день
                    </div>
                  </div>

                  <div style={{
                    width: 2,
                    backgroundColor: "#20202010",
                    borderRadius: 4,
                    marginRight: 8,
                    marginBottom: 8,
                    textAlign: "right"
                  }}>
                  </div>

                  <div className={styleCard._block}>
                    <div
                      className={styleCard._block__number}
                      style={{
                        fontSize: 24,
                        marginBottom: 8
                      }}
                    >
                      {(!!object.date_completion && moment(object.date_completion.substring(0, 19)).format("DD.MM.YYYY")) ||
                        "-"}
                    </div>
                    <div
                      className={styleCard._block__title}
                    >
                      Дата&nbsp;завершения
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/*{isNotTariff && (*/}
            {/*  <div style={{*/}
            {/*    fontSize: 28,*/}
            {/*    fontWeight: 400,*/}
            {/*    opacity: .33,*/}
            {/*    marginBottom: 16*/}
            {/*  }}>*/}
            {/*    Пока нет активного тарифа*/}
            {/*  </div>*/}
            {/*)}*/}

            {isNotTariff && !!list && (
              <div style={{}}>
                <NewTariffPay
                  isTrial={isNotTariff && !!list && list.length === 0}
                  successfulRequest={() => (setIsUpdateList(!isUpdateList), setIsUpdateObject(!isUpdateObject))} />
              </div>
            )}

            {!isNotTariff && (
              <button className="Action__button Action__button_maxi Action__button_blue"
                      onClick={() => setIsModalObjectAdd(true)}>
                Приобрести тариф
              </button>
            )}
          </div>
        </div>

        <div
          className="Page__content"
          style={{
            position: "relative",
            // height: "calc(100% - 48px - 26px - 2px - 10px - 28px)",
          }}
        >
        </div>

        {!!list && list.length > 0 && (
          <div className={"Page__hr"}></div>
        )}

        {!!list && list.length > 0 && (
          <>
            <div className="Page__title" style={{
              marginBottom: 16
            }}>
              <div className="Title__text">
                <div
                  className="Title__text"
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    fontSize: 19,
                    fontWeight: 700,
                    marginRight: 6,
                  }}
                >
                  <div>
                    История тарифов
                  </div>
                </div>
              </div>

              <div className="Title__settings">
                {!!count && count > pageLimit && (
                  <div style={{marginLeft: 8}}>
                    <Pagination
                      totalRecords={count}
                      pageLimit={pageLimit}
                      currentPage={pageNumber}
                      onPageChanged={(data) => changePage(data)}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="Page__content">
              <ul className="List">
                {list.map((item) => (
                  <Fragment key={"item_" + item.uuid}>
                    <li
                      className={"List__item"}
                      style={{
                        padding: "10px 12px",
                        position: "relative",
                        marginBottom: 12,
                        cursor: "default"
                      }}
                    >
                      <div className="Item__title">
                        <div className="Element__title" style={{
                          flexDirection: "column",
                          alignItems: "flex-start"
                        }}>
                          <div>
                            {(!item.tariff || (!!item.tariff && !item.tariff.name)) && !!item.tariff_type && item.tariff_type.name}
                            {!!item.tariff && !!item.tariff.name && item.tariff.name}
                          </div>
                          <div style={{
                            fontSize: 17,
                            fontWeight: 400
                          }}>
                            {(!!item.final_amount && (<>{new Intl.NumberFormat("ru-RU").format(
                                (item.final_amount / 100).toFixed(2))}&nbsp;<span style={{
                                fontSize: 11,
                                fontWeight: 400
                              }}>руб</span></>)) ||
                              "-"}
                          </div>
                        </div>

                        {item.is_paid && (
                          <div
                            style={{
                              position: "absolute",
                              top: -12,
                              right: 0,
                              padding: "0 12px",
                            }}
                          >
                            {!!item.date_start && !isDateBeforeToday(item.date_start.substring(0, 19)) && (
                              <div
                                style={{
                                  fontSize: 11,
                                  fontWeight: 700,
                                  backgroundColor: "#0064ff",
                                  display: "inline-block",
                                  color: "white",
                                  padding: "2px 4px 2px",
                                  borderRadius: 3,
                                  margin: "0 2px",
                                }}
                              >
                                Ожидает
                              </div>
                            )}

                            {!!item.date_start && !!item.date_completion && isDateBeforeToday(item.date_start.substring(0, 19)) && !isDateBeforeToday(item.date_completion.substring(0, 19)) && (
                              <div
                                style={{
                                  fontSize: 11,
                                  fontWeight: 700,
                                  backgroundColor: "#2e7d32",
                                  display: "inline-block",
                                  color: "white",
                                  padding: "2px 4px 2px",
                                  borderRadius: 3,
                                  margin: "0 2px",
                                }}
                              >
                                Активный
                              </div>
                            )}

                            {!!item.date_completion && isDateBeforeToday(item.date_completion.substring(0, 19)) && (
                              <div
                                style={{
                                  fontSize: 11,
                                  fontWeight: 700,
                                  backgroundColor: "#202020",
                                  display: "inline-block",
                                  color: "white",
                                  padding: "2px 4px 2px",
                                  borderRadius: 3,
                                  margin: "0 2px",
                                }}
                              >
                                Завершён
                              </div>
                            )}
                          </div>
                        )}

                        {!item.is_paid && (
                          <div
                            style={{
                              position: "absolute",
                              top: -12,
                              left: 0,
                              padding: "0 12px",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#0064ff",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              Не оплачен
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="Item__settings">
                        <div className="Element__datetime">
                          {!item.is_paid && !!item.payment_token && (
                            <a className="Action__button Action__button_maxi Action__button_blue"
                               target={"_self"}
                               href={`https://yoomoney.ru/checkout/payments/v2/contract?orderId=${item.payment_token}`}>
                              Оплатить
                            </a>
                          )}

                          {/*<div style={{*/}
                          {/*  padding: "4px 8px",*/}
                          {/*  backgroundColor: "#20202010",*/}
                          {/*  borderRadius: 4,*/}
                          {/*  marginLeft: 12,*/}
                          {/*  textAlign: "right"*/}
                          {/*}}>*/}
                          {/*  <div style={{*/}
                          {/*    fontSize: 21,*/}
                          {/*    fontWeight: 700,*/}
                          {/*  }}>*/}
                          {/*    {(!!item.count_months && item.count_months) ||*/}
                          {/*      "-"}*/}
                          {/*  </div>*/}
                          {/*  <div style={{*/}
                          {/*    fontSize: 10,*/}
                          {/*    fontWeight: 400,*/}
                          {/*    opacity: .75,*/}
                          {/*  }}>*/}
                          {/*    Месяцев*/}
                          {/*  </div>*/}
                          {/*</div>*/}

                          <div style={{
                            padding: "4px 8px",
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 21,
                              fontWeight: 700,
                            }}>
                              {(!!item.date_start && moment(item.date_start.substring(0, 19)).format("DD.MM.YYYY")) ||
                                "-"}
                            </div>
                            <div style={{
                              fontSize: 10,
                              fontWeight: 400,
                              opacity: .75,
                            }}>
                              Дата начала
                            </div>
                          </div>

                          <div style={{
                            padding: "4px 8px",
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 21,
                              fontWeight: 700,
                            }}>
                              {(!!item.date_completion && moment(item.date_completion.substring(0, 19)).format("DD.MM.YYYY")) ||
                                "-"}
                            </div>
                            <div style={{
                              fontSize: 10,
                              fontWeight: 400,
                              opacity: .75,
                            }}>
                              Дата завершения
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </Fragment>
                ))}
              </ul>
            </div>
          </>
        )}

        {modalObjectAdd && (
          <ModalNewTariffPay
            successfulRequest={() => (setIsUpdateList(!isUpdateList), setIsUpdateObject(!isUpdateObject))}
            handleClose={() => setIsModalObjectAdd(false)}
          />
        )}

      </div>
    </ScrollBlock>
  );
}

export default MyTariffPage;
