import React, {Fragment, useState} from "react";
import {
  TextSearchMarker, ButtonIcon, getAccountFio, newUuid,
} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";
import { JSONTree } from 'react-json-tree';
import styleTumbler from "../../../styles/modules/Tumbler.module.scss";


export const MpstatsSubjectsList = (props) => {
  const {
    list,

    searchQuery,

    isUnroll,
    changeUnroll,

    setModalItemSubjectImport,
    modalItemSubjectImport,
    setModalItemSellersImport,
    modalItemSellersImport,
    setModalItemGeographyImport,
    modalItemGeographyImport,
    setModalItemSummaryImport,
    modalItemSummaryImport,
    setModalItemCheckRatesImport,
    modalItemCheckRatesImport,
  } = props;

  const getBackground = (item) => {
    if (!!item) {
      let countTrue = 0
      if (!!item.mpstats_subject) {
        countTrue += 1
      }
      if (!!item.mpstats_sellers) {
        countTrue += 1
      }
      if (!!item.mpstats_geography) {
        countTrue += 1
      }
      if (!!item.mpstats_summary) {
        countTrue += 1
      }

      if (countTrue === 4) {
        return "#2e7d3233"
      }

      if (countTrue < 4 && countTrue > 0) {
        return "#cf940033"
      }
    }

    return "#cf2f0033"
  }

  const [tumbler, setTumbler] = useState("a");

  return (
    !!list && (
      <ul className="List">
        {list.map((item) => (
          <Fragment key={"item_" + item.uuid}>
            <li
              className={
                isUnroll(item.uuid) ? "List__item active" : "List__item"
              }
              onClick={() => changeUnroll(item.uuid)}
              style={{
                paddingLeft: 8,
              backgroundColor: getBackground(item)
            }}>
              <div className="Item__title">
                <div className="Element__title">
                  <div style={{ marginLeft: 4 }}>
                    <TextSearchMarker
                      text={item.wb_subject.name + (item.wb_subject.wb_number ? " #" + item.wb_subject.wb_number : "")}
                      search={searchQuery}
                    />
                    {/*{!!item.wb_category_subject && (*/}
                    {/*<div*/}
                    {/*  style={{*/}
                    {/*    fontSize: 12,*/}
                    {/*    opacity: 0.5,*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  {item.wb_category_subject.map(wbCatSubj => (*/}
                    {/*    <span key={"wbCatSubj_" + newUuid()}>*/}
                    {/*      {wbCatSubj.wb_category.name}*/}
                    {/*      </span>*/}
                    {/*  ))}*/}
                    {/*</div>*/}
                    {/*  )}*/}
                  </div>
                </div>
              </div>
              <div className="Item__settings">
                <div className="Element__datetime">


                    <>
                    <div style={{
                      padding: "4px 8px",
                      backgroundColor: ((!!item.mpstats_summary && ("#2e7d3233"
                  )) || "#20202010"),
                      borderRadius: 4,
                      marginLeft: 12,
                      textAlign: "right"
                    }}>
                      <div style={{
                        fontSize: 21,
                        fontWeight: 700,
                      }}>
                        Суммарный
                      </div>
                      <div style={{
                        fontSize: 12,
                        fontWeight: 600,
                        color: (!!item.status_summary && item.status_summary !== 200 && "#bf360c")
                      }}>
                        {(!!item.status_summary && item.status_summary) || "-"}
                        &nbsp;-&nbsp;
                        {(!!item.date_summary && moment(item.date_summary.substring(0, 19)).format("DD.MM.YYYY HH:mm:ss")) || "-"}</div>
                    </div>

                      <div style={{
                        height: 32,
                        width: 2,
                        backgroundColor: "#20202010",
                        borderRadius: 4,
                        marginLeft: 12,
                        textAlign: "right"
                      }}>
                      </div>
                    </>

                  {!!item.mpstats_sellers && (
                    <>
                      <div style={{
                        padding: "4px 8px",
                        backgroundColor: "#20202010",
                        borderRadius: 4,
                        marginLeft: 12,
                        textAlign: "right"
                      }}>
                        <div style={{
                          fontSize: 21,
                          fontWeight: 700,
                        }}>
                          Продавцы
                        </div>
                        <div style={{
                          fontSize: 12,
                          fontWeight: 600,
                          color: (!!item.status_sellers && item.status_sellers !== 200 && "#bf360c")
                        }}>
                          {(!!item.status_sellers && item.status_sellers) || "-"}
                          &nbsp;-&nbsp;
                          {(!!item.date_sellers && moment(item.date_sellers.substring(0, 19)).format("DD.MM.YYYY HH:mm:ss")) || "-"}</div>
                      </div>

                      <div style={{
                        height: 32,
                        width: 2,
                        backgroundColor: "#20202010",
                        borderRadius: 4,
                        marginLeft: 12,
                        textAlign: "right"
                      }}>
                      </div>
                    </>
                  )}

                  {!!item.mpstats_geography && (
                    <>
                      <div style={{
                        padding: "4px 8px",
                        backgroundColor: "#20202010",
                        borderRadius: 4,
                        marginLeft: 12,
                        textAlign: "right"
                      }}>
                        <div style={{
                          fontSize: 21,
                          fontWeight: 700,
                        }}>
                          География
                        </div>
                        <div style={{
                          fontSize: 12,
                          fontWeight: 600,
                          color: (!!item.status_geography && item.status_geography !== 200 && "#bf360c")
                        }}>
                          {(!!item.status_geography && item.status_geography) || "-"}
                          &nbsp;-&nbsp;
                          {(!!item.date_geography && moment(item.date_geography.substring(0, 19)).format("DD.MM.YYYY HH:mm:ss")) || "-"}</div>
                      </div>

                      <div style={{
                        height: 32,
                        width: 2,
                        backgroundColor: "#20202010",
                        borderRadius: 4,
                        marginLeft: 12,
                        textAlign: "right"
                      }}>
                      </div>
                    </>
                  )}

                  {!!item.mpstats_subject && (
                    <>
                      <div style={{
                        padding: "4px 8px",
                        backgroundColor: "#20202010",
                        borderRadius: 4,
                        marginLeft: 12,
                        textAlign: "right"
                      }}>
                        <div style={{
                          fontSize: 21,
                          fontWeight: 700,
                        }}>
                          Товары
                        </div>
                        <div style={{
                          fontSize: 12,
                          fontWeight: 600,
                          color: (!!item.status_subject && item.status_subject !== 200 && "#bf360c")
                        }}>
                          {(!!item.status_subject && item.status_subject) || "-"}
                          &nbsp;-&nbsp;
                          {(!!item.date_subject && moment(item.date_subject.substring(0, 19)).format("DD.MM.YYYY HH:mm:ss")) || "-"}</div>
                      </div>

                      <div style={{
                        height: 32,
                        width: 2,
                        backgroundColor: "#20202010",
                        borderRadius: 4,
                        marginLeft: 12,
                        textAlign: "right"
                      }}>
                      </div>
                    </>
                  )}

                  {!!item.mpstats_check_rates && (
                    <>
                      <div style={{
                        padding: "4px 8px",
                        backgroundColor: "#20202010",
                        borderRadius: 4,
                        marginLeft: 12,
                        textAlign: "right"
                      }}>
                        <div style={{
                          fontSize: 21,
                          fontWeight: 700,
                        }}>
                          Реклама
                        </div>
                        <div style={{
                          fontSize: 12,
                          fontWeight: 600,
                          color: (!!item.status_check_rates && item.status_check_rates !== 200 && "#bf360c")
                        }}>
                          {(!!item.status_check_rates && item.status_check_rates) || "-"}
                          &nbsp;-&nbsp;
                          {(!!item.date_check_rates && moment(item.date_check_rates.substring(0, 19)).format("DD.MM.YYYY HH:mm:ss")) || "-"}</div>
                      </div>

                      <div style={{
                        height: 32,
                        width: 2,
                        backgroundColor: "#20202010",
                        borderRadius: 4,
                        marginLeft: 12,
                        textAlign: "right"
                      }}>
                      </div>
                    </>
                  )}


                  <div style={{
                    marginLeft: 12,
                  }}>
                    <span>
                        {!!item.updated_at
                          ? moment(item.updated_at).format("DD.MM.YYYY")
                          : moment(item.created_at).format("DD.MM.YYYY")}
                      </span>
                  </div>
                </div>
              </div>
            </li>
            {isUnroll(item.uuid) && (
              <li className="List__content">
                  <div className="Content__header">
                    <div className="Header__title">
                      <ul className={styleTumbler._}>
                        <li className={styleTumbler._link}>
                          <a
                            className={
                              styleTumbler._text +
                              ((!!tumbler &&
                                  tumbler === "a" &&
                                  " " + styleTumbler.active + " ") ||
                                "")
                            }
                            onClick={() => setTumbler("a")}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            Суммарный отчёт
                          </a>
                        </li>
                        <li className={styleTumbler._link}>
                          <a
                            className={
                              styleTumbler._text +
                              ((!!tumbler &&
                                  tumbler === "b" &&
                                  " " + styleTumbler.active + " ") ||
                                "")
                            }
                            onClick={() => setTumbler("b")}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            Топ продавцов
                          </a>
                        </li>
                        <li className={styleTumbler._link}>
                          <a
                            className={
                              styleTumbler._text +
                              ((!!tumbler &&
                                  tumbler === "c" &&
                                  " " + styleTumbler.active + " ") ||
                                "")
                            }
                            onClick={() => setTumbler("c")}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            География
                          </a>
                        </li>
                        <li className={styleTumbler._link}>
                          <a
                            className={
                              styleTumbler._text +
                              ((!!tumbler &&
                                  tumbler === "d" &&
                                  " " + styleTumbler.active + " ") ||
                                "")
                            }
                            onClick={() => setTumbler("d")}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            Топ товаров
                          </a>
                        </li>
                        <li className={styleTumbler._link}>
                          <a
                            className={
                              styleTumbler._text +
                              ((!!tumbler &&
                                  tumbler === "e" &&
                                  " " + styleTumbler.active + " ") ||
                                "")
                            }
                            onClick={() => setTumbler("e")}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            Рекламная ставка
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="Header__settings">
                      <ButtonIcon
                        tooltipText={"Импорт рекламная ставка"}
                        iconName={"custom-cloud-upload"}
                        iconColor={"#202020"}
                        size={26}
                        style={{
                          marginLeft: 12
                        }}
                        onClick={() => setModalItemCheckRatesImport(item)}
                      />

                      <ButtonIcon
                        tooltipText={"Импорт топ товаров"}
                        iconName={"custom-cloud-upload"}
                        iconColor={"#202020"}
                        size={26}
                        style={{ marginLeft: 12 }}
                        onClick={() => setModalItemSubjectImport(item)}
                      />

                      <ButtonIcon
                        tooltipText={"Импорт география"}
                        iconName={"custom-cloud-upload"}
                        iconColor={"#202020"}
                        size={26}
                        style={{ marginLeft: 12 }}
                        onClick={() => setModalItemGeographyImport(item)}
                      />

                      <ButtonIcon
                        tooltipText={"Импорт топ продавцов"}
                        iconName={"custom-cloud-upload"}
                        iconColor={"#202020"}
                        size={26}
                        style={{ marginLeft: 12 }}
                        onClick={() => setModalItemSellersImport(item)}
                      />

                      <ButtonIcon
                        tooltipText={"Импорт суммарный отчёт"}
                        iconName={"custom-cloud-upload"}
                        iconColor={"#202020"}
                        size={26}
                        style={{
                          marginLeft: 12,
                          marginRight: 12
                      }}
                        onClick={() => setModalItemSummaryImport(item)}
                      />
                    </div>
                  </div>

                {!!tumbler && tumbler === "a" && (
                  <>
                    {!!item.mpstats_summary && (
                      <JSONTree data={JSON.parse(item.mpstats_summary)} />
                    )}
                    {!item.mpstats_summary && (
                      <span style={{marginRight: 8}}>Нет данных</span>
                    )}
                  </>
                )}

                {!!tumbler && tumbler === "b" && (
                  <>
                    {!!item.mpstats_sellers && (
                      <JSONTree data={JSON.parse(item.mpstats_sellers)} />
                    )}
                    {!item.mpstats_sellers && (
                      <span style={{marginRight: 8}}>Нет данных</span>
                    )}
                  </>
                )}

                {!!tumbler && tumbler === "c" && (
                <>
                  {!!item.mpstats_geography && (
                    <JSONTree data={JSON.parse(item.mpstats_geography)} />
                  )}
                  {!item.mpstats_geography && (
                    <span style={{marginRight: 8}}>Нет данных</span>
                  )}
                </>
              )}

                {!!tumbler && tumbler === "d" && (
                  <>
                    {!!item.mpstats_subject && (
                      <JSONTree data={JSON.parse(item.mpstats_subject)} />
                    )}
                    {!item.mpstats_subject && (
                      <span style={{marginRight: 8}}>Нет данных</span>
                    )}
                  </>
                )}

                {!!tumbler && tumbler === "e" && (
                  <>
                    {!!item.mpstats_check_rates && (
                      <JSONTree data={JSON.parse(item.mpstats_check_rates)} />
                    )}
                    {!item.mpstats_check_rates && (
                      <span style={{marginRight: 8}}>Нет данных</span>
                    )}
                  </>
                )}
              </li>
            )}
          </Fragment>
        ))}
      </ul>
    )
  );
};
