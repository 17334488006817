import React, {useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {
  getErrorMessageByKey, Icon,
  Input,
  isValidateNullText,
  jsonFormatText,
  Modal,
  useNotification,
} from "@vokymlak/kabinet-ui";
import {toast} from "sonner";

function ModalMyTopInfo(props) {
  return (
    <Modal handleClose={() => props.handleClose()} contentStyle={{
      width: 560+24+24+8
    }}>
      <div className="Form">
        <div className="Form__title">
          Топ ниш
          {/*<div className="Text__signature">Товар</div>*/}
        </div>

        <div className="Form__field Form__field_last">
          <div className="Field__text">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/HFV-16VaVsA?si=2qA0xvAN7cYyVmFP"
                    title="YouTube video player" frameBorder="0"
                    style={{
                      borderRadius: 11,
                      marginBottom: 24
                    }}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            <div style={{
              textAlign: "center"
            }}>
              {/*ТОП ниш:*/}
              {/*<br/>*/}
              В данном разделе вы можете посмотреть ниши которые в данный момент пользуются наибольшим спросом для
              продаж на маркетплейсе.<br/>
              Так же в этом разделе показаны наиболее популярные товары в этих нишах, для быстрого поиска идей
              товаров для реализации.
            </div>
          </div>
        </div>

        <div className="Form__actions Form__actions_center">
          <div className="Action__link"
               onClick={() => props.handleClose()}>
            Закрыть
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalMyTopInfo;
