import React, { useRef, useState } from "react";
import axios from "axios";
import {
  Modal,
  getErrorMessageByKey,
  useNotification,
  Textarea,
  useObject, Input,
} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";

function ModalWbAvailabilityScheduler(props) {
  const [isWork, setIsWork] = useState(false);

  const {
    object: [object],
  } = useObject({
    apiUrl: "api/scheduler/WB_AVAILABILITY",
    rootUrl: ROOT_URL,
    callbackResponse: (data) => {
      setIsWork(!!data && data.is_work);
    },
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputBreakTime = useRef(null);


  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const breakTime = inputBreakTime.current.value;

    const jsonData = JSON.stringify({
      break_time:  Number(breakTime),
      is_work: isWork,
    });

    axios
      .put("/api/scheduler/WB_AVAILABILITY/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              WB
              <div className="Text__signature">Оборачиваемость</div>
            </div>

            <div className="Form__field">
              <div
                className="Button__swith"
                style={{
                  padding: "8px",
                  width: "100%",
                  border: isWork
                    ? "2px solid #ffe082"
                    : "2px dashed #20202015",
                  backgroundColor: isWork ? "#ffe082" : "#ffffff",
                  borderRadius: 6,
                  marginTop: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => setIsWork(!isWork)}
              >
                <div
                  className="Swith__text"
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  В работе
                </div>
                <div
                  className={
                    "Swith Swith_gray" +
                    ((isWork && " Swith_active Swith_active_gray") || "")
                  }
                ></div>
              </div>
            </div>

            <div className="Form__field">
              <Input reference={inputBreakTime}
                     type="number"
                     value={object.break_time}
                     label="Время перерыва в секундах" />
            </div>

            <div className="Form__field">
              <div className="Field__label">
                Дата попытки
              </div>
              <div className="Field__text">
                {(!!object.attempt_date && object.attempt_date) || "-" }
              </div>
            </div>

            <div className="Form__field">
              <div className="Field__label">
                Количество попыток
              </div>
              <div className="Field__text">
                {(!!object.count_attempts && object.count_attempts) || "-" }
              </div>
            </div>


            <div className="Form__field">
              <div className="Field__label">
                Количество текущих в работе
              </div>
              <div className="Field__text">
                {(!!object.count_work_currents && object.count_work_currents) || "-" }
              </div>
            </div>


            <div className="Form__field">
              <div className="Field__label">
                Последний статус попытки
              </div>
              <div className="Field__text">
                {(!!object.last_attempt_status && object.last_attempt_status) || "-" }
              </div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>
          </form>
      </Modal>
    )
  );
}

export default ModalWbAvailabilityScheduler;
