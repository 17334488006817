import React, {useState} from "react";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {ButtonIcon, Icon, Loading, Pagination, Search, Select, Tooltip} from "@vokymlak/kabinet-ui";
import ModalMyProductAdd from "./ModalMyProductAdd";
import ModalMyProductView from "./ModalMyProductView";
import {MyProductsTable} from "./MyProductsTable";
import ModalTbProductAdd from "./ModalTbProductAdd";
import ModalTbProductView from "./ModalTbProductView";
import ModalMyProductEdit from "./ModalMyProductEdit";
import useMyProducts from "./useMyProducts";
import ModalTbProductArticleView from "./ModalTbProductArticleView";
import ModalMyProductInfo from "./ModalMyProductInfo";

function MyProductsPage({myTariff}) {
  const props = useMyProducts(myTariff);
  const {
    list,
    setList,
    count,
    allCount,

    setIsUpdateList,
    isUpdateList,

    setIsModalObjectAdd,
    modalObjectAdd,
    setIsModalObjectView,
    modalObjectView,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    exchangeRate,
    isUpdateExchangeRate,
    setIsUpdateExchangeRate,

    listProductCategories,
    setListProductCategories,
    isUpdateProductCategories,
    setIsUpdateProductCategories,
    changeSelectProductCategory,

    modalTbProductAdd,
    setModalTbProductAdd,
    modalTbProductView,
    setModalTbProductView,

    modalObjectEdit,
    setModalObjectEdit,

    checkAddMyProduct,

    modalTbProductArticleView,
    setModalTbProductArticleView,
  } = props;

  const [isModalInfo, setIsModalInfo] = useState(false);

  const getProductCategoriesSelectOptions = (list) => {
    const options = [
      {
        value: '',
        title: 'Все',
      },
      // {
      //   value: 'null',
      //   title: 'Без категории',
      // }
    ];

    list.map((item) => {
      const option = {
        value: item.uuid,
        title: item.name,
      };

      options.push(option);
    });

    return options;
  };

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">

        {(allCount > 0) && (
          <div className="Page__title">
            <div className="Title__text">
              <div
                className="Title__text"
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  fontSize: 19,
                  fontWeight: 700,
                  marginRight: 12
                }}
              >
                <div>
                  Коллекция товаров
                  {!!list && (
                    <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                    ({count})
                  </span>
                  )}
                </div>
              </div>

              <ButtonIcon
                // tooltipText={"В&nbsp;данном&nbsp;разделе " +
                //   "будут&nbsp;отображаться&nbsp;товары, " +
                //   "которые&nbsp;вы&nbsp;добавляете " +
                //   "для&nbsp;расчетов&nbsp;юнит-экономики.\n" +
                //   "Так&nbsp;же&nbsp;в&nbsp;этом&nbsp;разделе " +
                //   "вы&nbsp;сможете&nbsp;подобрать " +
                //   "поставщиков&nbsp;интересующих&nbsp;вас " +
                //   "товаров&nbsp;на&nbsp;сайте&nbsp;1688.com"}
                tooltipText={
                  "О&nbsp;разделе"
                }
                iconName={"custom-info"}
                iconColor={"#20202075"}
                size={22}
                style={{marginRight: 16}}
                onClick={() => setIsModalInfo(true)}
              />

              {(allCount > 0) && (
                <span style={{width: 160, marginRight: 16}}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
              )}

              {/*{(allCount > 0) && (*/}
              {/*  <span*/}
              {/*    style={{*/}
              {/*      marginLeft: 12,*/}
              {/*      width: 180,*/}
              {/*      fontWeight: 500*/}
              {/*    }}*/}
              {/*  >*/}
              {/*  <Select*/}
              {/*    isFixed={true}*/}
              {/*    isDefaultOption={false}*/}
              {/*    value={''}*/}
              {/*    options={*/}
              {/*      !!listProductCategories*/}
              {/*        ? getProductCategoriesSelectOptions(listProductCategories)*/}
              {/*        : getProductCategoriesSelectOptions([])*/}
              {/*    }*/}
              {/*    label="Категория"*/}
              {/*    onChange={(event) => changeSelectProductCategory(event.target.value)}*/}
              {/*  />*/}
              {/*</span>*/}
              {/*)}*/}
            </div>


            <div className="Title__settings">
              {/*{(allCount > 0) && (*/}
              {/*  <ButtonIcon*/}
              {/*    tooltipText={"Обновить"}*/}
              {/*    iconName={"custom-change-circle"}*/}
              {/*    iconColor={"#20202075"}*/}
              {/*    size={28}*/}
              {/*    style={{marginLeft: 12}}*/}
              {/*    onClick={() => (*/}
              {/*      setList(null),*/}
              {/*        setIsUpdateList(!isUpdateList),*/}
              {/*        setIsUpdateProductCategories(!isUpdateProductCategories),*/}
              {/*        setIsUpdateExchangeRate(!isUpdateExchangeRate)*/}
              {/*    )}*/}
              {/*  />*/}
              {/*)}*/}

              {/*{(!!count && !!pageLimit && (count > 10)) && (*/}
              {/*  <div*/}
              {/*    style={{*/}
              {/*      marginLeft: 12,*/}
              {/*      width: 64,*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <Select*/}
              {/*      isFixed={true}*/}
              {/*      isDefaultOption={false}*/}
              {/*      value={pageLimit}*/}
              {/*      options={[*/}
              {/*        {*/}
              {/*          value: 10,*/}
              {/*          title: 10,*/}
              {/*        },*/}
              {/*        {*/}
              {/*          value: 100,*/}
              {/*          title: 100,*/}
              {/*        },*/}
              {/*      ]}*/}
              {/*      label="Показать"*/}
              {/*      onChange={(event) => setPageLimit(Number(event.target.value))}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*)}*/}

              {!!count && count > pageLimit && (
                <div style={{marginLeft: 8}}>
                  <Pagination
                    totalRecords={count}
                    pageLimit={pageLimit}
                    currentPage={pageNumber}
                    onPageChanged={(data) => changePage(data)}
                  />
                </div>
              )}

              {/*<ButtonIcon*/}
              {/*  tooltipText={"Добавить"}*/}
              {/*  iconName={"custom-add"}*/}
              {/*  iconColor={"#0064ff"}*/}
              {/*  size={36}*/}
              {/*  style={{marginLeft: 12}}*/}
              {/*  onClick={() => checkAddMyProduct()}*/}
              {/*/>*/}

              {(allCount > 0) && (
                <button
                  className="Action__button Action__button_blue"
                  style={{
                    fontSize: 15,
                    fontWeight: 600,
                    marginLeft: 12,
                    padding: "6px 8px",
                    borderRadius: 7
                  }}
                  onClick={() => checkAddMyProduct()}>
                  Добавить товар
                </button>
              )}
            </div>
          </div>
        )}

        {/*<hr className={"Page__hr"} style={{marginBottom: 6}}/>*/}

        {!list && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!list && list.length > 0 && (
          <div
            className="Page__content"
            style={{
              position: "relative",
              // height: "calc(100% - 48px - 26px - 2px - 10px - 28px)",
              height: "calc(100% - 64px)",
            }}
          >
            <div
              style={{
                overflow: "auto",
                width: "100%",
                height: "100%",
              }}
            >
              <MyProductsTable {...props} />
            </div>
          </div>
        )}


        {(allCount === 0) && (
          <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 28,
            fontWeight: 400
          }}>
            <span style={{
              opacity: .33,
              marginBottom: 16
            }}>
              Коллекция товаров, пока пуста
            </span>

            <button
              className="Action__button Action__button_blue"
              style={{
                fontSize: 15,
                fontWeight: 600,
                marginLeft: 12,
                padding: "6px 8px",
                borderRadius: 7
              }}
              onClick={() => checkAddMyProduct()}>
              Добавь первый товар
            </button>
          </div>
        )}

        {(allCount > 0) &&
          (!!list && list.length === 0) && (
            <div style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              flexWrap: "nowrap",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 28,
              fontWeight: 400,
              opacity: .33,
              marginBottom: 16
            }}>
              По результатам поиска ничего не найдено
            </div>
          )}

        {modalObjectAdd && (
          <ModalMyProductAdd
            successfulRequest={() => (setIsUpdateList(!isUpdateList), setIsUpdateProductCategories(!isUpdateProductCategories))}
            handleClose={() => setIsModalObjectAdd(false)}
          />
        )}

        {!!modalObjectView && (
          <ModalMyProductView
            object={modalObjectView}
            successfulRequest={() => (setIsUpdateList(!isUpdateList), setIsUpdateProductCategories(!isUpdateProductCategories))}
            handleClose={() => setIsModalObjectView(null)}
          />
        )}

        {!!modalObjectEdit && (
          <ModalMyProductEdit
            object={modalObjectEdit}
            successfulRequest={() => (setIsUpdateList(!isUpdateList), setIsUpdateProductCategories(!isUpdateProductCategories))}
            handleClose={() => setModalObjectEdit(null)}
          />
        )}

        {modalTbProductAdd && (
          <ModalTbProductAdd
            object={modalTbProductAdd}
            exchangeRate={exchangeRate}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalTbProductAdd(null)}
          />
        )}

        {modalTbProductView && (
          <ModalTbProductView
            object={modalTbProductView}
            exchangeRate={exchangeRate}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalTbProductView(null)}
          />
        )}

        {modalTbProductArticleView && (
          <ModalTbProductArticleView
            object={modalTbProductArticleView}
            exchangeRate={exchangeRate}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalTbProductArticleView(null)}
          />
        )}

        {isModalInfo && (
          <ModalMyProductInfo
            handleClose={() => setIsModalInfo(false)}
          />
        )}



      </div>
    </ScrollBlock>
  );
}

export default MyProductsPage;
