import React, {Fragment} from "react";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {Input, Loading} from "@vokymlak/kabinet-ui";

const ParserView = (props) => {
    const {
        object,
        inputLinkUrl,
        onChangeParser,
        isRequest,
        openLink,
        option,
    } = props;

    return (
      <ScrollBlock style={{ width: "100%", height: "100%" }}>
        <div className="Page">
            <div className="Page__title">
                <div className="Title__text" style={{display: "flex"}}>
                    Парсинг
                </div>

                <div className="Title__settings">
                </div>
            </div>

            <div className="Page__content">
                <div>
                    <div className="Form__field Form__field_last">
                        <Input reference={inputLinkUrl} type="text" label="Артикул"/>
                    </div>
                    <br/>
                    <div className="Form__actions Form__actions_center">
                        <button
                            className="Action__button Action__button_all Action__button_blue"
                            onClick={!isRequest ? () => onChangeParser() : () => null}
                        >
                            {!isRequest ? "Начать" : <Loading size="medium" color="blue" /> }
                        </button>
                    </div>
                </div>
                <div>
                    {!!object && (
                      <table className="Table">
                          <tbody>
                          <tr className="Table__tr">
                              <td className="Table__td" style={{width: 162}}>
                                  <div className="Td__title">Артикул</div>
                              </td>
                              <td className="Table__td">


                                  {(!!object.wb_article && (
                                    <span
                                      onClick={() => openLink(`https://www.wildberries.ru/catalog/${object.wb_article}/detail.aspx`)}
                                      style={{
                                          cursor: "pointer",
                                          color: "#2196f3",
                                          textDecoration: "underline",
                                      }}
                                    >
                                  {object.wb_article}
                              </span>
                                  )) || "-"}
                              </td>
                          </tr>
                          {!!object.wb_detail &&
                            !!object.wb_detail.data &&
                            !!object.wb_detail.data.products && (
                              object.wb_detail.data.products.map((product, index) => (
                                String(product.id) === object.wb_article.article && (
                                  <Fragment key={'product_'+index}>
                                      <tr className="Table__tr">
                                          <td className="Table__td">
                                              <div className="Td__title">Название</div>
                                          </td>
                                          <td className="Table__td">
                                              {(!!product.name && product.name) || "-"}
                                          </td>
                                      </tr>

                                      <tr className="Table__tr">
                                          <td className="Table__td">
                                              <div className="Td__title">Бренд</div>
                                          </td>
                                          <td className="Table__td">
                                              {(!!product.brand && product.brand) || "-"}
                                          </td>
                                      </tr>

                                      <tr className="Table__tr">
                                          <td className="Table__td">
                                              <div className="Td__title">Цена</div>
                                          </td>
                                          <td className="Table__td">
                                              {(!!product.priceU && new Intl.NumberFormat("ru-RU").format(
                                                  (product.priceU / 100).toFixed(2)
                                                )) ||
                                                "-"}
                                          </td>
                                      </tr>

                                      <tr className="Table__tr">
                                          <td className="Table__td">
                                              <div className="Td__title">Цена продажи</div>
                                          </td>
                                          <td className="Table__td">
                                              {(!!product.salePriceU && new Intl.NumberFormat("ru-RU").format(
                                                  (product.salePriceU / 100).toFixed(2)
                                                )) ||
                                                "-"}
                                          </td>
                                      </tr>

                                      <tr className="Table__tr">
                                          <td className="Table__td">
                                              <div className="Td__title">Категория</div>
                                          </td>
                                          <td className="Table__td">
                                              {(!!object.wb_card.subj_root_name && object.wb_card.subj_root_name) ||
                                                "-"}
                                          </td>
                                      </tr>

                                      <tr className="Table__tr">
                                          <td className="Table__td">
                                              <div className="Td__title">Предмет</div>
                                          </td>
                                          <td className="Table__td">
                                              {(!!object.wb_card.subj_name && object.wb_card.subj_name) ||
                                                "-"}
                                          </td>
                                      </tr>

                                  </Fragment>
                                ))))}

                          {/*{!!link.product &&*/}
                          {/*  !!link.product.price_history && (*/}
                          {/*    <tr className="Table__tr">*/}
                          {/*        <td className="Table__td" colSpan={2}>*/}
                          {/*            <div className="Td__title">*/}
                          {/*                <ReactECharts*/}
                          {/*                  option={option(link.product)}*/}
                          {/*                  style={{ height: "114px", width: "100%" }}*/}
                          {/*                />*/}
                          {/*            </div>*/}
                          {/*        </td>*/}
                          {/*    </tr>*/}
                          {/*  )}*/}

                          {!!object.wb_card && (
                              <>
                                  <tr className="Table__tr">
                                      <td className="Table__td">
                                          <div className="Td__title">Описание</div>
                                      </td>
                                      <td className="Table__td">
                                          {(!!object.wb_card.description && object.wb_card.description) || "-"}
                                      </td>
                                  </tr>
                                  {!!object.wb_card.grouped_options &&
                                    object.wb_card.grouped_options.map((optionGroup, optionGroupIndex) => (
                                      <Fragment key={'option_group_'+optionGroupIndex}>
                                          <tr className="Table__tr">
                                              <td className="Table__td" colSpan={2} style={{
                                                  backgroundColor: "#20202025"
                                              }}>
                                                  <div className="Td__title">
                                                      {(!!optionGroup.group_name && optionGroup.group_name) || "-"}
                                                  </div>
                                              </td>
                                          </tr>
                                          {!!optionGroup.options && optionGroup.options.map((option, optionIndex) => (
                                            <Fragment key={'option_'+optionIndex}>
                                                <tr className="Table__tr">
                                                    <td className="Table__td">
                                                        <div className="Td__title">
                                                            {(!!option.name && option.name) || "-"}
                                                        </div>
                                                    </td>
                                                    <td className="Table__td">
                                                        {(!!option.value && option.value) || "-"}
                                                    </td>
                                                </tr>
                                            </Fragment>
                                          ))}
                                      </Fragment>
                                    ))}
                              </>
                            )}




                          <tr className="Table__tr">
                              <td className="Table__td" style={{width: 162}}>
                                  <div className="Td__title">
                                      Фото
                                  </div>
                              </td>
                              <td className="Table__td">
                                  {(!!object.wb_image && (
                                    <img
                                      src={"data:image/jpeg;base64," + object.wb_image}
                                    />
                                  )) || "-"}
                              </td>
                          </tr>
                          <tr className="Table__tr">
                              <td className="Table__td" style={{width: 162}}>
                                  <div className="Td__title">
                                      Скриншот
                                  </div>
                              </td>
                              <td className="Table__td">
                                  {(!!object.full_screenshot && (
                                  <img
                                    src={"data:image/png;base64," + object.full_screenshot}
                                    style={{
                                        width: 640,
                                    }}
                                  />
                                  )) || "-"}
                              </td>
                          </tr>

                          </tbody>
                      </table>
                    )}
                </div>
            </div>
        </div>
      </ScrollBlock>
    );
};

export default ParserView;
