import React from "react";
import { WbAvailabilityTableHead } from "./WbAvailabilityTableHead";
import { WbAvailabilityList } from "./WbAvailabilityList";

export const WbAvailabilityTable = (props) => {
  const { list } = props;

  return (
      <table className="Table">
        <thead className="Thead">
          <WbAvailabilityTableHead {...props} />
        </thead>
        <tbody>{!!list && <WbAvailabilityList {...props} />}</tbody>
      </table>
  );
};
