import React, { useEffect, useRef, useState } from "react";
import {useList, useAction, useUnroll} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";

function useMpstatsSubjects() {
  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
  } = useList({
    apiUrl: "/api/mpstats-subjects",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 25,
    isSearch: true,
  });

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const inputSearchText = useRef(null);
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  const onChangeSearchText = () => {
    setSearchQuery(inputSearchText.current.value);
  };

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
      changeAllUnroll(list, "uuid");
    }
  };

  const { setAction } = useAction("info", () => updateList());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "info"]);
    } else {
      setAction("info");
    }
  }, [list]);

  const [modalImport, setModalImport] = useState(null);
  const [isModalKeyEdit, setIsModalKeyEdit] = useState(null);

  const [modalItemSubjectImport, setModalItemSubjectImport] = useState(null);
  const [modalItemSellersImport, setModalItemSellersImport] = useState(null);
  const [modalItemGeographyImport, setModalItemGeographyImport] = useState(null);
  const [modalItemSummaryImport, setModalItemSummaryImport] = useState(null);
  const [modalItemCheckRatesImport, setModalItemCheckRatesImport] = useState(null);

  return {
    list,
    setList,
    count,

    setIsUpdateList,
    isUpdateList,

    setModalImport,
    modalImport,

    isModalKeyEdit,
    setIsModalKeyEdit,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setModalItemSubjectImport,
    modalItemSubjectImport,
    setModalItemSellersImport,
    modalItemSellersImport,
    setModalItemGeographyImport,
    modalItemGeographyImport,
    setModalItemSummaryImport,
    modalItemSummaryImport,
    setModalItemCheckRatesImport,
    modalItemCheckRatesImport,
  };
}

export default useMpstatsSubjects;
