import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  Textarea,
  useNotification,
  Modal,
  useObject,
  jsonFormatText,
  isValidateNullText, Input, Select,
} from "@vokymlak/kabinet-ui";
import moment from "moment";

function ModalSiteCallbackEditAction(props) {
  const [selectStage, setSelectStage] = useState(null);

  const {
    object: [object],
  } = useObject({
    apiUrl: "api/site-callback/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackResponse: (data) => {
      setSelectStage((!!data && !!data.site_callback_status_type && data.site_callback_status_type.tag) || null);
    },
    callbackError: () => {
      props.handleClose();
    },
  });


  const selectOrderStageType = useRef(null);

  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    let orderStageType = selectOrderStageType.current.value;
    orderStageType = orderStageType.length > 0
      ? orderStageType === "null"
        ? null
        : orderStageType
      : null

    const jsonData = JSON.stringify({
      site_callback_status_type: !!orderStageType ? {
        tag: orderStageType,
      } : null,
    });

    axios
      .put("/api/site-callback/" + props.object.uuid + "/edit/action", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeStage = () => {
    let orderStageType = selectOrderStageType.current.value;
    orderStageType = orderStageType.length > 0
      ? orderStageType === "null"
        ? null
        : orderStageType
      : null
    setSelectStage(orderStageType)
  }

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeEdit(event)}>
          <div
            className="Form__title"
            style={{ fontSize: 19, fontWeight: 700 }}
          >
            №{object.id}{!!object.date && ' от ' + moment(object.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}
            <div className="Text__signature">Заявка с сайта</div>
          </div>

          <div className={"Form__field Form__field_last"}>
            <Select
              isFixed={true}
              reference={selectOrderStageType}
              options={[
                {
                  value: "NEW",
                  title: "Новая",
                },
                {
                  value: "AT_WORK",
                  title: "В обработке",
                },
                {
                  value: "COMPLETED",
                  title: "Закрыта",
                },
                {
                  value: "CANCELLED",
                  title: "Отменена",
                }
                ]}
              label="Статус"
              onChange={() => changeStage()}
              value={!!object.site_callback_status_type && object.site_callback_status_type.tag}
            />
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Применить
            </button>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalSiteCallbackEditAction;
