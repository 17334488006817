import React, {Fragment} from "react";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {Input, Loading} from "@vokymlak/kabinet-ui";

const ParserView = (props) => {
    const {
        inputTicketFile,
        fileSend,
        link,
        onChangeParser,
        isRequest,
        openLink,
    } = props;

    return (
      <ScrollBlock style={{ width: "100%", height: "100%" }}>
        <div className="Page">
            <div className="Page__title">
                <div className="Title__text" style={{display: "flex"}}>
                    Парсинг
                </div>

                <div className="Title__settings">
                </div>
            </div>

            <div className="Page__content">
                <div>
                    <div className="Form__field Form__field_last">
                        <Input  type="file" label="Картинка"
                               reference={inputTicketFile}
                               isFixed={true}
                               onChange={(event) => fileSend(event)}
                               isMultiple={false}/>
                    </div>
                    <br/>
                    <div className="Form__actions Form__actions_center">
                        <button
                            className="Action__button Action__button_all Action__button_blue"
                            onClick={!isRequest ? () => onChangeParser() : () => null}
                        >
                            {!isRequest ? "Начать" : <Loading size="medium" color="blue" /> }
                        </button>
                    </div>
                </div>
                <div>
                    {!!link && (
                      <table className="Table">
                          <tbody>



                                  <tr className="Table__tr">
                                      <td className="Table__td" colSpan={4}>
                                          {(!!link.taobao_search_link && (
                                            <span
                                              onClick={() => openLink(`${link.taobao_search_link}`)}
                                              style={{
                                                  cursor: "pointer",
                                                  color: "#2196f3",
                                                  textDecoration: "underline",
                                              }}
                                            >
                                  Список товаров
                                            </span>
                                          )) || "-"}
                                      </td>
                                  </tr>
                                  {!!link.taobao_offer_result && !!link.taobao_offer_result.data && !!link.taobao_offer_result.data.offerList &&
                                    !!link.taobao_offer_result.data.offerList.offers &&
                                    link.taobao_offer_result.data.offerList.offers.map((offer) => (
                                          <tr className="Table__tr" key={'offer_'+offer.id}>
                                              <td className="Table__td">
                                                  <div className="Td__title">
                                                      {(!!offer.id &&
                                                        <span
                                                          onClick={() => openLink(`https://detail.1688.com/offer/${offer.id}.html`)}
                                                          style={{
                                                              cursor: "pointer",
                                                              color: "#2196f3",
                                                              textDecoration: "underline",
                                                          }}
                                                        >
                                              {offer.id}
                                          </span>
                                                      ) || "-"}
                                                  </div>
                                              </td>
                                              <td className="Table__td">
                                                  {(!!offer.originalPrice && offer.originalPrice) || "-"}
                                              </td>
                                              <td className="Table__td">
                                                  {(!!offer.subject && offer.subject) || "-"}
                                              </td>
                                              <td className="Table__td">
                                                  {(!!offer.offerPicUrl && (
                                                        <div
                                                          style={{
                                                              backgroundImage: `url("${offer.offerPicUrl}")`,
                                                              borderRadius: 5,
                                                              backgroundPosition: "center",
                                                              backgroundRepeat: "no-repeat",
                                                              backgroundSize: "cover",
                                                              width: 100,
                                                              height: 74
                                                          }}
                                                        >
                                                        </div>
                                                      )) || "-"}
                                              </td>
                                          </tr>
                                    ))}


                          <tr className="Table__tr">
                              <td className="Table__td" style={{width: 162}}>
                                  <div className="Td__title">
                                      Скриншот
                                  </div>
                              </td>
                              <td className="Table__td" colSpan={3}>
                                  <br/>
                                  <img
                                    src={"data:image/png;base64," + link.full_screenshot}
                                    style={{
                                        width: 640,
                                    }}
                                  />
                              </td>
                          </tr>

                          </tbody>
                      </table>
                    )}
                </div>
            </div>
        </div>
      </ScrollBlock>
    );
};

export default ParserView;
