import React, {useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {
  getErrorMessageByKey,
  Input,
  jsonFormatText,
  Modal,
  Textarea,
  useNotification,
  useObject,
} from "@vokymlak/kabinet-ui";

export const ModalEditTariff = (props) => {
  const [isAvailable, setIsAvailable] = useState(false);
  const [isForSale, setIsForSale] = useState(false);
  const [isForTrial, setIsForTrial] = useState(false);

  const [isDelete, setIsDelete] = useState(false);

  const inputName = useRef(null);
  const inputInfo = useRef(null);

  const inputTariffCost = useRef(null);
  const inputRequestCount = useRef(null);

  const {notification} = useNotification();

  const {
    object: [tariff],
  } = useObject({
    apiUrl: "/api/tariff/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackResponse: (data) => {
      setIsAvailable(!!data && data.is_available);
      setIsForSale(!!data && data.is_for_sale);
      setIsForTrial(!!data && data.is_for_trial);
    },
    callbackError: () => {
      props.handleClose();
    },
  });

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const changeEdit = (event) => {
    event.preventDefault();

    const name = jsonFormatText(inputName.current.value);
    const info = jsonFormatText(inputInfo.current.value);

    // const tariffCost = jsonFormatText(inputTariffCost.current.value);
    const requestCount = jsonFormatText(inputRequestCount.current.value);

    let tariffCost = null;

    if (isForTrial) {
    } else {
      tariffCost = jsonFormatText(inputTariffCost.current.value);
    }

    // if (
    //   // isValidateNullText(tariffCost) ||
    //   isValidateNullText(requestCount)
    // ) {
    //   notification("Заполните все обязательные поля", "red");
    //   return;
    // }

    const jsonData = JSON.stringify({
      name: name,
      info: info,
      tariff_cost: !isForTrial ? numberConvert(tariffCost) : null,
      day_request_count: Number(requestCount),
      is_available: isAvailable,
      is_for_sale: isForSale,
    });

    axios
      .put("/api/tariff/" + props.object.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };


  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("/api/tariff/" + props.object.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Тариф удалён", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!tariff && (
      <Modal handleClose={() => props.handleClose()}>
        {!isDelete && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div
              className="Form__title"
              style={{fontSize: 19, fontWeight: 700}}
            >
              {(!!tariff.tariff_type && tariff.tariff_type.name) || "-"}
              <div className="Text__signature">Тариф</div>
            </div>

            <div className="Form__field">
              <Input reference={inputName} label="Название"
                     value={!!tariff.name && tariff.name}/>
            </div>

            <div className="Form__field">
              <Textarea reference={inputInfo} label="Описание"
                        value={!!tariff.info && tariff.info}/>
            </div>

            {!isForTrial && (
              <div className="Form__field">
                <Input reference={inputTariffCost} label="Цена тарифа" type={"number"}
                       value={!!tariff.tariff_cost && (tariff.tariff_cost / 100)}/>
              </div>
            )}

            <div className="Form__field">
              <Input reference={inputRequestCount} label="Количество запросов" type={"number"}
                     value={!!tariff.day_request_count && tariff.day_request_count}/>
            </div>

            <div className="Form__field">
              <div
                className="Button__swith"
                style={{
                  padding: "8px",
                  width: "100%",
                  border: isAvailable
                    ? "2px solid #ffe082"
                    : "2px dashed #20202015",
                  backgroundColor: isAvailable ? "#ffe082" : "#ffffff",
                  borderRadius: 6,
                  marginTop: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => setIsAvailable(!isAvailable)}
              >
                <div
                  className="Swith__text"
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Доступен
                </div>
                <div
                  className={
                    "Swith Swith_gray" +
                    ((isAvailable && " Swith_active Swith_active_gray") || "")
                  }
                ></div>
              </div>
            </div>

            <div className="Form__field Form__field_last">
              <div
                className="Button__swith"
                style={{
                  padding: "8px",
                  width: "100%",
                  border: isForSale
                    ? "2px solid #ffe082"
                    : "2px dashed #20202015",
                  backgroundColor: isForSale ? "#ffe082" : "#ffffff",
                  borderRadius: 6,
                  marginTop: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => setIsForSale(!isForSale)}
              >
                <div
                  className="Swith__text"
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Выставлен на продажу
                </div>
                <div
                  className={
                    "Swith Swith_gray" +
                    ((isForSale && " Swith_active Swith_active_gray") || "")
                  }
                ></div>
              </div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            <div className="Form__actions">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>
              <div className="Action__link" onClick={() => props.handleClose()}>
                Отмена
              </div>
            </div>
          </form>
        )}
        {!!isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div
              className="Form__title"
              style={{fontSize: 19, fontWeight: 700}}
            >
              {(!!tariff.tariff_type && tariff.tariff_type.name) || "-"}
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Продолжить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsDelete(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
};
