import React from "react";
import { WbReturnCostTableHead } from "./WbReturnCostTableHead";
import { WbReturnCostList } from "./WbReturnCostList";

export const WbReturnCostTable = (props) => {
  const { list } = props;

  return (
      <table className="Table">
        <thead className="Thead">
          <WbReturnCostTableHead {...props} />
        </thead>
        <tbody>{!!list && <WbReturnCostList {...props} />}</tbody>
      </table>
  );
};
