import React from "react";
import { InfoExchangeRatesTableHead } from "./InfoExchangeRatesTableHead";
import { InfoExchangeRatesList } from "./InfoExchangeRatesList";

export const InfoExchangeRatesTable = (props) => {
  const { list } = props;

  return (
      <table className="Table">
        <thead className="Thead">
          <InfoExchangeRatesTableHead {...props} />
        </thead>
        <tbody>{!!list && <InfoExchangeRatesList {...props} />}</tbody>
      </table>
  );
};
