import React, {Fragment, useState} from "react";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {
  ButtonIcon,
  getErrorMessageByKey,
  Icon,
  Loading,
  Pagination,
  Search,
  TextSearchMarker, Tooltip
} from "@vokymlak/kabinet-ui";
import useMyTop from "./useMyTop";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {toast} from "sonner";
import {AnalysisSubjectSummary} from "./AnalysisSubjectSummary";
import ModalMyProductInfo from "../MyProducts/ModalMyProductInfo";
import ModalMyTopInfo from "./ModalMyTopInfo";

function MyTopPage({myTariff}) {
  const props = useMyTop(myTariff);
  const {
    list,
    setList,
    count,
    allCount,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    inputSearchText,
    onChangeSearchText,
    searchQuery,
  } = props;

  const [isModalInfo, setIsModalInfo] = useState(false);

  const [isRequest, setIsRequest] = useState(false);

  const getObjectByJson = (json) => {
    return JSON.parse(json);
  }

  const changeAdd = (article) => {
    setIsRequest(true)

    const jsonData = JSON.stringify({
      wb_article: String(article),
    });

    axios
      .post("/api/my/product/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        toast.success("Товар добавлен");
      })
      .catch((error) => {
        // console.error(error)
        if (error.response.status === 402) {
          toast.error("Превышен лимит запросов на сегодня");
        } else {
          toast.error(getErrorMessageByKey(error.response.data.error));
        }
      })
      .finally(() => {
        setIsRequest(false);
      });
  };

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
                marginRight: 12
              }}
            >
              <div>
                Топ ниш
              </div>
            </div>

            <ButtonIcon
              // tooltipText={
              //   "В&nbsp;данном&nbsp;разделе&nbsp;вы&nbsp;можете\n" +
              //   "посмотреть&nbsp;ниши&nbsp;которые&nbsp;в&nbsp;данный\n" +
              //   "момент&nbsp;пользуются&nbsp;наибольшим&nbsp;спросом\n" +
              //   "для&nbsp;продаж&nbsp;на&nbsp;маркетплейсе.\n" +
              //   "Так&nbsp;же&nbsp;в&nbsp;этом&nbsp;разделе&nbsp;показаны&nbsp;наиболее\n" +
              //   "популярные&nbsp;товары&nbsp;в&nbsp;этих&nbsp;нишах,\n" +
              //   "для&nbsp;быстрого&nbsp;поиска&nbsp;иде\n" +
              //   "товаров&nbsp;для&nbsp;реализации"
              // }
              tooltipText={
                "О&nbsp;разделе"
              }
              iconName={"custom-info"}
              iconColor={"#20202075"}
              size={22}
              style={{marginRight: 16}}
              onClick={() => setIsModalInfo(true)}
            />

            {(allCount > 0) && (
              <span style={{width: 160, marginRight: 16}}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
            )}
          </div>

          <div className="Title__settings">

            {!!count && count > pageLimit && (
              <div style={{marginLeft: 8}}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}

            {/*<ButtonIcon*/}
            {/*  tooltipText={"Обновить"}*/}
            {/*  iconName={"custom-change-circle"}*/}
            {/*  iconColor={"#20202075"}*/}
            {/*  size={28}*/}
            {/*  style={{ marginLeft: 12 }}*/}
            {/*  onClick={() => (setList(null), setIsUpdateList(!isUpdateList), setIsUpdateObject(!isUpdateObject))}*/}
            {/*/>*/}
          </div>
        </div>

        {/*<hr className={"Page__hr"} style={{marginBottom: 6}}/>*/}

        {!list && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!list && list.length > 0 && (
          <div
            className="Page__content"
            style={{
              position: "relative",
              // height: "calc(100% - 48px - 26px - 2px - 10px - 28px)",
            }}
          >

            <ul className="List">
              {list.map((item) => (
                <Fragment key={"item_" + item.uuid}>
                  <li
                    className={
                      isUnroll(item.uuid)
                        ? "List__item active"
                        : "List__item"
                    }
                    onClick={() => changeUnroll(item.uuid)}
                    style={{
                      paddingLeft: 14,
                      // cursor: "pointer",
                      // backgroundColor: "#ffffff"
                    }}
                  >
                    <div className="Item__title" style={{
                      width: "100%"
                    }}>
                      <div className="Element__title" style={{
                        width: "100%"
                      }}>
                        <div style={{
                          width: "100%"
                        }}>
                          <div
                            style={{
                              fontSize: 21,
                              fontWeight: 600,
                              marginTop: 4,
                              marginBottom: 12,
                            }}>
                            {(!!item.name && (
                              <TextSearchMarker
                                text={item.name}
                                search={searchQuery}
                              />
                            )) || "-"}
                          </div>

                          {!!item.analysis_data && !!item.analysis_data.mpstats_summary && (
                            <AnalysisSubjectSummary mpSummaryJson={item.analysis_data.mpstats_summary}/>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="Item__settings">
                      <div className="Element__datetime">
                        <Tooltip
                          text={isUnroll(item.uuid) ? "Свернуть" : "Развернуть" }
                          style={{
                            marginLeft: 8,
                            marginRight: 12
                          }}
                        >
                          <div style={{height: 24}}>
                            <Icon
                              name={isUnroll(item.uuid) ?  "custom-expand-circle-up" : "custom-expand-circle-down"}
                              size={24}
                              color={"#202020"}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </li>

                  {isUnroll(item.uuid) && (
                    <li className="List__content">
                      <div
                        style={{
                          fontSize: 12,
                          fontWeight: 400,
                          width: "100%",
                          marginTop: 12
                        }}
                      >
                        <ul style={{
                          width: "100%"
                        }}>
                          {!!item.analysis_data && !!item.analysis_data.mpstats_subject &&
                            getObjectByJson(item.analysis_data.mpstats_subject).data.slice(0, 25).map((product) => (
                              <Fragment key={"item_item_" + product.id}>
                                <li
                                  className={"List__item"}
                                  style={{
                                    paddingLeft: 8,
                                    cursor: "default",
                                    width: "100%",
                                    backgroundColor: "#20202010"
                                  }}
                                >
                                  <div className="Item__title">
                                    <div className="Element__title">
                                      <div
                                        style={{
                                          backgroundImage: `url("${product.thumb}")`,
                                          borderRadius: 7,
                                          backgroundPosition: "center",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "cover",
                                          width: 42,
                                          height: 42,
                                          marginRight: 12
                                        }}
                                      >
                                      </div>
                                      <div>
                                        <div
                                          style={{
                                            fontSize: 16,
                                            fontWeight: 400,
                                          }}>
                                          {(!!product.name && product.name) || "-"}
                                        </div>
                                        <div
                                          style={{
                                            fontSize: 14,
                                            fontWeight: 400,
                                          }}
                                        >
                                          <a
                                            target={"_blank"}
                                            href={`https://www.wildberries.ru/catalog/${product.id}/detail.aspx`}
                                            style={{
                                              cursor: "pointer",
                                              color: "#2196f3",
                                              textDecoration: "underline",
                                            }}
                                          >
                                            {product.id}
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="Item__settings">
                                    <div className="Element__datetime">
                                      <button className="Action__button Action__button_mini Action__button_blue"
                                              onClick={!isRequest ? () => changeAdd(product.id) : () => null}>
                                        Добавить в коллекцию
                                      </button>
                                    </div>
                                  </div>
                                </li>
                              </Fragment>
                            ))}
                        </ul>

                      </div>
                    </li>
                  )}
                </Fragment>
              ))}
            </ul>

          </div>
        )}

        {(allCount === 0) && (
          <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 28,
            fontWeight: 400
          }}>
            <span style={{
              opacity: .33,
              marginBottom: 16
            }}>
              Топ ниш, пока пуста
            </span>
          </div>
        )}

        {(allCount > 0) &&
          (!!list && list.length === 0) && (
            <div style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              flexWrap: "nowrap",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 28,
              fontWeight: 400,
              opacity: .33,
              marginBottom: 16
            }}>
              По результатам поиска ничего не найдено
            </div>
          )}

        {isModalInfo && (
          <ModalMyTopInfo
            handleClose={() => setIsModalInfo(false)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default MyTopPage;
