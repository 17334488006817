import React from "react";

export const MyCalculationTwoTableHeadNew = (props) => {
  const {} = props;

  return (
    <>
      <tr className="Table__tr Table__tr_padding Table__tr_border">
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
          colSpan={6}
        >
          <div className="Th__title">Информация о товаре</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#ffe082",
          }}
          colSpan={5}
        >
          <div className="Th__title">Затраты на услуги ВБ</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#bbdefb",
          }}
          colSpan={6}
        >
          <div className="Th__title">Экономика</div>
        </th>
      </tr>
      <tr className="Table__tr Table__tr_padding Table__tr_border">
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Средний объем продаж в нише</div>
        </th>
        {/*<th*/}
        {/*  className="Table__th Table__th_border"*/}
        {/*  style={{*/}
        {/*    backgroundColor: "#dfdfdf",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <div className="Th__title">Стоимость партии</div>*/}
        {/*</th>*/}
        {/*<th*/}
        {/*  className="Table__th Table__th_border"*/}
        {/*  style={{*/}
        {/*    backgroundColor: "#dfdfdf",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <div className="Th__title">Объем изделия</div>*/}
        {/*</th>*/}
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Оборачиваемость</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Процент выкупа</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Потенциальные продажи/мес</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Вес партии</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Вложения в партию</div>
        </th>

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#ffe082",
          }}
        >
          <div className="Th__title">Тип поставки</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#ffe082",
          }}
        >
          <div className="Th__title">Расходы на рекламу</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#ffe082",
          }}
        >
          <div className="Th__title">Комиссия</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#ffe082",
          }}
        >
          <div className="Th__title">Логистика</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#ffe082",
          }}
        >
          <div className="Th__title">Хранение</div>
        </th>

        {/*<th*/}
        {/*  className="Table__th Table__th_border"*/}
        {/*  style={{*/}
        {/*    backgroundColor: "#ffe082",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <div className="Th__title">Рекламный бюджет/сут</div>*/}
        {/*</th>*/}
        {/*<th*/}
        {/*  className="Table__th Table__th_border"*/}
        {/*  style={{*/}
        {/*    backgroundColor: "#ffe082",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <div className="Th__title">Рекламный бюджет/мес</div>*/}
        {/*</th>*/}
        {/*<th*/}
        {/*  className="Table__th Table__th_border"*/}
        {/*  style={{*/}
        {/*    backgroundColor: "#ffe082",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <div className="Th__title">Реклама на 1 товар</div>*/}
        {/*</th>*/}
        {/*<th*/}
        {/*  className="Table__th Table__th_border"*/}
        {/*  style={{*/}
        {/*    backgroundColor: "#ffe082",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <div className="Th__title">Налог с 1 товара</div>*/}
        {/*</th>*/}
        {/*<th*/}
        {/*  className="Table__th Table__th_border"*/}
        {/*  style={{*/}
        {/*    backgroundColor: "#ffe082",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <div className="Th__title">Затраты на поставку 1 товара на склад ВБ</div>*/}
        {/*</th>*/}
        {/*<th*/}
        {/*  className="Table__th Table__th_border"*/}
        {/*  style={{*/}
        {/*    backgroundColor: "#ffe082",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <div className="Th__title">Услуги ВБ на 1 товар</div>*/}
        {/*</th>*/}

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#bbdefb",
          }}
        >
          <div className="Th__title">Валовый доход с партии</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#bbdefb",
          }}
        >
          <div className="Th__title">Расходы на партию</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#bbdefb",
          }}
        >
          <div className="Th__title">Налог с партии</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#bbdefb",
          }}
        >
          <div className="Th__title">Прибыль с партии</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#bbdefb",
          }}
        >
          <div className="Th__title">Прибыль в процентах</div>
        </th>
      </tr>
    </>
  );
}
