import React, {Fragment, useEffect, useState} from "react";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {ButtonIcon, Icon, Loading, Pagination, Search, Select, TextSearchMarker} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";
import styleTumbler from "../../../styles/modules/Tumbler.module.scss";
import ModalMyCalculationEdit from "./ModalMyCalculationEdit";
import useMyCalculation from "./useMyCalculation";
import {AnalysisSubject} from "./AnalysisSubject";
import {MyCalculationTwoTableNew,} from "./MyCalculationTwoTableNew";
import {MyCalculationTableNew} from "./MyCalculationTableNew";
import {
  getAmount,
  getAnalysisData,
  getPackageHeight,
  getPackageLength,
  getPackageWeight,
  getPackageWidth,
  getWbPrice,
  housekeeperCalculationNew
} from "./housekeeperCalculationNew";
import {NavLink} from "react-router-dom";
import TelegramIcon from './telegram.svg';
import ModalFulfillment from "./ModalFulfillment";

function MyCalculationPage({myTariff}) {
  const props = useMyCalculation(myTariff)
  const {
    exchangeRate,

    wbDelivery,
    wbReturnCost,

    object,

    list,
    setList,
    count,
    allCount,

    setIsUpdateList,
    isUpdateList,

    isUpdateObject,
    setIsUpdateObject,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    isFbo,
    setIsFbo,

    fulfillmentPrice,
    setFulfillmentPrice,
    taxPercent,
    setTaxPercent,
    deliveryPreUsdPrice,
    setDeliveryPreUsdPrice,
    marketingPercent,
    setMarketingPercent,
    additionalExpenses,
    setAdditionalExpenses,

    isModalFilterEdit,
    setIsModalFilterEdit,
    filterMyCalculation,
    setFilterMyCalculation,

    selectStorage,
    setSelectStorage,
  } = props;

  const [tumbler, setTumbler] = useState("a");

  const [isModalFulfillment, setIsModalFulfillment] = useState(false);

  const [analysisData, setAnalysisData] = useState(null);
  useEffect(() => {
    setAnalysisData(getAnalysisData(object))
  }, [object])
  useEffect(() => {
    return () => {
      setAnalysisData(null);
    }
  }, [])

  return (
    !!object &&
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 17,
                fontWeight: 700,
                marginRight: 12
              }}
            >
              <div>
                {object.name}
                {!!list && (
                  <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                    ({count})
                  </span>
                )}
              </div>
              <span
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                  Расчёт по категории
              </span>
            </div>


            {(allCount > 0) && (
              <span style={{width: 160, marginRight: 16}}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
            )}
          </div>


          <div className="Title__settings">

            {/*{(!!count && !!pageLimit && (count > 10)) && (*/}
            {/*  <div*/}
            {/*    style={{*/}
            {/*      marginLeft: 12,*/}
            {/*      width: 64,*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <Select*/}
            {/*      isFixed={true}*/}
            {/*      isDefaultOption={false}*/}
            {/*      value={pageLimit}*/}
            {/*      options={[*/}
            {/*        {*/}
            {/*          value: 10,*/}
            {/*          title: 10,*/}
            {/*        },*/}
            {/*        {*/}
            {/*          value: 50,*/}
            {/*          title: 50,*/}
            {/*        },*/}
            {/*      ]}*/}
            {/*      label="Показать"*/}
            {/*      onChange={(event) => setPageLimit(Number(event.target.value))}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*)}*/}

            {!!count && count > pageLimit && (
              <div style={{marginLeft: 8}}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}
          </div>
        </div>

        <div className="Page__title" style={{
          marginBottom: 4
        }}>
          <div className="Title__text">
            <div className="Title__text">
              <span style={{
                fontSize: 16,
                marginRight: 12,
                // backgroundColor: "#f1f1f1",
                // backgroundColor: "#fff",
                padding: "5px 3px",
                // padding: "7px 7px",
                borderRadius: 5,
                border: "2px solid #dbdbdb"
              }}>
                <div className="Button__swith">
                              <div className="Swith__text">FBS</div>
                              <div
                                className={
                                  "Swith" +
                                  ((!isFbo && " Swith_active ") ||
                                    "")
                                }
                                onClick={() => setIsFbo(!isFbo)}
                              ></div>
                            </div>
              </span>


              <span style={{
                fontSize: 16,
                marginRight: 12,
                backgroundColor: "#fff",
                padding: 8,
                borderRadius: 5,
                cursor: "pointer"
              }}

                    onClick={() => setIsModalFilterEdit(true)}>

                <span><span style={{fontSize: 12}}>Доставка&nbsp;1&nbsp;</span><span
                  style={{fontSize: 12}}>кг.</span>&nbsp;:&nbsp;{new Intl.NumberFormat("ru-RU").format(
                  (deliveryPreUsdPrice / 100).toFixed(2))}&nbsp;<span style={{fontSize: 12}}>долл.</span></span>

              </span>

              <span style={{
                fontSize: 16,
                marginRight: 12,
                backgroundColor: "#fff",
                padding: 8,
                borderRadius: 5,
                cursor: "pointer"
              }}

                    onClick={() => setIsModalFilterEdit(true)}>
                <span><span style={{fontSize: 12}}>Фулфилмент</span>&nbsp;:&nbsp;{new Intl.NumberFormat("ru-RU").format(
                  (fulfillmentPrice / 100).toFixed(2))}&nbsp;<span style={{fontSize: 12}}>руб.</span></span>
              </span>

              <span style={{
                fontSize: 16,
                marginRight: 12,
                backgroundColor: "#fff",
                padding: 8,
                borderRadius: 5,
                cursor: "pointer"
              }}

                    onClick={() => setIsModalFilterEdit(true)}>
                <span><span
                  style={{fontSize: 12}}>Расходы на рекламу</span>&nbsp;:&nbsp;{new Intl.NumberFormat("ru-RU").format(
                  (marketingPercent / 100).toFixed(2))}&nbsp;<span style={{fontSize: 12}}>%</span></span>
              </span>

              <span style={{
                fontSize: 16,
                marginRight: 12,
                backgroundColor: "#fff",
                padding: 8,
                borderRadius: 5,
                cursor: "pointer"
              }}

                    onClick={() => setIsModalFilterEdit(true)}>
                <span><span style={{fontSize: 12}}>Налог</span>&nbsp;:&nbsp;{new Intl.NumberFormat("ru-RU").format(
                  (taxPercent / 100).toFixed(2))}&nbsp;<span style={{fontSize: 12}}>%</span></span>
              </span>

              <span style={{
                fontSize: 16,
                marginRight: 12,
                backgroundColor: "#fff",
                padding: 8,
                borderRadius: 5,
                cursor: "pointer"
              }}

                    onClick={() => setIsModalFilterEdit(true)}>
                <span><span
                  style={{fontSize: 12}}>Доп. расходы</span>&nbsp;:&nbsp;{new Intl.NumberFormat("ru-RU").format(
                  (additionalExpenses / 100).toFixed(2))}&nbsp;<span style={{fontSize: 12}}>руб.</span></span>
              </span>

              <ButtonIcon
                iconName={"custom-edit"}
                iconColor={"#202020"}
                size={32}
                style={{marginRight: 8}}
                onClick={() => setIsModalFilterEdit(true)}
              />
            </div>
          </div>

          <div className="Title__settings">
          </div>
        </div>

        {!!object.analysis_data && (
          <AnalysisSubject
            analysisData={object.analysis_data}
            subject={object} isFbo={isFbo}
            selectStorage={selectStorage}
            setSelectStorage={setSelectStorage}
          />
        )}

        <hr className={"Page__hr"} style={{marginBottom: 6}}/>

        {!list && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!list && list.length > 0 && (
          <div
            className="Page__content"
            style={{
              position: "relative",
              // height: "calc(100% - 48px - 26px - 2px - 10px - 28px)",
            }}
          >

            <ul className="List">
              {list.map((item) => (
                <Fragment key={"item_" + item.uuid}>
                  <li
                    className={
                      isUnroll(item.uuid)
                        ? "List__item active"
                        : "List__item"
                    }
                    onClick={() => changeUnroll(item.uuid)}
                    style={{paddingLeft: 8}}
                  >
                    <div className="Item__title">
                      <div className="Element__title">
                        {!!item.wb_product && !!item.wb_product.status_update && item.wb_product.status_update === 200 && (
                          <div style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            justifyContent: "flex-start",
                            // background: "white",
                            // padding: 8,
                            borderRadius: 9,
                            position: "relative",
                          }}>
                            <div style={{
                              display: "flex",
                              flexDirection: "column",
                              flexWrap: "nowrap",
                              justifyContent: "center",
                              alignItems: "center",
                              width: 56,
                              height: 56,
                              marginRight: 12,
                            }}>
                              {!!item.wb_product.images &&
                                !!item.wb_product.images[0].data && (
                                  <div
                                    key={item.uuid + ":" + item.wb_product.images[0].uuid}
                                    style={{
                                      backgroundImage: `url("${ROOT_URL + "/api/integration/wb/product/image/" + item.wb_product.images[0].uuid}")`,
                                      borderRadius: 7,
                                      backgroundPosition: "center",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                      width: 56,
                                      height: 56
                                    }}
                                  >
                                  </div>
                                ) || "-"}
                            </div>
                            <div style={{
                              display: "flex",
                              flexDirection: "column",
                              flexWrap: "nowrap",
                              justifyContent: "center",
                              alignItems: "flex-start",
                            }}>
                                  <span>
                                  {!!item.wb_product && !!item.wb_product.wb_article_number && (
                                      <a
                                        target={"_blank"}
                                        href={`https://www.wildberries.ru/catalog/${item.wb_product.wb_article_number}/detail.aspx`}
                                        style={{
                                          cursor: "pointer",
                                          color: "#2196f3",
                                          textDecoration: "underline",
                                          fontSize: 14
                                        }}
                                      >
                                        <TextSearchMarker
                                          text={item.wb_product.wb_article_number}
                                          search={searchQuery}
                                        />
                                      </a>
                                    ) ||
                                    "-"}

                                    {(!!item.taobao_product && !!item.taobao_product.taobao_article_number && (
                                      <>&nbsp;|&nbsp;</>
                                    ))}

                                    {(!!item.taobao_product && !!item.taobao_product.taobao_article_number &&
                                      <a
                                        href={`https://detail.1688.com/offer/${item.taobao_product.taobao_article_number}.html`}
                                        style={{
                                          cursor: "pointer",
                                          color: "#2196f3",
                                          fontSize: 14,
                                          textDecoration: "underline",
                                        }}
                                        target={"_blank"}
                                      >
                                        {item.taobao_product.taobao_article_number}
                                      </a>
                                    )}
                                  </span>

                              {!!item.wb_product.name && (
                                <span style={{
                                  marginTop: 4,
                                  fontSize: 14,
                                  fontWeight: 700,
                                  textAlign: "left",
                                }}>
                                  <TextSearchMarker
                                    text={item.wb_product.name}
                                    search={searchQuery}
                                  /></span>
                              )}

                              <span style={{
                                fontSize: 14,
                                fontWeight: 400,
                                textAlign: "left",
                              }}>
                                       {(!!item.wb_product && (
                                         <span style={{fontSize: 12}}>Цена продажи WB (руб):&nbsp;
                                           {(getWbPrice(item) && (
                                             <span style={{fontSize: 12, marginRight: 12}}><span
                                               style={{fontWeight: 700, fontSize: 14}}>
                                              {new Intl.NumberFormat("ru-RU").format(
                                                (getWbPrice(item) / 100).toFixed(2)
                                              )}</span></span>
                                           )) || "-"}
                                          </span>
                                       ))}

                                {(!!item.wb_product && (
                                  <span style={{fontSize: 12}}>Цена закупа (руб):&nbsp;
                                    {!!item.amount ? (
                                      <span style={{fontSize: 12, marginRight: 12}}><span
                                        style={{fontWeight: 700, fontSize: 14}}>
                                          {new Intl.NumberFormat("ru-RU").format((getAmount(item) / 100).toFixed(2))}</span></span>
                                    ) : ((!!exchangeRate && !!exchangeRate.cny && !!item.taobao_product && !item.taobao_article_number && !!item.taobao_product.price && (
                                      <span style={{fontSize: 12, marginRight: 12}}><span
                                        style={{fontWeight: 700, fontSize: 14}}>
                                              {new Intl.NumberFormat("ru-RU").format(
                                                ((item.taobao_product.price * exchangeRate.cny) / 10000).toFixed(2)
                                              )}</span></span>
                                    )) || (!!exchangeRate && !!exchangeRate.cny && !item.taobao_product && !!item.taobao_article_number && !!item.taobao_price && (
                                      <span style={{fontSize: 12, marginRight: 12}}><span
                                        style={{fontWeight: 700, fontSize: 14}}>
                                              {new Intl.NumberFormat("ru-RU").format(
                                                ((item.taobao_price * exchangeRate.cny) / 10000).toFixed(2)
                                              )}</span></span>
                                    ))) || "-"}
                                          </span>
                                ))}

                                {(!!item.wb_product && (
                                  <span style={{fontSize: 12, marginRight: 12}}>Габарит ДхВхШ (см):&nbsp;
                                    {(getPackageLength(item) && (
                                      <span style={{fontSize: 12}}><span style={{fontWeight: 700, fontSize: 14}}>
                                              {new Intl.NumberFormat("ru-RU").format(
                                                (getPackageLength(item) / 10).toFixed(2)
                                              )}</span></span>
                                    )) || "-"}
                                    &nbsp;х&nbsp;
                                    {(getPackageHeight(item) && (
                                      <span style={{fontSize: 12}}><span style={{fontWeight: 700, fontSize: 14}}>
                                              {new Intl.NumberFormat("ru-RU").format(
                                                (getPackageHeight(item) / 10).toFixed(2)
                                              )}</span></span>
                                    )) || "-"}
                                    &nbsp;х&nbsp;
                                    {(getPackageWidth(item) && (
                                      <span style={{fontSize: 12}}><span style={{fontWeight: 700, fontSize: 14}}>
                                              {new Intl.NumberFormat("ru-RU").format(
                                                (getPackageWidth(item) / 10).toFixed(2)
                                              )}</span></span>
                                    )) || "-"}
                                          </span>
                                ))}

                                {(!!item.wb_product && (
                                  <span style={{fontSize: 12, marginRight: 12}}>Вес (кг):&nbsp;
                                    {(getPackageWeight(item) && (
                                      <span style={{fontSize: 12}}><span style={{fontWeight: 700, fontSize: 14}}>
                                              {new Intl.NumberFormat("ru-RU").format(
                                                (getPackageWeight(item) / 1000).toFixed(3)
                                              )}</span></span>
                                    )) || "-"}
                                          </span>
                                ))}

                                    </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="Item__settings">
                      <div className="Element__datetime">
                        {!!analysisData && (
                          <div style={{
                            padding: "4px 8px",
                            backgroundColor: (
                              housekeeperCalculationNew(
                                item,
                                object,
                                exchangeRate,
                                isFbo, fulfillmentPrice, taxPercent, deliveryPreUsdPrice, marketingPercent, additionalExpenses,
                                wbDelivery, wbReturnCost, "BOX",
                                analysisData, selectStorage).profitPercent > 0 ? "#c5e1a590" : "#e1a5a590"),
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 21,
                              fontWeight: 700,
                            }}>
                              {(housekeeperCalculationNew(
                                item,
                                object,
                                exchangeRate,
                                isFbo, fulfillmentPrice, taxPercent, deliveryPreUsdPrice, marketingPercent, additionalExpenses,
                                wbDelivery, wbReturnCost, "BOX",
                                analysisData, selectStorage).profitPercent && (<>{new Intl.NumberFormat("ru-RU").format(
                                (housekeeperCalculationNew(
                                  item,
                                  object,
                                  exchangeRate,
                                  isFbo, fulfillmentPrice, taxPercent, deliveryPreUsdPrice, marketingPercent, additionalExpenses,
                                  wbDelivery, wbReturnCost, "BOX",
                                  analysisData, selectStorage).profitPercent / 100).toFixed(2))}<span
                                style={{
                                  fontSize: 9,
                                  opacity: .75,
                                  marginLeft: 4
                                }}>%</span></>)) || "-"}
                            </div>
                            <div style={{
                              fontSize: 12,
                              fontWeight: 600,
                            }}>
                              Прибыль
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </li>

                  {isUnroll(item.uuid) && (
                    <li className="List__content">
                      <div className="Content__header">
                        <div className="Header__title">
                          <ul className={styleTumbler._}>
                            <li className={styleTumbler._link}>
                              <a
                                className={
                                  styleTumbler._text +
                                  ((!!tumbler &&
                                      tumbler === "a" &&
                                      " " + styleTumbler.active + " ") ||
                                    "")
                                }
                                onClick={() => setTumbler("a")}
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                Юнит-экономика на товар
                              </a>
                            </li>
                            <li className={styleTumbler._link}>
                              <a
                                className={
                                  styleTumbler._text +
                                  ((!!tumbler &&
                                      tumbler === "b" &&
                                      " " + styleTumbler.active + " ") ||
                                    "")
                                }
                                onClick={() => setTumbler("b")}
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                Юнит-экономика на партию
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="Header__settings">
                          {/*<ButtonIcon*/}
                          {/*  tooltipText={"Редактировать"}*/}
                          {/*  iconName={"custom-edit"}*/}
                          {/*  iconColor={"#202020"}*/}
                          {/*  size={36}*/}
                          {/*  style={{ marginLeft: 12 }}*/}
                          {/*  onClick={() => setModalEditAccount(item.account)}*/}
                          {/*/>*/}

                          <button
                            className="Action__button Action__button_blue"
                            style={{
                              fontSize: 15,
                              fontWeight: 600,
                              marginLeft: 12,
                              padding: "6px 8px",
                              borderRadius: 7,
                              display: "flex"
                            }}
                          onClick={() => setIsModalFulfillment(true)}>
                            {/*https://t.me/Stay_House_Msk*/}
                            Заказать фулфилмент
                            {/*<div style={{*/}
                            {/*  marginLeft: 8,*/}
                            {/*  height: 20*/}
                            {/*}}>*/}
                            {/*  /!*<Icon size={20} name={''} color={"#0064ff"}/>*!/*/}
                            {/*  <img src={TelegramIcon} height={20} width={20} alt=""/>*/}
                            {/*</div>*/}
                          </button>

                          <NavLink to={'https://t.me/stepp0'} target={'_blank'}>
                            <button
                              className="Action__button Action__button_blue"
                              style={{
                                fontSize: 15,
                                fontWeight: 600,
                                marginLeft: 12,
                                padding: "6px 8px",
                                borderRadius: 7,
                                display: "flex"
                              }}>
                              Заказать товар
                              <div style={{
                                marginLeft: 8,
                                height: 20
                              }}>
                                {/*<Icon size={20} name={''} color={"#0064ff"}/>*/}
                                <img src={TelegramIcon} height={20} width={20} alt=""/>
                              </div>
                            </button>
                          </NavLink>
                        </div>
                      </div>

                      {!!tumbler && tumbler === "a" && (
                        <MyCalculationTableNew {...props} item={item}/>
                      )}

                      {!!tumbler && tumbler === "b" && (
                        <MyCalculationTwoTableNew {...props} item={item}/>
                      )}
                    </li>
                  )}
                </Fragment>
              ))}
            </ul>

          </div>
        )}

        {(allCount === 0) && (
          <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 28,
            fontWeight: 400
          }}>
            <span style={{
              opacity: .33,
              marginBottom: 16
            }}>
              Расчёт по нише, пока пуст
            </span>
          </div>
        )}

        {(allCount > 0) &&
          (!!list && list.length === 0) && (
            <div style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              flexWrap: "nowrap",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 28,
              fontWeight: 400,
              opacity: .33,
              marginBottom: 16
            }}>
              По результатам поиска ничего не найдено
            </div>
          )}

        {!!isModalFilterEdit && (
          <ModalMyCalculationEdit
            {...props}
            handleClose={() => setIsModalFilterEdit(null)}
          />
        )}

        {!!isModalFulfillment && (
          <ModalFulfillment
            handleClose={() => setIsModalFulfillment(false)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default MyCalculationPage;
