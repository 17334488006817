import React, {Fragment} from "react";
import {ModalAddTariff} from "./ModalAddTariff";
import {ModalEditTariff} from "./ModalEditTariff";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {ButtonIcon, Loading, Pagination, Search, Select,} from "@vokymlak/kabinet-ui";
import styleContent from "../../../styles/modules/Block.module.scss";
import styleCard from "../../../styles/modules/Card.module.scss";

export const TariffsSettingPage = (props) => {
  const {
    profile,

    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,

    modalEditTariff,
    setModalEditTariff,
    isModalAddTariff,
    setIsModalAddTariff,
  } = props;

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Настройка тарифов
            {!!list && (
              <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({count})
              </span>
            )}
            <span style={{width: 220, marginLeft: 16}}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{marginLeft: 8}}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 8}}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 100,
                      title: 100,
                    },
                    {
                      value: 1000,
                      title: 1000,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{marginLeft: 8}}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{marginLeft: 12}}
              onClick={() => setIsModalAddTariff(true)}
            />
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {list.map((item) => (
                <Fragment key={"item_" + item.uuid}>
                  <li
                    className={
                      isUnroll(item.uuid) ? "List__item active" : "List__item"
                    }
                    onClick={() => changeUnroll(item.uuid)}
                    style={{
                      padding: "10px 12px",
                      position: "relative",
                    }}
                  >
                    <div className="Item__title">
                      <div>
                        <div className="Element__title">
                          {!item.name && !!item.tariff_type && item.tariff_type.name}
                          {!!item.name && item.name}
                        </div>
                        <div style={{
                          fontSize: 14,
                          fontWeight: 400
                        }}>
                          {!!item.info && item.info}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          position: "absolute",
                          top: -8,
                          left: 0,
                          justifyContent: " flex-end",
                          width: "100%",
                          padding: "0 8px",
                        }}
                      >

                        {!!item.is_for_trial && (
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: 700,
                              backgroundColor: "rgba(181,35,197)",
                              display: "inline-block",
                              color: "white",
                              padding: "2px 4px 2px",
                              borderRadius: 3,
                              margin: "0 2px",
                            }}
                          >
                            Пробный
                          </div>
                        )}

                        {!!item.tariff_type && (
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: 700,
                              backgroundColor: "#202020",
                              display: "inline-block",
                              color: "white",
                              padding: "2px 4px 2px",
                              borderRadius: 3,
                              margin: "0 2px",
                            }}
                          >
                            {item.tariff_type.name}
                          </div>
                        )}

                        {!!item.is_available && (
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: 700,
                              backgroundColor: "#0064ff",
                              display: "inline-block",
                              color: "white",
                              padding: "2px 4px 2px",
                              borderRadius: 3,
                              margin: "0 2px",
                            }}
                          >
                            Доступен
                          </div>
                        )}

                        {!!item.is_for_sale && (
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: 700,
                              backgroundColor: "#2e7d32",
                              display: "inline-block",
                              color: "white",
                              padding: "2px 4px 2px",
                              borderRadius: 3,
                              margin: "0 2px",
                            }}
                          >
                            Выставлен на продажу
                          </div>
                        )}

                      </div>
                    </div>
                    <div className="Item__settings">
                      <div className="Element__datetime">
                        <div style={{
                          padding: "4px 8px",
                          backgroundColor: "#20202010",
                          borderRadius: 4,
                          marginLeft: 12,
                          textAlign: "right"
                        }}>
                          <div style={{
                            fontSize: 21,
                            fontWeight: 700,
                          }}>
                            {(!!item.day_request_count && item.day_request_count) ||
                              "-"}
                          </div>
                          <div style={{
                            fontSize: 10,
                            fontWeight: 400,
                            opacity: .75,
                          }}>Запросов
                          </div>
                        </div>

                        <div style={{
                          height: 32,
                          width: 2,
                          backgroundColor: "#20202010",
                          borderRadius: 4,
                          marginLeft: 12,
                          textAlign: "right"
                        }}>
                        </div>

                        {!item.is_for_trial && (
                          <div style={{
                            padding: "4px 8px",
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 21,
                              fontWeight: 700,
                            }}>
                              {(!!item.count_months && item.count_months) ||
                                "-"}
                            </div>
                            <div style={{
                              fontSize: 10,
                              fontWeight: 400,
                              opacity: .75,
                            }}>Месяцев
                            </div>
                          </div>
                        )}

                        {!!item.is_for_trial && (
                          <div style={{
                            padding: "4px 8px",
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 21,
                              fontWeight: 700,
                            }}>
                              {(!!item.count_trial_days && item.count_trial_days) ||
                                "-"}
                            </div>
                            <div style={{
                              fontSize: 10,
                              fontWeight: 400,
                              opacity: .75,
                            }}>Дней
                            </div>
                          </div>
                        )}

                        {!item.is_for_trial && (
                          <div style={{
                            padding: "4px 8px",
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 21,
                              fontWeight: 700,
                            }}>
                              {(!!item.tariff_cost && item.tariff_cost / 100) ||
                                "-"}
                            </div>
                            <div style={{
                              fontSize: 10,
                              fontWeight: 400,
                              opacity: .75,
                            }}>Цена
                            </div>
                          </div>
                        )}

                      </div>
                    </div>
                  </li>
                  {isUnroll(item.uuid) && (
                    <li className="List__content">
                      {profile.uuid !== item.uuid && (
                        <div className="Content__header">
                          <div className="Header__title">
                          </div>
                          <div className="Header__settings">
                            <ButtonIcon
                              tooltipText={"Редактировать"}
                              iconName={"custom-edit"}
                              iconColor={"#202020"}
                              size={36}
                              style={{marginLeft: 12}}
                              onClick={() => setModalEditTariff(item)}
                            />
                          </div>
                        </div>
                      )}

                      <div
                        className={styleContent._block + " " + styleContent._block_bottom}
                      >
                        <div className={styleContent._block__title}>
                          Использовано
                        </div>
                        <div className={styleContent._block__content}>
                          <div className={styleCard._block}>
                            <div
                              className={styleCard._block__number}
                            >
                              {(!!item.сount && item.сount) || 0}
                            </div>
                            <div
                              className={styleCard._block__title}
                            >
                              Всего
                            </div>
                          </div>

                          <div style={{
                            height: 88,
                            width: 2,
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginRight: 8,
                            marginBottom: 8,
                            textAlign: "right"
                          }}>
                          </div>

                          <div className={styleCard._block}>
                            <div
                              className={styleCard._block__number}
                            >
                              {(!!item.сount && item.сount) || 0}
                            </div>
                            <div
                              className={styleCard._block__title}
                            >
                              Действующих
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  )}
                </Fragment>
              ))}
            </ul>
          </div>
        )}

        {!!modalEditTariff && (
          <ModalEditTariff
            object={modalEditTariff}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalEditTariff(null)}
          />
        )}
        {!!isModalAddTariff && (
          <ModalAddTariff
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalAddTariff(false)}
          />
        )}
      </div>
    </ScrollBlock>
  );
};
