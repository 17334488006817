import React, {useState} from "react";
import ModalWbAvailabilityImport from "./ModalWbAvailabilityImport";
import {
  ButtonIcon, Pagination, Search, Select,
} from "@vokymlak/kabinet-ui";
import { WbAvailabilityTable } from "./WbAvailabilityTable";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import ModalWbAvailabilityEdit from "./ModalWbAvailabilityEdit";
import ModalWbAvailabilityScheduler from "./ModalWbAvailabilityScheduler";

function WbAvailabilityPage(props) {
  const {
    list,
    setList,
    count,

    setIsUpdateList,
    isUpdateList,

    setModalImport,
    modalImport,

    setIsModalKeyEdit,
    isModalKeyEdit,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    inputSearchText,
    onChangeSearchText,
    searchQuery,
  } = props;

  const [isModalScheduler, setIsModalScheduler] = useState(false);

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="Page">
        <div
          className="Page__title"
          style={{
            marginTop: 0,
            marginBottom: 6,
          }}
        >
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
                marginRight: 6,
              }}
            >
              <div>
                WB оборачиваемость
                {!!list && (
                  <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                    ({count})
                  </span>
                )}
              </div>
            </div>
            <span style={{ width: 220, marginLeft: 16 }}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
          </div>

          <div className="Title__settings">
            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 12 }}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 35,
                      title: 35,
                    },
                    {
                      value: 1000,
                      title: 1000,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{ marginLeft: 8 }}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}

            <ButtonIcon
              tooltipText={"Редактировать"}
              iconName={"custom-edit"}
              iconColor={"#202020"}
              size={36}
              style={{ marginLeft: 12 }}
              onClick={() => setIsModalKeyEdit(true)}
            />

            <ButtonIcon
              tooltipText={"Планировщик"}
              iconName={"custom-priority"}
              iconColor={"#202020"}
              size={24}
              style={{ marginLeft: 12 }}
              onClick={() => setIsModalScheduler(true)}
            />

            <ButtonIcon
              tooltipText={"Импорт"}
              iconName={"custom-cloud-upload"}
              iconColor={"#202020"}
              size={26}
              style={{ marginLeft: 12 }}
              onClick={() => setModalImport(true)}
            />
          </div>
        </div>

        {!!list && (
          <div
            className="Page__content"
            style={{
              position: "relative",
              height: "calc(100% - 48px - 12px)",
            }}
          >
            <div
              style={{
                overflow: "auto",
                width: "100%",
                height: "100%",
              }}
            >
              <WbAvailabilityTable {...props} />
            </div>
          </div>
        )}

        {modalImport && (
          <ModalWbAvailabilityImport
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalImport(false)}
          />
        )}

        {isModalKeyEdit && (
          <ModalWbAvailabilityEdit
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalKeyEdit(false)}
          />
        )}

        {isModalScheduler && (
          <ModalWbAvailabilityScheduler
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalScheduler(false)}
          />
        )}
      </div>
</ScrollBlock>
  );
}

export default WbAvailabilityPage;
