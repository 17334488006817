import React, {useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {getErrorMessageByKey, Modal, useNotification,} from "@vokymlak/kabinet-ui";
import {useViewer} from "../../../components/Viewer";
import {toast} from "sonner";

function ModalTbProductArticleView(props) {
  const [isDelete, setIsDelete] = useState(false);

  const {notification} = useNotification();

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("/api/my/product/" + props.object.uuid + "/delete/taobao-article-price", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        toast.success("Товар удалён");
        props.handleClose();
      })
      .catch((error) => {
        toast.error(getErrorMessageByKey(error.response.data.error));
      });
  };

  const {viewer} = useViewer();

  const changeViewer = (image, mimeType) => {
    viewer(image, mimeType);
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      {!isDelete && (
        <form className="Form">
          <div className="Form__title">
            Подобранный товар
          </div>

          {!!props.object.taobao_article_number && (
            <>
              <div className="Form__field">
                <div className="Field__label">
                  Артикул
                </div>
                <div className="Field__text">
                  {(!!props.object.taobao_article_number &&
                    <a
                      target={"_blank"}
                      href={`https://detail.1688.com/offer/${props.object.taobao_article_number}.html`}
                      style={{
                        cursor: "pointer",
                        color: "#2196f3",
                        textDecoration: "underline",
                      }}
                    >
                      {props.object.taobao_article_number}
                    </a>) || "-"}
                </div>
              </div>

              <div className="Form__field">
                <div className="Field__label">
                  Цена (юань)
                </div>
                <div className="Field__text" style={{
                  fontSize: 21,
                  fontWeight: 600
                }}>
                  {(!!props.object.taobao_price && (<>{new Intl.NumberFormat("ru-RU").format(
                    (props.object.taobao_price / 100).toFixed(2))}&nbsp;<span style={{
                    fontSize: 17
                  }}>¥</span></>)) || "-"}
                </div>
              </div>

              <div className="Form__field Form__field_last">
                <div className="Field__label">
                  Цена (руб)
                </div>
                <div className="Field__text" style={{
                  fontSize: 21,
                  fontWeight: 600
                }}>
                  {(!!props.exchangeRate && !!props.exchangeRate.cny && !!props.object.taobao_price && (<>{new Intl.NumberFormat("ru-RU").format(
                    ((props.object.taobao_price * props.exchangeRate.cny) / 10000).toFixed(2))}&nbsp;<span
                    style={{
                      fontSize: 17
                    }}>₽</span></>)) || "-"}
                </div>
              </div>
            </>
          )}

          <div className="Form__actions">
            <div
              className="Action__link Action__link_red"
              onClick={() => setIsDelete(true)}
            >
              Удалить
            </div>

            <div
              className="Action__link Action__link_gray"
              onClick={() => props.handleClose()}
            >
              Закрыть
            </div>
          </div>
        </form>
      )}

      {isDelete && (
        <form className="Form" onSubmit={(event) => changeDelete(event)}>
          <div className="Form__title">
            Подобранный товар
            {(!!props.object.wb_product && !!props.object.taobao_article_number && (
              <div className="Text__signature">{props.object.taobao_article_number}</div>
            ))}
            {/*<div className="Text__signature">Удалить</div>*/}
          </div>

          <div className="Form__actions"
               style={{
                 justifyContent: "flex-end"
               }}>
            <div className="Action__link"
                 onClick={() => setIsDelete(false)}
                 style={{
                   marginRight: 24
                 }}>
              Отмена
            </div>
            <button className="Action__button Action__button_maxi Action__button_red">
              Удалить
            </button>
          </div>

          {/*<div className="Form__actions Form__actions_center">*/}
          {/*  <button className="Action__button Action__button_all Action__button_red">*/}
          {/*    Удалить*/}
          {/*  </button>*/}
          {/*</div>*/}

          {/*<div className="Form__actions Form__actions_center">*/}
          {/*  <div className="Action__link" onClick={() => setIsDelete(false)}>*/}
          {/*    Отмена*/}
          {/*  </div>*/}
          {/*</div>*/}
        </form>
      )}
    </Modal>
  );
}

export default ModalTbProductArticleView;
