import React from "react";

export const InfoPalletesTableHead = () => {
  return (
    <tr className="Table__tr Table__tr_padding Table__tr_border">
      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title">Обновлено</div>
      </th>

      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title">Склад</div>
      </th>
      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title">Логистика Коэффициент</div>
      </th>

      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title">за 3л</div>
      </th>
      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title">доп. л</div>
      </th>
      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title">Хранение Коэффициент</div>
      </th>
      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title">1 паллета</div>
      </th>
    </tr>

  );
};
