import React, { useState } from "react";
import axios from "axios";
import {
  getErrorMessageByKey,
  useNotification,
  Modal, Loading,
} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";

function ModalMpstatsSellersPathImport(props) {
  const [isRequest, setIsRequest] = useState(false);

  const { notification } = useNotification();

  const changeImport = () => {
    setIsRequest(true)

    axios
      .get(
        "/api/mpstats-subjects/import/sellers/"+ props.object.wb_subject.wb_number,
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then(() => {
        setIsRequest(false)
        props.successfulRequest();
        notification("Импорт завершён", "green");
        props.handleClose();
      })
      .catch((error) => {
        setIsRequest(false)
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <div className="Form">
        <div className="Form__title">
          {props.object.wb_subject.name + (props.object.wb_subject.wb_number ? " #" + props.object.wb_subject.wb_number : "")}
          <div className="Text__signature">MPSTATS топ продавцов</div>
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue"
                  onClick={!isRequest ? () => changeImport() : () => null}>
            {!isRequest
              ? "Импортировать"
              : <Loading size="medium" color="blue" />}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalMpstatsSellersPathImport;
