import React from "react";
import { WbCommissionTableHead } from "./WbCommissionTableHead";
import { WbCommissionList } from "./WbCommissionList";

export const WbCommissionTable = (props) => {
  const { list } = props;

  return (
      <table className="Table">
        <thead className="Thead">
          <WbCommissionTableHead {...props} />
        </thead>
        <tbody>{!!list && <WbCommissionList {...props} />}</tbody>
      </table>
  );
};
