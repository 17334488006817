import React, {Fragment, useState} from "react";
import {getErrorMessageByKey, Icon, Modal, useList} from "@vokymlak/kabinet-ui";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {TARIFF_OPTIONS} from "./constants";
import {ModalAddMyPromoCode} from "./ModalAddMyPromoCode";
import {toast} from "sonner";

function NewTariffPay(props) {
  const [promoCode, setPromoCode] = useState(null);

  const {
    list: [list],
    update: [isUpdateList, setIsUpdateList],
  } = useList({
    apiUrl: "/api/my/tariffs/sale",
    rootUrl: ROOT_URL,
  });

  const [isRequest, setIsRequest] = useState(null);

  const changeAdd = (item) => {

    setIsRequest(item);

    const jsonData = JSON.stringify({
      tariff: item,
      promo_code: (!!promoCode && promoCode.promo_code) || null
    });

    axios
      .post("/api/my/tariff/pay", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then((response) => {
        if (response.data.payment_type === 'ONLINE') {
          window.open(`https://yoomoney.ru/checkout/payments/v2/contract?orderId=${response.data.payment_token}`, '_self');
        }
        props.successfulRequest();
      })
      .catch((error) => {
        if (error.response.status === 402) {
          toast.error("Промо тариф уже применён");
        } else {
          toast.error(getErrorMessageByKey(error.response.data.error));
        }
      })
      .finally(() => {
        setIsRequest(null);
      });
  };

  const [modalObjectAdd, setIsModalObjectAdd] = useState(false);

  const getPriceSale = (tariffCost, percentDiscount) => {
    return Math.abs((tariffCost - (tariffCost / 100 * (percentDiscount / 100))) / 100) * 100
  }

  return (
    !!list &&
    <>
        <div className="Form">
          {/*<div className="Form__title">*/}
          {/*  Тарифы*/}
          {/*  /!*<div className="Text__signature">Выберите и оплатите тариф из представленных ниже</div>*!/*/}
          {/*</div>*/}

          <div className="Form__field" style={{
            // marginTop: 16,
            // marginBottom: 12,
            // width: "100%",
          }}>
            {!!promoCode && (
              <div>
                <ul className="List">
                  <li
                    className={"List__item"}
                    style={{
                      padding: "10px 12px",
                      position: "relative",
                      cursor: "default",
                      backgroundColor: "white",
                      // border: "2px dashed #dbdbdb",
                      borderRadius: 11
                    }}
                  >
                    <div className="Item__title">
                      <div className="Element__title" style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        marginRight: 24
                      }}>
                        <div style={{}}>
                          {(!!promoCode.title && promoCode.title)}
                        </div>
                        <div style={{
                          fontSize: 17,
                          fontWeight: 400
                        }}>
                          {(!!promoCode.description && promoCode.description)}
                        </div>
                      </div>

                      <button className="Action__button Action__button_mini Action__button_gray"
                              onClick={() => setPromoCode(null)}>
                        Отменить промо-код
                      </button>
                    </div>
                    <div className="Item__settings">
                      <div className="Element__datetime">
                        <div style={{
                          padding: "4px 8px",
                          backgroundColor: "#20202010",
                          borderRadius: 7,
                          marginLeft: 12,
                          textAlign: "right"
                        }}>
                          <div style={{
                            fontSize: 21,
                            fontWeight: 700,
                          }}>
                            {(!!promoCode.promo_code && promoCode.promo_code.toUpperCase()) ||
                              "-"}
                          </div>
                          <div style={{
                            fontSize: 10,
                            fontWeight: 400,
                            opacity: .75,
                          }}>
                            Промо-код
                          </div>
                        </div>

                        <div style={{
                          padding: "4px 8px",
                          backgroundColor: "#20202010",
                          borderRadius: 7,
                          marginLeft: 12,
                          textAlign: "right"
                        }}>
                          <div style={{
                            fontSize: 21,
                            fontWeight: 700,
                          }}>
                            {(!!promoCode.percent_discount && (<>{new Intl.NumberFormat("ru-RU").format(
                                (promoCode.percent_discount / 100).toFixed(2))}&nbsp;<span style={{
                                fontSize: 11,
                                fontWeight: 400
                              }}>%</span></>)) ||
                              "-"}
                          </div>
                          <div style={{
                            fontSize: 10,
                            fontWeight: 400,
                            opacity: .75,
                          }}>
                            Скидка
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            )}
            {!promoCode && (
              <button className="Action__button Action__button_blue"
                      style={{
                        borderRadius: 7
                      }}
                      onClick={() => setIsModalObjectAdd(true)}>
                Применить промо-код
              </button>
            )}
          </div>

          <div className="Form__field" style={{
            // backgroundColor: "#eee",
            // borderRadius: 19,
            // display: "inline-block",
            // padding: 12
            padding: "0 0 24px",
            marginTop: 24
          }}>
            <div style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "stretch",
              justifyContent: "flex-start",
            }}>
              {!!list && list.filter(i => (props.isTrial ? props.isTrial : !i.is_for_trial)).map((item, index) => (
                <div
                  key={item.uuid}
                  style={{
                    backgroundColor: !item.is_for_trial && "white",
                    borderRadius: 21,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    padding: "16px 16px",
                    marginRight: index < (list.length - 1) && 12,
                    marginLeft: index !== 0 && 12,
                    border: item.is_for_trial && "2px solid #dbdbdb",
                    boxShadow: props.isTrial ? !item.is_for_trial && "0 16px 24px rgba(0, 0, 0, 0.11)" : "none",
                    width: 224
                  }}>
                  <div style={{
                    display: "flex",
                    alignItems: "stretch",
                    flexDirection: "column",
                    marginBottom: 16
                  }}>
                    <div style={{
                      fontSize: 32,
                      fontWeight: 700,
                      textAlign: "center",
                      marginBottom: 24,
                    }}>
                      <div style={{
                        textTransform: "uppercase",
                        marginBottom: 8
                      }}>
                        {!item.name && !!item.tariff_type && item.tariff_type.name}
                        {!!item.name && item.name}
                      </div>

                      {!item.info && !!TARIFF_OPTIONS.find(obj => obj.tag === item.tariff_type.tag) && (
                        <div
                          style={{
                            fontSize: 14,
                            fontWeight: 400
                          }}>
                          {TARIFF_OPTIONS.find(obj => obj.tag === item.tariff_type.tag).subtitle}
                        </div>
                      )}
                      {!!item.info && (
                        <div
                          style={{
                            fontSize: 14,
                            fontWeight: 400
                          }}>
                          {item.info}
                        </div>
                      )}
                    </div>
                    {!!TARIFF_OPTIONS.find(obj => obj.tag === item.tariff_type.tag) && (
                      <>
                        {TARIFF_OPTIONS.find(obj => obj.tag === item.tariff_type.tag).options.map((option, index) => (
                          <div
                            key={"option_" + item.uuid + "_" + index}
                            style={{
                              display: "flex",
                              fontSize: 14,
                              fontWeight: 400,
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: 8
                            }}>
                            <div style={{
                              marginRight: 16
                            }}>
                              {option.title}
                            </div>
                            <div style={{
                              height: 20
                            }}>
                              {!!option.option && (
                                <Icon name={'custom-circle-good'} size={20} color={"#35A456"}/>
                              )}
                              {!option.option && (
                                <Icon name={'custom-circle-bad'} size={20} color={"#F24612"}/>
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    )}


                    <div style={{
                      display: "flex",
                      fontSize: 14,
                      fontWeight: 400,
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}>
                      <div style={{
                        marginRight: 16
                      }}>
                        Лимит на расчёт в день
                      </div>
                      <div style={{
                        fontSize: 17,
                        fontWeight: 700
                      }}>
                        {item.day_request_count}
                      </div>
                    </div>
                  </div>
                  {!!promoCode && !item.is_for_trial && (
                    <>
                      {item.count_months === 12 && (
                        <>
                          {(promoCode.percent_discount === 10_00 || promoCode.percent_discount === 5_00) && (
                            <div style={{
                              fontSize: 19,
                              fontWeight: 700,
                              textAlign: "center",
                              textDecoration: "line-through",
                              opacity: .5
                            }}>
                              <span>
                                {(!!item.tariff_cost && new Intl.NumberFormat("ru").format((Number(item.tariff_cost) / 100).toFixed(2))) || 0}
                              </span>
                            </div>
                          )}
                        </>
                      )}
                      {item.count_months === 6 && (
                        <>
                          {promoCode.percent_discount === 5_00 && (
                            <div style={{
                              fontSize: 19,
                              fontWeight: 700,
                              textAlign: "center",
                              textDecoration: "line-through",
                              opacity: .5
                            }}>
                              <span>
                                {(!!item.tariff_cost && new Intl.NumberFormat("ru").format((Number(item.tariff_cost) / 100).toFixed(2))) || 0}
                              </span>
                            </div>
                          )}
                        </>
                      )}
                      {item.count_months === 1 && (
                        <>
                          {(promoCode.percent_discount === 100_00 || promoCode.percent_discount === 5_00) && (
                            <div style={{
                              fontSize: 19,
                              fontWeight: 700,
                              textAlign: "center",
                              textDecoration: "line-through",
                              opacity: .5
                            }}>
                              <span>
                                {(!!item.tariff_cost && new Intl.NumberFormat("ru").format((Number(item.tariff_cost) / 100).toFixed(2))) || 0}
                              </span>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <div style={{
                    fontSize: 32,
                    fontWeight: 700,
                    textAlign: "center",
                    marginBottom: 16,
                  }}>
                    {(!promoCode || item.is_for_trial) && (
                      <span>
                      {(!!item.tariff_cost && new Intl.NumberFormat("ru").format((Number(item.tariff_cost) / 100).toFixed(2))) || 0}
                    </span>
                    )}
                    {!!promoCode && !item.is_for_trial && (
                      <>
                        {item.count_months === 12 && (
                          <>
                            {(promoCode.percent_discount === 10_00 || promoCode.percent_discount === 5_00) && (
                              <span>
                                {(!!item.tariff_cost && new Intl.NumberFormat("ru").format((Number(getPriceSale(item.tariff_cost, promoCode.percent_discount)) / 100).toFixed(2))) || 0}
                              </span>
                            )}
                            {promoCode.percent_discount !== 10_00 && promoCode.percent_discount !== 5_00 && (
                              <span>
                                {(!!item.tariff_cost && new Intl.NumberFormat("ru").format((Number(item.tariff_cost) / 100).toFixed(2))) || 0}
                              </span>
                            )}
                          </>
                        )}
                        {item.count_months === 6 && (
                          <>
                            {promoCode.percent_discount === 5_00 && (
                              <span>
                                {(!!item.tariff_cost && new Intl.NumberFormat("ru").format((Number(getPriceSale(item.tariff_cost, promoCode.percent_discount)) / 100).toFixed(2))) || 0}
                              </span>
                            )}
                            {promoCode.percent_discount !== 5_00 && (
                              <span>
                                {(!!item.tariff_cost && new Intl.NumberFormat("ru").format((Number(item.tariff_cost) / 100).toFixed(2))) || 0}
                              </span>
                            )}
                          </>
                        )}
                        {item.count_months === 1 && (
                          <>
                            {(promoCode.percent_discount === 100_00 || promoCode.percent_discount === 5_00) && (
                              <span>
                                {(!!item.tariff_cost && new Intl.NumberFormat("ru").format((Number(getPriceSale(item.tariff_cost, promoCode.percent_discount)) / 100).toFixed(2))) || 0}
                              </span>
                            )}
                            {promoCode.percent_discount !== 100_00 && promoCode.percent_discount !== 5_00 && (
                              <span>
                                {(!!item.tariff_cost && new Intl.NumberFormat("ru").format((Number(item.tariff_cost) / 100).toFixed(2))) || 0}
                              </span>
                            )}
                          </>
                        )}
                      </>
                    )}
                    <span style={{
                      fontSize: 16,
                      fontWeight: 400,
                      marginLeft: 6,
                    }}>руб</span>
                  </div>
                  <div>
                    <button className="Action__button Action__button_all Action__button_blue"
                            style={{
                              borderRadius: 11
                            }}
                            onClick={isRequest ? () => null : () => changeAdd(item)}>
                      {item.is_for_trial && 'Испытать'}
                      {!item.is_for_trial && 'Оплатить'}
                    </button>
                  </div>
                </div>
              ))}
            </div>

            {/*<div className="Form__actions Form__actions_center">*/}
            {/*  <div*/}
            {/*    className="Action__link Action__link_gray"*/}
            {/*    onClick={() => props.handleClose()}*/}
            {/*  >*/}
            {/*    Закрыть*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>

      {!!modalObjectAdd && (
        <ModalAddMyPromoCode
          successfulRequest={(item) => setPromoCode(item)}
          handleClose={() => setIsModalObjectAdd(false)}
        />
      )}
    </>
  );
}

export default NewTariffPay;
