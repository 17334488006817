import React, { Fragment } from "react";
import {
  Tooltip,
  TextSearchMarker,
} from "@vokymlak/kabinet-ui";
import moment from "moment";


export const WbReturnCostList = (props) => {
  const {
    list,
  } = props;

  return (
    !!list && (
      <Fragment>
        {list.map(item => (
          <tr
            className={
              "Table__tr Table__tr_padding Table__tr_border Table__tr_hover "
            }
            key={"item_" + item.uuid}
          >
            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
            >
              <Tooltip
                text={"Обновлено"}
                style={{ width: "100%", height: "100%" }}
              >
                <div className="Td__text Td__text_center">
                  {!!item.updated_at
                    ? moment(item.updated_at).format("DD.MM.YYYY")
                    : moment(item.created_at).format("DD.MM.YYYY")}
                </div>
              </Tooltip>
            </td>


            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
            >
              <Tooltip text={"Склад"} style={{ width: "100%", height: "100%" }}>
                <div className="Td__text Td__text_center">
                  {(!!item.wb_warehouse && !!item.wb_warehouse.name && item.wb_warehouse.name) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
            >
              <Tooltip
                text={"Доставка на ПВЗ (базовая цена + доп. литр)"}
                style={{ width: "100%", height: "100%" }}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.sup_office_expr && item.sup_office_expr) ||
                    "-"}
                  (
                  {(!!item.sup_office_base &&
                      new Intl.NumberFormat("ru-RU").format(
                        (item.sup_office_base / 100).toFixed(2)
                      )) ||
                    "-"}
                  |
                  {(!!item.sup_office_liter &&
                      new Intl.NumberFormat("ru-RU").format(
                        (item.sup_office_liter / 100).toFixed(2)
                      )) ||
                    "-"}
                  )
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
            >
              <Tooltip
                text={"Доставка курьером (базовая цена + доп. литр)"}
                style={{ width: "100%", height: "100%" }}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.sup_courier_expr && item.sup_courier_expr) ||
                    "-"}
                  (
                  {(!!item.sup_courier_base &&
                      new Intl.NumberFormat("ru-RU").format(
                        (item.sup_courier_base / 100).toFixed(2)
                      )) ||
                    "-"}
                  |
                  {(!!item.sup_courier_liter &&
                      new Intl.NumberFormat("ru-RU").format(
                        (item.sup_courier_liter / 100).toFixed(2)
                      )) ||
                    "-"}
                  )
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
            >
              <Tooltip
                text={"Обратная логистика невостребованного возврата"}
                style={{ width: "100%", height: "100%" }}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.sup_return_expr &&
                      new Intl.NumberFormat("ru-RU").format(
                        (item.sup_return_expr / 100).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
            >
              <Tooltip
                text={"Доставка на ПВЗ (базовая цена + доп. литр)"}
                style={{ width: "100%", height: "100%" }}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.kgt_office_expr && item.kgt_office_expr) ||
                    "-"}
                  (
                  {(!!item.kgt_office_base &&
                      new Intl.NumberFormat("ru-RU").format(
                        (item.kgt_office_base / 100).toFixed(2)
                      )) ||
                    "-"}
                  |
                  {(!!item.kgt_office_liter &&
                      new Intl.NumberFormat("ru-RU").format(
                        (item.kgt_office_liter / 100).toFixed(2)
                      )) ||
                    "-"}
                  )
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
            >
              <Tooltip
                text={"Обратная логистика невостребованного возврата"}
                style={{ width: "100%", height: "100%" }}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.kgt_return_expr &&
                      new Intl.NumberFormat("ru-RU").format(
                        (item.kgt_return_expr / 100).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
            >
              <Tooltip
                text={"Доставка на ПВЗ"}
                style={{ width: "100%", height: "100%" }}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.srg_office_expr &&
                      new Intl.NumberFormat("ru-RU").format(
                        (item.srg_office_expr / 100).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
            >
              <Tooltip
                text={"Обратная логистика невостребованного возврата"}
                style={{ width: "100%", height: "100%" }}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.srg_return_expr &&
                      new Intl.NumberFormat("ru-RU").format(
                        (item.srg_return_expr / 1000).toFixed(3)
                      )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>
          </tr>
        ))}
      </Fragment>
    )
  );
};
