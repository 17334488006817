import React, {useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {
  getErrorMessageByKey, Icon,
  Input,
  isValidateNullText,
  jsonFormatText,
  Modal,
  useNotification,
} from "@vokymlak/kabinet-ui";
import {toast} from "sonner";

function ModalMyProductInfo(props) {
  return (
    <Modal handleClose={() => props.handleClose()} contentStyle={{
      width: 560+24+24+8
    }}>
      <div className="Form">
        <div className="Form__title">
          Коллекция товаров
          {/*<div className="Text__signature">Товар</div>*/}
        </div>

        <div className="Form__field Form__field_last">
          <div className="Field__text">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/pf8JFX70UXU?si=G2Rr4HUUPWLZro2X"
                    title="YouTube video player" frameBorder="0"
                    style={{
                      borderRadius: 11,
                      marginBottom: 24
                    }}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            <div style={{
              textAlign: "center"
            }}>
              {/*Коллекция товаров:*/}
              {/*<br/>*/}
              В данном разделе отображаються товары, которые вы добавляете для расчетов юнит-экономики.<br/>
              Так же в этом разделе вы можете подобрать поставщиков интересующих вас товаров на сайте 1688.com.
            </div>
          </div>
        </div>

        <div className="Form__actions Form__actions_center">
          <div className="Action__link"
               onClick={() => props.handleClose()}>
            Закрыть
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalMyProductInfo;
