import React, {useRef} from "react";
import {Modal} from "@vokymlak/kabinet-ui";
import {NavLink} from "react-router-dom";
import TelegramIcon from './telegram.svg';

function ModalFulfillment(props) {

  return (
    <Modal handleClose={() => props.handleClose()}>

      <div className="Form">
        <div className="Form__title" style={{
          textAlign: "center"
        }}>
          Фулфилмент от партнёров
        </div>

        <div className="Form__field Form__field_last" style={{
          textAlign: "center"
        }}>
          Сообщите промо-код <span style={{
          padding: "2px 4px",
          borderRadius: 5,
          backgroundColor: "#f1f1f1",
          margin: 4,
          fontWeight: 700
        }}>MPUNIT5</span> и получите скидку 5%!
        </div>

        <div className="Form__actions Form__actions_center">
          <NavLink to={'https://t.me/Stay_House_Msk'} target={'_blank'}>
            <button
              className="Action__button Action__button_all Action__button_blue"
              style={{
                fontSize: 15,
                fontWeight: 600,
                padding: "6px 8px",
                borderRadius: 7,
                display: "flex"
              }}>
              Заказать фулфилмент
              <div style={{
                marginLeft: 8,
                height: 20
              }}>
                {/*<Icon size={20} name={''} color={"#0064ff"}/>*/}
                <img src={TelegramIcon} height={20} width={20} alt=""/>
              </div>
            </button>
          </NavLink>
        </div>

        <div className="Form__actions Form__actions_center">
          <div className="Action__link" onClick={() => props.handleClose()}>
            Закрыть
          </div>
        </div>

      </div>
    </Modal>
  );
}

export default ModalFulfillment;
