import React from "react";
import {Input} from "@vokymlak/kabinet-ui";

export const WbProductsTableHead = (props) => {
  const {
    list,

    selectContracts,
    isSelectAllContract,
    changeSelectAllContract,
    changeSelectAllClearContract,
  } = props

  return (
    <tr className="Table__tr Table__tr_padding Table__tr_border">
      {/*<th*/}
      {/*  className="Table__th Table__th_border"*/}
      {/*  style={{*/}
      {/*    backgroundColor: "#dfdfdf",*/}
      {/*    width: 24*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <div className="Th__title" style={{ height: 40 }}>*/}
      {/*    <Input type={"checkbox"} onChange={isSelectAllContract(list, selectContracts) ? () => changeSelectAllClearContract(list, selectContracts) : () => changeSelectAllContract(list, selectContracts)} isChecked={isSelectAllContract(list, selectContracts)}/>*/}
      {/*  </div>*/}
      {/*</th>*/}
      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title">Ниша</div>
      </th>
      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title">Артикул</div>
      </th>
      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title">Изображение</div>
      </th>
      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title">Название</div>
      </th>
      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title">Цена (руб.)</div>
      </th>

      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title">Вес (кг)</div>
      </th>

      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title">Габарит ДхВхШ (см)</div>
      </th>
      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title">Добавлено</div>
      </th>
      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title">Обновлено</div>
      </th>
      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title">Код обновления</div>
      </th>
      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title">1688</div>
      </th>
      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title"></div>
      </th>
    </tr>
  );
};
