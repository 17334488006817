import React, {Fragment, useEffect, useState} from "react";
import styleContent from "../../../styles/modules/Block.module.scss";
import styleCard from "../../../styles/modules/Card.module.scss";

export const AnalysisSubjectSummary = ({mpSummaryJson}) => {
  const [mpSummary, setMpSummary] = useState(null);

  const getObjectByJson = (json) => {
    return JSON.parse(json);
  }

  useEffect(() => {
    setMpSummary(getObjectByJson(mpSummaryJson))
  }, [mpSummaryJson])


  const getBuyoutWithReturn = (summary) => {
    if (!!summary && !!summary.buyout && !!summary.buyout.with_return) {
      return summary.buyout.with_return * 100
    }

    return 0;
  }

  const getMonopolization = (summary) => {
    if (!!summary && !!summary.monopolization && !!summary.monopolization.monopolization) {
      return summary.monopolization.monopolization
    }

    return 0;
  }

  const getRevenueMissed = (summary) => {
    if (!!summary && !!summary.revenue && !!summary.revenue.missed) {
      return summary.revenue.missed
    }

    return 0;
  }

  const getRevenue = (summary) => {
    if (!!summary && !!summary.revenue) {
      return Number(summary.revenue.value)
    }

    return 0;
  }

  return (
    !!mpSummary && (
      <div className={styleContent._block}>
        <div className={styleContent._block__content}>
          {!!mpSummary.summary && (
            <div className={styleCard._block}
                 style={{
                   // backgroundColor: "#f1f1f1",
                   // backgroundColor: "white",
                   backgroundColor: "inherit",
                   border: "2px solid #20202020",
                 }}>
              <div
                className={styleCard._block__number}
                style={{fontSize: 21}}
              >
                {(getRevenue(mpSummary.summary) && (<>{new Intl.NumberFormat("ru-RU").format(
                  (getRevenue(mpSummary.summary)).toFixed(2))}<span
                  style={{
                    fontSize: 9,
                    opacity: .75,
                    marginLeft: 4
                  }}>руб.</span></>)) || "-"}
              </div>
              <div
                className={styleCard._block__title}
              >
                Выручка
              </div>
            </div>
          )}

          {!!mpSummary.summary && (
            <div className={styleCard._block}
                 style={{
                   // backgroundColor: "#f1f1f1",
                   // backgroundColor: "white",
                   backgroundColor: "inherit",
                   border: "2px solid #20202020",
                 }}>
              <div
                className={styleCard._block__number}
                style={{fontSize: 21}}
              >
                {(getRevenueMissed(mpSummary.summary) && (<>{new Intl.NumberFormat("ru-RU").format(
                  (getRevenueMissed(mpSummary.summary)).toFixed(2))}<span
                  style={{
                    fontSize: 9,
                    opacity: .75,
                    marginLeft: 4
                  }}>руб.</span></>)) || "-"}
              </div>
              <div
                className={styleCard._block__title}
              >
                Упущенная выручка
              </div>
            </div>
          )}

          {!!mpSummary.summary && (
            <div className={styleCard._block}
                 style={{
                   // backgroundColor: "#f1f1f1",
                   // backgroundColor: "white",
                   backgroundColor: "inherit",
                   border: "2px solid #20202020",
                 }}>
              <div
                className={styleCard._block__number}
                style={{fontSize: 21}}
              >
                {(getMonopolization(mpSummary.summary) &&
                  (<>{getMonopolization(mpSummary.summary)}</>)) || "-"}
              </div>
              <div
                className={styleCard._block__title}
              >
                Монополизация
              </div>
            </div>
          )}

          {!!mpSummary.summary && (
            <div className={styleCard._block}
                 style={{
                   // backgroundColor: "#f1f1f1",
                   // backgroundColor: "white",
                   backgroundColor: "inherit",
                   border: "2px solid #20202020",
                 }}>
              <div
                className={styleCard._block__number}
                style={{fontSize: 21}}
              >
                {(getBuyoutWithReturn(mpSummary.summary) && (<>{new Intl.NumberFormat("ru-RU").format(
                  (getBuyoutWithReturn(mpSummary.summary) / 100).toFixed(2))}<span
                  style={{
                    fontSize: 9,
                    opacity: .75,
                    marginLeft: 4
                  }}>%</span></>)) || "-"}
              </div>
              <div
                className={styleCard._block__title}
              >
                Выкуп с учетов возврата
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
}
