import {useEffect, useRef, useState} from "react";
import {useAction, useList, useObject} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";

function useMyProducts(myTariff) {
  const [selectSubject, setSelectSubject] = useState('');

  const {
    object: [exchangeRate],
    update: [isUpdateExchangeRate, setIsUpdateExchangeRate],
  } = useObject({
    apiUrl: "/api/info-exchange-rate",
    rootUrl: ROOT_URL,
  });

  const {
    list: [list, count, setList, allCount],
    update: [isUpdateList, setIsUpdateList, updateList],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
    setApiUrlParams,
  } = useList({
    apiUrl: "/api/my/products",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 25,
    isSearch: true,
    urlParams: {
      "subject": selectSubject
    }
  });

  const {
    list: [listProductCategories, _count, setListProductCategories],
    update: [isUpdateProductCategories, setIsUpdateProductCategories],
  } = useList({
    apiUrl: "/api/my/products/subjects",
    rootUrl: ROOT_URL,
  });

  const changeSelectProductCategory = (subject) => {
    setSelectSubject(subject.length > 0 ? subject : '');
  };

  useEffect(() => {
    const params = {
      "subject": selectSubject
    };
    setApiUrlParams(params);
  }, [selectSubject]);

  const inputSearchText = useRef(null);
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  const onChangeSearchText = () => {
    setSearchQuery(inputSearchText.current.value.trim());
  };

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  const {setAction} = useAction("my-products", () => updateList());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => !!i && !!i.wb_product && !!i.wb_product.uuid && i.wb_product.uuid);
      setAction([...listUuid, "my-products"]);
    } else {
      setAction("my-products");
    }
  }, [list]);

  const [modalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [modalObjectView, setIsModalObjectView] = useState(null);
  const [modalObjectEdit, setModalObjectEdit] = useState(null);

  const [modalTbProductAdd, setModalTbProductAdd] = useState(null);
  const [modalTbProductView, setModalTbProductView] = useState(null);
  const [modalTbProductArticleView, setModalTbProductArticleView] = useState(null);

  const checkAddMyProduct = () => {
    // Обработка ошибки написана в нажатии кнопки добавить.
    // if (!!myTariff && !!myTariff.day_request_count && !!myTariff.day_request &&
    //   myTariff.day_request.request_counts >= myTariff.day_request_count) {
    //
    //   notification("Превышен лимит запросов на сегодня", "red");
    //   return;
    // }

    setIsModalObjectAdd(true);
  }

  return {
    list,
    setList,
    count,
    allCount,

    setIsUpdateList,
    isUpdateList,

    setIsModalObjectAdd,
    modalObjectAdd,
    modalObjectView,
    setIsModalObjectView,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    exchangeRate,
    isUpdateExchangeRate,
    setIsUpdateExchangeRate,

    listProductCategories,
    setListProductCategories,
    isUpdateProductCategories,
    setIsUpdateProductCategories,
    changeSelectProductCategory,

    modalTbProductAdd,
    setModalTbProductAdd,
    modalTbProductView,
    setModalTbProductView,

    modalObjectEdit,
    setModalObjectEdit,

    checkAddMyProduct,

    modalTbProductArticleView,
    setModalTbProductArticleView,
  };
}

export default useMyProducts;
