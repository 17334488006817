import React, {useRef, useState} from "react";
import {
  getErrorMessageByKey,
  Input,
  isValidateNullText,
  jsonFormatText, Loading,
  Modal,
} from "@vokymlak/kabinet-ui";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {toast} from "sonner";

function ModalTaobaoProductAdd(props) {

  const [isRequest, setIsRequest] = useState(false);
  const inputArticle = useRef(null);
  const inputPrice = useRef(null);

  function validateString(str) {
    // Проверка на наличие только цифр
    const isNumeric = /^[0-9]+$/.test(str);

    // Проверка на отсутствие ведущих нулей
    const noLeadingZero = str[0] !== '0';

    // Возвращаем true, если строки удовлетворяют оба условия
    return isNumeric && noLeadingZero;
  }

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const changeAdd = (event) => {
    event.preventDefault();

    const article = jsonFormatText(inputArticle.current.value);
    const price = inputPrice.current.value;

    if (isValidateNullText(article)) {
      toast.error("Заполните все обязательные поля");
      return;
    }

    if (!validateString(article)) {
      toast.error("Не правильный формат артикула");
      return;
    }

    setIsRequest(true)

    const jsonData = JSON.stringify({
      taobao_article_number: article,
      price: numberConvert(price),
    });

    axios
      .post("/api/my/product/" + props.object.uuid + "/add/taobao-article-price", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        toast.success("Товар добавлен");
        props.handleClose();
      })
      .catch((error) => {
        toast.error(getErrorMessageByKey(error.response.data.error));
      })
      .finally(() => {
        setIsRequest(false);
      });
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">
          Подобранный товар
          {/*<div className="Text__signature">Товар</div>*/}
        </div>

        <div className="Form__field">
          <Input reference={inputArticle}
                 type="text" label="Артикул *"/>
        </div>

        <div className="Form__field Form__field_last">
          <Input
            reference={inputPrice}
            type="number"
            label="Цена (юань)"
            maxNumber={999_999_999}
            minNumber={0}
            stepNumber={0.01}
          />
        </div>

        {/*<div className="Form__actions Form__actions_center">*/}
        {/*  <button className="Action__button Action__button_all Action__button_blue"*/}
        {/*          onClick={!isRequest ? () => changeAdd() : () => null}>*/}
        {/*    {!isRequest*/}
        {/*      ? "Добавить"*/}
        {/*      : <Loading size="medium" color="blue"/>}*/}
        {/*  </button>*/}
        {/*</div>*/}

        <div className="Form__actions"
             style={{
               justifyContent: "flex-end"
             }}>
          <div className="Action__link"
               onClick={() => props.handleClose()}
               style={{
                 marginRight: 24
               }}>
            Отмена
          </div>
          <button className="Action__button Action__button_maxi Action__button_blue"
                  disabled={isRequest}>
            Добавить
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalTaobaoProductAdd;
