import React, { Fragment } from "react";
import {
  Tooltip,
  TextSearchMarker,
} from "@vokymlak/kabinet-ui";
import moment from "moment";


export const InfoExchangeRatesList = (props) => {
  const {
    list,
    selectSpecification,
    searchQuery,
  } = props;

  return (
    !!list && (
      <Fragment>
        {list.map(item => (
          <tr
            className={
              "Table__tr Table__tr_padding Table__tr_border Table__tr_hover " +
              (!!selectSpecification && selectSpecification === item.uuid
                ? "Table__tr_focus"
                : "")
            }
            key={"item_" + item.uuid}
          >
            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
              // onClick={() => (
              //   setModalSpecificationEdit(item), setSelectSpecification(item.uuid)
              // )}
            >
              <Tooltip
                text={"Дата"}
                style={{ width: "100%", height: "100%" }}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.date && moment(item.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")) || "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
              // onClick={() => (
              //   setModalSpecificationEdit(item), setSelectSpecification(item.uuid)
              // )}
            >
              <Tooltip text={"Юань"} style={{ width: "100%", height: "100%" }}>
                <div className="Td__text Td__text_center">
                  {(!!item.cny && new Intl.NumberFormat("ru").format((Number(item.cny) / 100).toFixed(2))) || 0}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
              // onClick={() => (
              //   setModalSpecificationEdit(item), setSelectSpecification(item.uuid)
              // )}
            >
              <Tooltip text={"Доллар"} style={{ width: "100%", height: "100%" }}>
                <div className="Td__text Td__text_center">
                  {(!!item.usd && new Intl.NumberFormat("ru").format((Number(item.usd) / 100).toFixed(2))) || 0}
                </div>
              </Tooltip>
            </td>

          </tr>
        ))}
      </Fragment>
    )
  );
};
