import React, {useRef, useState} from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
  useObject,
  Textarea,
  Input,
  jsonFormatText, isValidateNullText, Loading,
} from "@vokymlak/kabinet-ui";

function ModalWbProductAdd(props) {

  const [isRequest, setIsRequest] = useState(false);
  const inputArticle = useRef(null);

  const { notification } = useNotification();

  function validateString(str) {
    // Проверка на наличие только цифр
    const isNumeric = /^[0-9]+$/.test(str);

    // Проверка на отсутствие ведущих нулей
    const noLeadingZero = str[0] !== '0';

    // Возвращаем true, если строки удовлетворяют оба условия
    return isNumeric && noLeadingZero;
  }

  const changeAdd = () => {

    const article = jsonFormatText(inputArticle.current.value);

    if (isValidateNullText(article)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    if (!validateString(article)) {
      notification("Не правильный формат артикула", "red");
      return;
    }

    setIsRequest(true)

    const jsonData = JSON.stringify({
      wb_article: article,
    });

    axios
      .post("/api/wb-product/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsRequest(false)
        props.successfulRequest();
        notification("WB товар добавлен", "green");
        props.handleClose();
      })
      .catch((error) => {
        setIsRequest(false)
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeParser = () => {

    const article = jsonFormatText(inputArticle.current.value);

    if (isValidateNullText(article)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    if (!validateString(article)) {
      notification("Не правильный формат артикула", "red");
      return;
    }

    setIsRequest(true)

    const jsonData = JSON.stringify({
      wb_article: article,
    });

    axios
      .post("/api/wb-product/parser", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsRequest(false)
        props.successfulRequest();
        notification("WB товар добавлен", "green");
        props.handleClose();
      })
      .catch((error) => {
        setIsRequest(false)
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
      <Modal handleClose={() => props.handleClose()}>
        <div className="Form">
          <div className="Form__title">
            Новый
            <div className="Text__signature">Товар</div>
          </div>

          <div className="Form__field Form__field_last">
            <Input reference={inputArticle}
                   type="text"  label="Артикул WB *" />
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue"
                    onClick={!isRequest ? () => changeAdd() : () => null}>
              {!isRequest
                ? "Добавить"
                : <Loading size="medium" color="blue" />}
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue"
                    onClick={!isRequest ? () => changeParser() : () => null}>
              {!isRequest
                ? "Добавить (приоритетно)"
                : <Loading size="medium" color="blue" />}
            </button>
          </div>
        </div>
      </Modal>
  );
}

export default ModalWbProductAdd;
