import React from "react";
import styleContent from "../../../styles/modules/Block.module.scss";
import {MyCalculationListNew} from "./MyCalculationListNew";
import {MyCalculationTableHeadNew} from "./MyCalculationTableHeadNew";

export const MyCalculationTableNew = (props) => {
  const { list } = props;

  return (
    <div
      className={styleContent._block + " " + styleContent._block_bottom}
      style={{
        marginTop: 12
      }}
    >
      <div className={styleContent._block__title}>
        Юнит-экономика на товар
      </div>
      <div className={styleContent._block__content} style={{overflowX: "auto", width: "100%"}}>
      <table className="Table">
        <thead className="Thead">
          <MyCalculationTableHeadNew {...props} />
        </thead>
        <tbody>{!!list && <MyCalculationListNew {...props} />}</tbody>
      </table>
      </div>
    </div>
  );
};
