import React from "react";
import styleContent from "../../../styles/modules/Block.module.scss";
import {MyCalculationTwoListNew} from "./MyCalculationTwoListNew";
import {MyCalculationTwoTableHeadNew} from "./MyCalculationTwoTableHeadNew";

export const MyCalculationTwoTableNew = (props) => {
  const {
    list,
  } = props;

  return (
    <div
      className={styleContent._block + " " + styleContent._block_bottom}
      style={{
        marginTop: 12
      }}
    >
      <div className={styleContent._block__title}>
        Юнит-экономика на партию
      </div>
      <div className={styleContent._block__content} style={{overflowX: "auto", width: "100%"}}>
      <table className="Table">
        <thead className="Thead">
          <MyCalculationTwoTableHeadNew {...props} />
        </thead>
        <tbody>{!!list && <MyCalculationTwoListNew {...props} />}</tbody>
      </table>
      </div>
    </div>
  );
};
