import React, {useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {
  getErrorMessageByKey,
  Input,
  isValidateNullText,
  jsonFormatText,
  Modal,
  Select,
  Textarea,
  useNotification,
} from "@vokymlak/kabinet-ui";

export const ModalAddTariff = (props) => {
  const [isAvailable, setIsAvailable] = useState(false);
  const [isForSale, setIsForSale] = useState(false);
  const [isForTrial, setIsForTrial] = useState(false);

  const inputName = useRef(null);
  const inputInfo = useRef(null);

  const inputTariffCost = useRef(null);
  const inputRequestCount = useRef(null);
  const inputCountMonths = useRef(null);
  const inputTypeTag = useRef(null);
  const inputCountTrialDays = useRef(null);

  const {notification} = useNotification();

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const changeAdd = (event) => {
    event.preventDefault();

    const name = jsonFormatText(inputName.current.value);
    const info = jsonFormatText(inputInfo.current.value);

    // const tariffCost = jsonFormatText(inputTariffCost.current.value);
    const requestCount = jsonFormatText(inputRequestCount.current.value);
    // const countMonths = jsonFormatText(inputCountMonths.current.value);
    // const countTrialDays = jsonFormatText(inputCountTrialDays.current.value);
    const typeTag = inputTypeTag.current.value;

    let countTrialDays, countMonths, tariffCost = null;

    if (isForTrial) {
      countTrialDays = jsonFormatText(inputCountTrialDays.current.value);
    } else {
      tariffCost = jsonFormatText(inputTariffCost.current.value);
      countMonths = jsonFormatText(inputCountMonths.current.value);
    }

    if (
      // isValidateNullText(tariffCost) ||
      // isValidateNullText(requestCount) ||
      isValidateNullText(typeTag)
    ) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    if (
      isForTrial && isValidateNullText(countTrialDays)
    ) {
      notification("Укажите количество дней пробного периода", "red");
      return;
    }

    const jsonData = JSON.stringify({
      name: name,
      info: info,
      tariff_cost: !isForTrial ? numberConvert(tariffCost) : null,
      day_request_count: Number(requestCount),
      count_months: !isForTrial ? Number(countMonths) : null,
      is_available: isAvailable,
      is_for_sale: isForSale,
      tariff_type: {
        tag: typeTag,
      },
      is_for_trial: isForTrial,
      count_trial_days: isForTrial ? Number(countTrialDays) : null,
    });

    axios
      .post("/api/tariff/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Тариф добавлен", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">
          Новый
          <div className="Text__signature">Тариф</div>
        </div>

        <div className="Form__field">
          <Select
            isFixed={true}
            reference={inputTypeTag}
            options={[
              {
                value: "BETA",
                title: "Бета",
              },
              {
                value: "TRIAL",
                title: "Пробный",
              },
              {
                value: "ELEMENTARY",
                title: "Профи",
              },
              {
                value: "PROFESSIONAL",
                title: "Эксперт",
              },
              {
                value: "CORPORATE",
                title: "Гуру",
              },
            ]}
            label="Тип тарифа *"
          />
        </div>

        <div className="Form__field">
          <Input reference={inputName} label="Название"/>
        </div>

        <div className="Form__field">
          <Textarea reference={inputInfo} label="Описание"/>
        </div>

        {!isForTrial && (
          <div className="Form__field">
            <Input reference={inputTariffCost} label="Цена тарифа" type={"number"}/>
          </div>
        )}

        {!isForTrial && (
          <div className="Form__field">
            <Input reference={inputCountMonths} label="Количество месяцев" type={"number"}/>
          </div>
        )}

        <div className="Form__field">
          <Input reference={inputRequestCount} label="Количество запросов" type={"number"}/>
        </div>

        <div className="Form__field">
          <div
            className="Button__swith"
            style={{
              padding: "8px",
              width: "100%",
              border: isForTrial
                ? "2px solid #ffe082"
                : "2px dashed #20202015",
              backgroundColor: isForTrial ? "#ffe082" : "#ffffff",
              borderRadius: 6,
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => setIsForTrial(!isForTrial)}
          >
            <div
              className="Swith__text"
              style={{
                fontSize: 17,
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              Для пробного периода
            </div>
            <div
              className={
                "Swith Swith_gray" +
                ((isForTrial && " Swith_active Swith_active_gray") || "")
              }
            ></div>
          </div>
        </div>

        {isForTrial && (
          <div className="Form__field">
            <Input reference={inputCountTrialDays} label="Количетсво дней пробного периода" type={"number"}
                   isFixed={true}/>
          </div>
        )}

        <div className="Form__field">
          <div
            className="Button__swith"
            style={{
              padding: "8px",
              width: "100%",
              border: isAvailable
                ? "2px solid #ffe082"
                : "2px dashed #20202015",
              backgroundColor: isAvailable ? "#ffe082" : "#ffffff",
              borderRadius: 6,
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => setIsAvailable(!isAvailable)}
          >
            <div
              className="Swith__text"
              style={{
                fontSize: 17,
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              Активный
            </div>
            <div
              className={
                "Swith Swith_gray" +
                ((isAvailable && " Swith_active Swith_active_gray") || "")
              }
            ></div>
          </div>
        </div>

        <div className="Form__field Form__field_last">
          <div
            className="Button__swith"
            style={{
              padding: "8px",
              width: "100%",
              border: isForSale
                ? "2px solid #ffe082"
                : "2px dashed #20202015",
              backgroundColor: isForSale ? "#ffe082" : "#ffffff",
              borderRadius: 6,
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => setIsForSale(!isForSale)}
          >
            <div
              className="Swith__text"
              style={{
                fontSize: 17,
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              Выставлен на продажу
            </div>
            <div
              className={
                "Swith Swith_gray" +
                ((isForSale && " Swith_active Swith_active_gray") || "")
              }
            ></div>
          </div>
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Добавить
          </button>
        </div>
      </form>
    </Modal>
  );
};
