import React, {useState} from "react";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {Loading, useObject} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";
import MyCalculationPage from "./MyCalculationPage";
import {NavLink} from "react-router-dom";

function TariffMyCalculationWrapper() {
  const [isNotTariff, setIsNotTariff] = useState(true);
  const [isTariffRequest, setIsTariffRequest] = useState(true);

  const {
    object: [myTariff],
    update: [isUpdateMyTariff, setIsUpdateMyTariff],
  } = useObject({
    apiUrl: "/api/my/tariff",
    rootUrl: ROOT_URL,
    callbackResponse: () => {
      setIsNotTariff(false);
      setIsTariffRequest(false);
    },
    callbackError: (error) => {
      if (error.response.status === 402) {
        setIsNotTariff(true);
        setIsTariffRequest(false);
      }
    }
  });

  return (
    <>
      {(isTariffRequest || isNotTariff) && (
        <ScrollBlock style={{width: "100%", height: "100%"}}>
          <div className="Page">
            <div style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              flexWrap: "nowrap",
              justifyContent: "center",
              alignItems: "center",
            }}>
              {/*{isTariffRequest && (*/}
              {/*  <Loading/>*/}
              {/*)}*/}

              {!isTariffRequest && isNotTariff && (
                <div style={{
                  textAlign: "center",
                  fontSize: 19
                }}>
                  Раздел доступен пользователям после приобретения тарифного плана.
                  <div>
                    Перейти в раздел,
                    <NavLink style={{
                      color: "#0064ff",
                      marginLeft: 4,
                      textDecoration: "underline"
                    }} to={"/my/tariff"}>
                      Тарифный план
                    </NavLink>
                    .
                  </div>
                </div>
              )}
            </div>
          </div>
        </ScrollBlock>
      )}

      {!isTariffRequest && !isNotTariff &&
        !!myTariff && (
          <MyCalculationPage myTariff={myTariff}/>
        )}
    </>
  );
}

export default TariffMyCalculationWrapper;
