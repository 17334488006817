// Роль администратора.
export const ROLE_ADMINISTRATOR = 'ADMINISTRATOR'
export const ROLE_DIRECTOR = 'DIRECTOR'
export const ROLE_EMPLOYEE = 'EMPLOYEE'
export const ROLE_CLIENT = 'CLIENT'

// Код ошибки таймаута запроса.
export const ERROR_CODE_ECONNABORTED = 'ECONNABORTED'
// Код ошибки отмены запроса.
export const ERR_CANCELED = 'ERR_CANCELED'

// Код ошибки 400.
export const ERR_BAD_REQUEST = 'ERR_BAD_REQUEST'

// Тикет идея.
export const TICKET_TYPE_IDEA = 'IDEA'
// Тикет ошибка.
export const TICKET_TYPE_ERROR = 'ERROR'

// Тикет запланировано.
export const TICKET_STATUS_PLANNED = 'PLANNED'
// Тикет завершено.
export const TICKET_STATUS_COMPLETED = 'COMPLETED'
// Тикет отклонено.
export const TICKET_STATUS_REJECTED = 'REJECTED'
