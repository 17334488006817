import React from "react";
import { InfoPalletesTableHead } from "./InfoPalletesTableHead";
import { InfoPalletesList } from "./InfoPalletesList";

export const InfoPalletesTable = (props) => {
  const { list } = props;

  return (
      <table className="Table">
        <thead className="Thead">
          <InfoPalletesTableHead {...props} />
        </thead>
        <tbody>{!!list && <InfoPalletesList {...props} />}</tbody>
      </table>
  );
};
