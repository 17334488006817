import React, {useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {getErrorMessageByKey, Modal, useNotification, useObject,} from "@vokymlak/kabinet-ui";
import {useViewer} from "../../../components/Viewer";
import {toast} from "sonner";

function ModalMyProductView(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "api/my/product/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const [isDelete, setIsDelete] = useState(false);

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("/api/my/product/" + props.object.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        toast.success("Товар удалён");
        props.handleClose();
      })
      .catch((error) => {
        toast.error(getErrorMessageByKey(error.response.data.error));
      });
  };

  const {viewer} = useViewer();

  const changeViewer = (image, mimeType) => {
    viewer(image, mimeType);
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
        {!isDelete && (
          <form className="Form">
            <div className="Form__title">
              {!!object.wb_product && !!object.wb_product.name && object.wb_product.name}
              {!!object.wb_product && !!object.wb_product.name && <>&nbsp;</>}
              {/*{(!!object.wb_product && !!object.wb_product.wb_article_number && "(" + object.wb_product.wb_article_number + ")") || "" }*/}
              {/*<div className="Text__signature">Товар</div>*/}
              {/*{(!!object.wb_product && !!object.wb_product.wb_article_number && (*/}
              {/*  <div className="Text__signature">{object.wb_product.wb_article_number}</div>*/}
              {/*))}*/}
              {(!!object.wb_product && !!object.wb_product.wb_article_number &&
                <div style={{
                  fontSize: 17,
                  fontWeight: 400
                }}>
                  <a
                    target={"_blank"}
                    href={`https://www.wildberries.ru/catalog/${object.wb_product.wb_article_number}/detail.aspx`}
                    style={{
                      cursor: "pointer",
                      color: "#2196f3",
                      textDecoration: "underline",
                    }}
                  >
                    {object.wb_product.wb_article_number}
                  </a>
                </div>
              )}
            </div>

            {/*<div className="Form__field">*/}
            {/*  <div className="Field__label">*/}
            {/*      Артикул*/}
            {/*  </div>*/}
            {/*  <div className="Field__text">*/}
            {/*    {(!!object.wb_product && !!object.wb_product.wb_article_number &&*/}
            {/*      <a*/}
            {/*        target={"_blank"}*/}
            {/*        href={`https://www.wildberries.ru/catalog/${object.wb_product.wb_article_number}/detail.aspx`}*/}
            {/*        style={{*/}
            {/*          cursor: "pointer",*/}
            {/*          color: "#2196f3",*/}
            {/*          textDecoration: "underline",*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        {object.wb_product.wb_article_number}*/}
            {/*      </a>) || "-" }*/}
            {/*  </div>*/}
            {/*</div>*/}

            {!!object.wb_product && !!object.wb_product.status_update && object.wb_product.status_update === 200 && (
              <>
                {/*<div className="Form__field">*/}
                {/*  <div className="Field__label">*/}
                {/*    Название*/}
                {/*  </div>*/}
                {/*  <div className="Field__text">*/}
                {/*    {(!!object.wb_product && !!object.wb_product.name && object.wb_product.name) || "-"}*/}
                {/*  </div>*/}
                {/*</div>*/}

                <div className="Form__field">
                  <div className="Field__label">
                    Цена (руб)
                  </div>
                  <div className="Field__text" style={{
                    fontSize: 21,
                    fontWeight: 600
                  }}>
                    {(!!object.wb_product && !!object.wb_product.sale_price &&
                        new Intl.NumberFormat("ru-RU").format(
                          (object.wb_product.sale_price / 100).toFixed(2)
                        )) ||
                      "-"}
                  </div>
                </div>

                <div className="Form__field">
                  <div className="Field__label">
                    Вес упаковки (кг)
                  </div>
                  <div className="Field__text">
                    {(!!object.wb_product && !!object.wb_product.package_weight &&
                        new Intl.NumberFormat("ru-RU").format(
                          (object.wb_product.package_weight / 1000).toFixed(3)
                        )) ||
                      "-"}
                  </div>
                </div>

                <div className="Form__field">
                  <div className="Field__label">
                    Длина упаковки (см)
                  </div>
                  <div className="Field__text">
                    {(!!object.wb_product && !!object.wb_product.package_length &&
                        new Intl.NumberFormat("ru-RU").format(
                          (object.wb_product.package_length / 10).toFixed(2)
                        )) ||
                      "-"}
                  </div>
                </div>

                <div className="Form__field">
                  <div className="Field__label">
                    Высота упаковки (см)
                  </div>
                  <div className="Field__text">
                    {(!!object.wb_product && !!object.wb_product.package_height &&
                        new Intl.NumberFormat("ru-RU").format(
                          (object.wb_product.package_height / 10).toFixed(2)
                        )) ||
                      "-"}
                  </div>
                </div>

                <div className="Form__field">
                  <div className="Field__label">
                    Ширина упаковки (см)
                  </div>
                  <div className="Field__text">
                    {(!!object.wb_product && !!object.wb_product.package_width &&
                        new Intl.NumberFormat("ru-RU").format(
                          (object.wb_product.package_width / 10).toFixed(2)
                        )) ||
                      "-"}
                  </div>
                </div>

                <div className="Form__field">
                  {!!object.wb_product && !!object.wb_product.images &&
                    !!object.wb_product.images[0].data && (
                      <div
                        style={{
                          cursor: "pointer"
                        }}
                        key={object.uuid + ":" + object.wb_product.images[0].uuid}
                        onClick={(event) => (event.preventDefault(), changeViewer(object.wb_product.images[0].uuid, ROOT_URL + "/api/integration/wb/product/image/" + object.wb_product.images[0].uuid))}>
                        <img
                          src={`${ROOT_URL + "/api/integration/wb/product/image/" + object.wb_product.images[0].uuid}`}
                          width={292} style={{
                          borderRadius: 3
                        }
                        }/>
                      </div>
                    ) || "-"}
                </div>

                {/*<div className="Form__field">*/}
                {/*  <div className="Field__label">*/}
                {/*    Описание*/}
                {/*  </div>*/}
                {/*  <div className="Field__text" style={{*/}
                {/*    fontSize: 11*/}
                {/*  }}>*/}
                {/*    {(!!object.wb_product && !!object.wb_product.description && object.wb_product.description) || "-" }*/}
                {/*  </div>*/}
                {/*</div>*/}
              </>
            )}

            <div className="Form__actions">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>

              <div
                className="Action__link Action__link_gray"
                onClick={() => props.handleClose()}
              >
                Закрыть
              </div>
            </div>
          </form>
        )}

        {isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div className="Form__title">
              {!!object.wb_product && !!object.wb_product.name && object.wb_product.name}
              {/*&nbsp;{(!!object.wb_product && !!object.wb_product.wb_article_number && "(" + object.wb_product.wb_article_number + ")") || ""}*/}
              {(!!object.wb_product && !!object.wb_product.wb_article_number && (
                <div className="Text__signature">{object.wb_product.wb_article_number}</div>
              ))}
              {/*<div className="Text__signature">Удалить</div>*/}
            </div>

            <div className="Form__actions"
                 style={{
                   justifyContent: "flex-end"
                 }}>
              <div className="Action__link"
                   onClick={() => setIsDelete(false)}
                   style={{
                     marginRight: 24
                   }}>
                Отмена
              </div>
              <button className="Action__button Action__button_maxi Action__button_red">
                Удалить
              </button>
            </div>

            {/*<div className="Form__actions Form__actions_center">*/}
            {/*  <button className="Action__button Action__button_all Action__button_red">*/}
            {/*    Удалить*/}
            {/*  </button>*/}
            {/*</div>*/}

            {/*<div className="Form__actions Form__actions_center">*/}
            {/*  <div className="Action__link" onClick={() => setIsDelete(false)}>*/}
            {/*    Отмена*/}
            {/*  </div>*/}
            {/*</div>*/}
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalMyProductView;
