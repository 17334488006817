import React, {useEffect, useRef, useState} from "react";
import {useAction, useList, useObject} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";

function useWbProducts() {
  const [selectSubject, setSelectSubject] = useState('');

  const [isError, setIsError] = useState(false);

  const [selectSpecification, setSelectSpecification] = useState(null);
  const [modalTbProductAdd, setModalTbProductAdd] = useState(null);
  const [modalTbProductView, setModalTbProductView] = useState(null);
  const [modalProductEdit, setModalProductEdit] = useState(null);

  const {
    object: [exchangeRate],
    update: [isUpdateExchangeRate, setIsUpdateExchangeRate],
  } = useObject({
    apiUrl: "/api/info-exchange-rate",
    rootUrl: ROOT_URL,
  });

  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
    setApiUrlParams,
  } = useList({
    apiUrl: "/api/wb-products",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 100,
    isSearch: true,
    urlParams: {
      "subject": selectSubject
    }
  });

  const {
    list: [listProductCategories, _count, setListProductCategories],
    update: [isUpdateProductCategories, setIsUpdateProductCategories],
  } = useList({
    apiUrl: "/api/wb-products/subjects",
    rootUrl: ROOT_URL,
  });


  const changeSelectProductCategory = (subject) => {
    setSelectSubject(subject.length > 0 ? subject : '');
  };

  useEffect(() => {
    let params = null;
    if (isError) {
      params = {
        ...params,
        error: isError,
      };
    }
    if (selectSubject) {
      params = {
        ...params,
        subject: selectSubject,
      };
    }
    setApiUrlParams(params);
  }, [selectSubject, isError]);

  const inputSearchText = useRef(null);
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  const onChangeSearchText = () => {
    setSearchQuery(inputSearchText.current.value);
  };

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  const { setAction } = useAction("wb-products", () => updateList());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "wb-products"]);
    } else {
      setAction("wb-products");
    }
  }, [list]);

  const [modalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [modalObjectView, setIsModalObjectView] = useState(null);

  const [selectContracts, setSelectContracts] = useState([]);

  const getSelectList = (list, selects) => {
    const selectList = []

    if (!!list && !!selects) {
      selects.forEach(s => {
        let item;
        let isPush = false;
        list.forEach(i => {
          if (i.uuid === s) {
            item = i
            isPush = true;
          }
        })
        if (isPush) {
          selectList.push(item)
        }
      })
    }

    return selectList
  }

  const isSelectContract = (selectList, uuid) => {
    let isTrue = false
    selectList.forEach(u => {
      if (u === uuid) {
        isTrue = true;
      }
    });
    return isTrue
  }

  const isSelectAllContract = (list, selectList) => {
    return list.every(u => selectList.includes(u.uuid));
  };

  const changeSelectContract = (uuid) => {
    let isNew = true;
    let index = 0;

    selectContracts.forEach((u, i) => {
      if (u === uuid) {
        isNew = false;
        index = i;
      }
    });

    if (isNew) {
      setSelectContracts([...selectContracts, uuid]);
    } else {
      selectContracts.splice(index, 1);
      setSelectContracts([...selectContracts]);
    }
  };

  const changeSelectAllContract = (list, selectList) => {
    const selectContracts = selectList.slice();

    if (list && selectList) {
      list.forEach(item => {
        if (!selectContracts.includes(item.uuid)) {
          selectContracts.push(item.uuid);
        }
      });
    }

    setSelectContracts(selectContracts);
  };

  const changeSelectAllClearContract = (list, selectList) => {
    const selectContracts = selectList.filter(item => !list.some(u => u.uuid === item));

    setSelectContracts(selectContracts);
  };


  return {
    list,
    setList,
    count,

    setIsUpdateList,
    isUpdateList,

    setIsModalObjectAdd,
    modalObjectAdd,
    modalObjectView,
    setIsModalObjectView,

    setSelectSpecification,
    selectSpecification,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    exchangeRate,
    isUpdateExchangeRate,
    setIsUpdateExchangeRate,

    getSelectList,

    selectContracts,
    setSelectContracts,
    isSelectContract,
    isSelectAllContract,
    changeSelectContract,
    changeSelectAllContract,
    changeSelectAllClearContract,

    listProductCategories,
    setListProductCategories,
    isUpdateProductCategories,
    setIsUpdateProductCategories,
    changeSelectProductCategory,

    modalTbProductAdd,
    setModalTbProductAdd,
    modalTbProductView,
    setModalTbProductView,

    modalProductEdit,
    setModalProductEdit,

    isError,
    setIsError,
  };
}

export default useWbProducts;
