import React, {Fragment, useEffect, useState} from "react";
import {getAnalysisData, housekeeperCalculationNew} from "./housekeeperCalculationNew";

export const MyCalculationListNew = (props) => {
  const {
    exchangeRate,

    wbDelivery,
    wbReturnCost,

    object,

    item,

    isFbo,

    fulfillmentPrice,
    taxPercent,
    deliveryPreUsdPrice,
    marketingPercent,
    additionalExpenses,

    selectStorage,
    setSelectStorage,
  } = props;

  const [analysisData, setAnalysisData] = useState(null)
  const [housekeeperData, setHousekeeperData] = useState(null)

  useEffect(() => {
    setAnalysisData(getAnalysisData(object))
  }, [object])

  useEffect(() => {
    if (!!analysisData && !!item) {
      setHousekeeperData(housekeeperCalculationNew(
        item, object, exchangeRate,
        isFbo, fulfillmentPrice, taxPercent, deliveryPreUsdPrice, marketingPercent, additionalExpenses,
        wbDelivery, wbReturnCost, "BOX", analysisData,
        selectStorage
      ))
    }
  }, [
    item, analysisData,
    object, exchangeRate,
    isFbo, fulfillmentPrice, taxPercent, deliveryPreUsdPrice, marketingPercent, additionalExpenses,
    wbDelivery, wbReturnCost,
    selectStorage,
  ])

  return (
    !!housekeeperData &&
    <>

      <tr
        className={
          "Table__tr Table__tr_padding Table__tr_border"
        }
      >

        {/* Короб */}
        <td
          className="Table__td Table__td_border"
        >
          <div className="Td__text">
            <span style={{fontSize: 10}}>Короб</span><br/>
            {!!housekeeperData.wbWarehouse && housekeeperData.wbWarehouse.wb_warehouse.name}
          </div>
        </td>

        {/* Закупка */}
        <td
          className="Table__td Table__td_border"
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                    {(housekeeperData.purchasePrice && (<>{new Intl.NumberFormat("ru-RU").format(
                      (housekeeperData.purchasePrice / 100).toFixed(2))}<span
                      style={{
                        fontSize: 9,
                        opacity: .75,
                        marginLeft: 4
                      }}>руб.</span></>)) || "-"}
                </span>
          </div>
        </td>

        {/* Упаковка */}
        <td
          className="Table__td Table__td_border"
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                          {(fulfillmentPrice && (<>{new Intl.NumberFormat("ru-RU").format(
                            (fulfillmentPrice / 100).toFixed(2))}<span style={{
                            fontSize: 9,
                            opacity: .75,
                            marginLeft: 4
                          }}>руб.</span></>)) || "-"}
                </span>
          </div>
        </td>

        {/* Доставка */}
        <td
          className="Table__td Table__td_border"
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                    {(housekeeperData.deliveryPrice && (<>{new Intl.NumberFormat("ru-RU").format(
                      (housekeeperData.deliveryPrice / 100).toFixed(2))}<span
                      style={{
                        fontSize: 9,
                        opacity: .75,
                        marginLeft: 4
                      }}>руб.</span></>)) || "-"}
                </span>
          </div>
        </td>

        {/* Себестоимость */}
        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#bbdefb50"
          }}
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                    {(housekeeperData.costPrice && (<>{new Intl.NumberFormat("ru-RU").format(
                      (housekeeperData.costPrice / 100).toFixed(2))}<span
                      style={{
                        fontSize: 9,
                        opacity: .75,
                        marginLeft: 4
                      }}>руб.</span></>)) || "-"}
                </span>
          </div>
        </td>

        {/* Комиссия */}
        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#cc99ff50"
          }}
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                    {(housekeeperData.wbCommissionAmount && (<>{new Intl.NumberFormat("ru-RU").format(
                      (housekeeperData.wbCommissionAmount / 100).toFixed(2))}<span
                      style={{
                        fontSize: 9,
                        opacity: .75,
                        marginLeft: 4
                      }}>руб.</span></>)) || "-"}
                </span>
          </div>
        </td>

        {/* Логистика */}
        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#cc99ff50"
          }}
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                    {(housekeeperData.wbLogistic && (<>{new Intl.NumberFormat("ru-RU").format(
                      (housekeeperData.wbLogistic / 100).toFixed(2))}<span
                      style={{
                        fontSize: 9,
                        opacity: .75,
                        marginLeft: 4
                      }}>руб.</span></>)) || "-"}
                </span>
          </div>
        </td>

        {/* Хранение */}
        {isFbo && (
          <td
            className="Table__td Table__td_border"
            style={{
              backgroundColor: "#cc99ff50"
            }}
          >
            <div className="Td__text Td__text_center">
                  <span style={{}}>
                    {(housekeeperData.wbStorage && (<>{new Intl.NumberFormat("ru-RU").format(
                      (housekeeperData.wbStorage / 100).toFixed(2))}<span
                      style={{
                        fontSize: 9,
                        opacity: .75,
                        marginLeft: 4
                      }}>руб.</span></>)) || "-"}
                </span>
            </div>
          </td>
        )}


        {/* Расходы на рекламу */}
        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#cc99ff50"
          }}
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                    {(housekeeperData.adProductPrice && (<>{new Intl.NumberFormat("ru-RU").format(
                      (housekeeperData.adProductPrice / 100).toFixed(2))}<span
                      style={{
                        fontSize: 9,
                        opacity: .75,
                        marginLeft: 4
                      }}>руб.</span></>)) || "-"}
                </span>
          </div>
        </td>

        {/* Себестоимость с учетом комиссий ВБ, руб */}
        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#bbdefb50"
          }}
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                                 {(housekeeperData.costPriceWb && (<>{new Intl.NumberFormat("ru-RU").format(
                                   (housekeeperData.costPriceWb / 100).toFixed(2))}<span
                                   style={{
                                     fontSize: 9,
                                     opacity: .75,
                                     marginLeft: 4
                                   }}>руб.</span></>)) || "-"}
                </span>
          </div>
        </td>


        {/* Цена продажи */}
        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#c5e1a550"
          }}
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                    {(housekeeperData.wbPrice && (<>{new Intl.NumberFormat("ru-RU").format(
                      (housekeeperData.wbPrice / 100).toFixed(2))}<span
                      style={{
                        fontSize: 9,
                        opacity: .75,
                        marginLeft: 4
                      }}>руб.</span></>)) || "-"}
                </span>
          </div>
        </td>

        {/* Выплата на р/с */}
        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#c5e1a550"
          }}
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                    {(housekeeperData.paymentToThe && (<>{new Intl.NumberFormat("ru-RU").format(
                      (housekeeperData.paymentToThe / 100).toFixed(2))}<span
                      style={{
                        fontSize: 9,
                        opacity: .75,
                        marginLeft: 4
                      }}>руб.</span></>)) || "-"}
                </span>
          </div>
        </td>

        {/* Налог (УСН 6%) + 1% */}
        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#c5e1a550"
          }}
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                    {(housekeeperData.taxProductPrice && (<>{new Intl.NumberFormat("ru-RU").format(
                      (housekeeperData.taxProductPrice / 100).toFixed(2))}<span
                      style={{
                        fontSize: 9,
                        opacity: .75,
                        marginLeft: 4
                      }}>руб.</span></>)) || "-"}
                </span>
          </div>
        </td>

        {/* Прибыль с единицы */}
        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#c5e1a550"
          }}
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                    {(housekeeperData.netProfitOne && (<>{new Intl.NumberFormat("ru-RU").format(
                      (housekeeperData.netProfitOne / 100).toFixed(2))}<span
                      style={{
                        fontSize: 9,
                        opacity: .75,
                        marginLeft: 4
                      }}>руб.</span></>)) || "-"}
                </span>
          </div>
        </td>

        {/* Прибыль в процентах */}
        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#bbdefb50"
          }}
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                                 {(housekeeperData.profitPercent && (<>{new Intl.NumberFormat("ru-RU").format(
                                   (housekeeperData.profitPercent / 100).toFixed(2))}<span
                                   style={{
                                     fontSize: 9,
                                     opacity: .75,
                                     marginLeft: 4
                                   }}>%</span></>)) || "-"}
                </span>
          </div>
        </td>
      </tr>
    </>
  );
};
