import React from "react";

export const WbReturnCostTableHead = () => {
  return (
    <>
    <tr className="Table__tr Table__tr_padding Table__tr_border">
      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title"></div>
      </th>

      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title"></div>
      </th>

      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
        colSpan={3}
      >
        <div className="Th__title">Стоимость возврата брака и возврата по инициативе продавца за единицу товара, ₽</div>
      </th>

      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
        colSpan={2}
      >
        <div className="Th__title">Стоимость возврата брака и возврата по инициативе продавца за единицу товара при Грузовой доставке, ₽</div>
      </th>

      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
        colSpan={2}
      >
        <div className="Th__title">Стоимость возврата неопознанного складом товара за каждую единицу, ₽</div>
      </th>

    </tr>



      <tr className="Table__tr Table__tr_padding Table__tr_border">
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Обновлено</div>
        </th>

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Склад</div>
        </th>

      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title">Доставка на ПВЗ (базовая цена + доп. литр)</div>
      </th>

      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title">Доставка курьером (базовая цена + доп. литр)</div>
      </th>
      <th
        className="Table__th Table__th_border"
        style={{
          backgroundColor: "#dfdfdf",
        }}
      >
        <div className="Th__title">Обратная логистика невостребованного возврата</div>
      </th>

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Доставка на ПВЗ (базовая цена + доп. литр)</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Обратная логистика невостребованного возврата</div>
        </th>

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Доставка на ПВЗ</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Обратная логистика невостребованного возврата</div>
        </th>
    </tr>
      </>

  );
};
