import React from "react";

export const WbCommissionTableHead = () => {
  return (
      <tr className="Table__tr Table__tr_padding Table__tr_border">
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Обновлено</div>
        </th>

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Категория</div>
        </th>

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Предмет</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">WB номер</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Склад WB, %</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Склад поставщика - везу на склад WB, %</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Склад поставщика - везу самостоятельно до клиента, %</div>
        </th>
      </tr>
  );
};
