import React, {useState} from "react";
import ModalWbDeliveryImport from "./ModalWbDeliveryImport";
import {
  ButtonIcon,
} from "@vokymlak/kabinet-ui";
import { InfoBoxesTable } from "./InfoBoxesTable";
import ModalWbDeliveryEdit from "./ModalWbDeliveryEdit";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import styleTumbler from "../../../styles/modules/Tumbler.module.scss";
import {NavLink} from "react-router-dom";
import {InfoPalletesTable} from "./InfoPalletesTable";

function WbDeliveryPage(props) {
  const {
    list,
    setList,
    count,

    setIsUpdateList,
    isUpdateList,

    setModalImport,
    modalImport,

    setIsModalKeyEdit,
    isModalKeyEdit,
  } = props;


  const [tumbler, setTumbler] = useState("aa");

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="Page">
        <div
          className="Page__title"
          style={{
            marginTop: 0,
            marginBottom: 6,
          }}
        >
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
                marginRight: 6,
              }}
            >
              <div>
                WB коэффициенты складов
                {!!list && (
                  <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                    ({count})
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="Title__settings">
            <ul className={styleTumbler._}>
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((!!tumbler &&
                        tumbler === "aa" &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => setTumbler("aa")}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Короба
                </a>
              </li>
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((!!tumbler &&
                        tumbler === "ab" &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => setTumbler("ab")}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Монопаллеты
                </a>
              </li>
            </ul>
          </div>

          <div className="Title__settings">
            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 12 }}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            <ButtonIcon
              tooltipText={"Редактировать"}
              iconName={"custom-edit"}
              iconColor={"#202020"}
              size={36}
              style={{ marginLeft: 12 }}
              onClick={() => setIsModalKeyEdit(true)}
            />

            <ButtonIcon
              tooltipText={"Импорт"}
              iconName={"custom-cloud-upload"}
              iconColor={"#202020"}
              size={26}
              style={{ marginLeft: 12 }}
              onClick={() => setModalImport(true)}
            />
          </div>
        </div>

        {!!list && (
          <div
            className="Page__content"
            style={{
              position: "relative",
              height: "calc(100% - 48px - 26px - 2px - 10px - 28px)",
            }}
          >
            <div
              style={{
                overflow: "auto",
                width: "100%",
                height: "100%",
              }}
            >
              {!!tumbler && tumbler === "aa" && (
              <InfoBoxesTable {...props} />
                )}
              {!!tumbler && tumbler === "ab" && (
                <InfoPalletesTable {...props} />
              )}
            </div>
          </div>
        )}

        {modalImport && (
          <ModalWbDeliveryImport
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalImport(false)}
          />
        )}

        {isModalKeyEdit && (
          <ModalWbDeliveryEdit
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalKeyEdit(false)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default WbDeliveryPage;
