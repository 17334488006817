import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {Accounts} from "./ScreenAccounts/Accounts";
import {
  ROLE_ADMINISTRATOR,
  ROLE_CLIENT,
  ROLE_DIRECTOR,
  ROLE_EMPLOYEE,
} from "../config/constants";
import { Clients } from "./ScreenAccounts/Clients";
import { Employees } from "./ScreenAccounts/Employees";
import {
  useProfile,
  isProfileRoleTypeTags,
} from "@vokymlak/kabinet-ui";
import {HandbookRouting} from "./modules/ScreenHandbook/HandbookRouting";
import {WbParser} from "./ScreenParser/WbParser";
import {TaoBaoParser} from "./ScreenParser/TaoBaoParser";
import MyProducts from "./ScreenMy/MyProducts/MyProducts";
import {MyCalculations} from "./ScreenMy/MyCalculations";
import {MyCalculation} from "./ScreenMy/MyCalculation";
import {InfoExchangeRates} from "./ScreenData/InfoExchangeRates";
import {WbCommission} from "./ScreenData/WbCommission";
import {WbDelivery} from "./ScreenData/WbDelivery";
import {WbReturnCost} from "./ScreenData/WbReturnCost";
import {MpstatsSubjects} from "./ScreenData/MpstatsSubjects";
import {WbProducts} from "./ScreenData/WbProducts";
import {TariffsSetting} from "./ScreenTariffs/TariffsSetting";
import {SiteCallbacks} from "./ScreenSite/SiteCallbacks";
import WbAvailability from "./ScreenData/WbAvailability/WbAvailability";
import {TicketsRouting} from "./modules/ScreenTickets/TicketsRouting";
import {MyTariff} from "./ScreenMy/MyTariff";
import {TariffsSelling} from "./ScreenTariffs/TariffsSelling";
import {MyTop} from "./ScreenMy/MyTop";
import {TariffsPromoCodes} from "./ScreenTariffs/TariffsCodes";

export const Routing = () => {
  const { profile } = useProfile();

  return (
    !!profile && (
      <Routes>

        {isProfileRoleTypeTags(profile, [
          ROLE_CLIENT,
        ]) && (
          <>
            <Route path="/my/products" element={<MyProducts />} />

            <Route path="/my/calculations" element={<MyCalculations />} />
            <Route
              path="/my/calculation/:calculationUuid"
              element={<MyCalculation />}
            />

            <Route path="/my/tariff" element={<MyTariff />} />

            <Route path="/my/top" element={<MyTop />} />
          </>
        )}

        {isProfileRoleTypeTags(profile, [
          ROLE_EMPLOYEE,
        ]) && (
          <>
            <Route path="/employees" element={<Employees />} />
            <Route path="/clients" element={<Clients />} />

            <Route
              path="/tariffs-selling"
              element={<TariffsSelling />}
            />

            <Route path="/site-callbacks" element={<SiteCallbacks />} />
          </>
        )}

        {isProfileRoleTypeTags(profile, [
          ROLE_ADMINISTRATOR,
          ROLE_DIRECTOR,
        ]) && (
          <>
            <Route path="/employees" element={<Employees />} />
            <Route path="/clients" element={<Clients />} />

            <Route path="/tickets/*" element={<TicketsRouting />} />

            <Route path={"/handbook/*"}
                   element={<HandbookRouting />} />

            <Route path="/parser-wb" element={<WbParser />} />
            <Route path="/parser-taobao" element={<TaoBaoParser />} />

            <Route
              path="/info-exchange-rates"
              element={<InfoExchangeRates />}
            />
            <Route
              path="/wb-commission"
              element={<WbCommission />}
            />
            <Route
              path="/wb-delivery"
              element={<WbDelivery />}
            />
            <Route
              path="/wb-return-cost"
              element={<WbReturnCost />}
            />
            <Route
              path="/mpstats-subjects"
              element={<MpstatsSubjects />}
            />
            <Route
              path="/wb-availability"
              element={<WbAvailability />}
            />

            <Route
              path="/wb-products"
              element={<WbProducts />}
            />

            <Route
              path="/tariffs-selling"
              element={<TariffsSelling />}
            />

            <Route
              path="/tariffs-setting"
              element={<TariffsSetting />}
            />

            <Route
              path="/tariffs-codes"
              element={<TariffsPromoCodes />}
            />

            <Route
              path="/site-callbacks"
              element={<SiteCallbacks />}
            />
          </>
        )}

        {isProfileRoleTypeTags(profile, [ROLE_ADMINISTRATOR]) && (
          <>
            <Route path="/accounts" element={<Accounts />} />
          </>
        )}

        {isProfileRoleTypeTags(profile, [
          ROLE_CLIENT,
        ]) && (
          <Route
            exact
            path="/"
            element={<Navigate to="/my/tariff" replace />}
          />
        )}

        {isProfileRoleTypeTags(profile, [
          ROLE_DIRECTOR,
          ROLE_EMPLOYEE,
        ]) && (
          <Route
            exact
            path="/"
            element={<Navigate to="/clients" replace />}
          />
        )}

        {isProfileRoleTypeTags(profile, [ROLE_ADMINISTRATOR]) && (
          <Route
            exact
            path="/"
            element={<Navigate to="/accounts" replace />}
          />
        )}

        <Route path="/login" element={<Navigate to="/" />} />
        <Route path="/registration" element={<Navigate to="/" />} />
        <Route path="/restore" element={<Navigate to="/" />} />
      </Routes>
    )
  );
};
