import React, {useRef, useState} from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Input,
  Modal,
  jsonFormatText,
  isValidateNullText, Select, Textarea,
} from "@vokymlak/kabinet-ui";

export const ModalAddTariffPromoCode = (props) => {
  const inputPromoCode = useRef(null);
  const inputPercentDiscount = useRef(null);
  const inputTitle = useRef(null);
  const inputDescription = useRef(null);

  const { notification } = useNotification();

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const changeAdd = (event) => {
    event.preventDefault();

    const promoCode = jsonFormatText(inputPromoCode.current.value);
    const percentDiscount = jsonFormatText(inputPercentDiscount.current.value);
    const title = jsonFormatText(inputTitle.current.value);
    const description = jsonFormatText(inputDescription.current.value);

    if (
      // isValidateNullText(tariffCost) ||
      // isValidateNullText(requestCount) ||
      isValidateNullText(promoCode) ||
      isValidateNullText(percentDiscount) ||
      isValidateNullText(title)
    ) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      promo_code: promoCode,
      percent_discount: numberConvert(percentDiscount),
      title: title,
      description: description,
    });

    axios
      .post("/api/tariff-promo-code/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Промо-коде добавлен", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">
          Новый
          <div className="Text__signature">Промо-код</div>
        </div>

        <div className="Form__field">
          <Input reference={inputTitle} label="Название *"/>
        </div>

        <div className="Form__field">
          <Textarea reference={inputDescription} label="Описание"/>
        </div>

        <div className="Form__field">
          <Input reference={inputPromoCode} label="Промо-код *"/>
        </div>

        <div className="Form__field Form__field_last">
          <Input reference={inputPercentDiscount} label="Скидка по промо-коду, % *" type={"number"}/>
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Добавить
          </button>
        </div>
      </form>
    </Modal>
  );
};
