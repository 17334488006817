import React, {useEffect, useRef, useState} from "react";
import {useAction, useList, useNotification, useObject} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";

function useMyTariff() {
  const [isNotTariff, setIsNotTariff] = useState(true);

  const {
    object: [object],
    update: [isUpdateObject, setIsUpdateObject, changeUpdateObject],
  } = useObject({
    apiUrl: "/api/my/tariff",
    rootUrl: ROOT_URL,
    callbackResponse: () => {
      setIsNotTariff(false);
    },
    callbackError: (error) => {
      if (error.response.status === 402) {
        setIsNotTariff(true);
      }
    }
  });

  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, changeUpdateList],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
  } = useList({
    apiUrl: "/api/my/tariffs",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 7,
    isSearch: true,
  });

  useAction("my-tariff", () => setIsUpdateObject(!isUpdateObject));

  const inputSearchText = useRef(null);
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  const { setAction } = useAction(["my-tariff"], () => {
    changeUpdateList();
    changeUpdateObject();
  });

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "my-tariff"]);
    } else {
      setAction(["my-tariff"]);
    }
  }, [list]);

  const { notification } = useNotification();

  function onChangeSearchText() {
    setSearchQuery(inputSearchText.current.value);
  }

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  const [modalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [modalObjectView, setIsModalObjectView] = useState(null);
  const [modalObjectEdit, setModalObjectEdit] = useState(null);

  return {
    isNotTariff,

    list,
    setList,
    count,

    object,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    setIsUpdateObject,
    isUpdateObject,

    isUpdateList,
    setIsUpdateList,

    setIsModalObjectAdd,
    modalObjectAdd,
    modalObjectView,
    setIsModalObjectView,
    modalObjectEdit,
    setModalObjectEdit,
  };
}

export default useMyTariff;
