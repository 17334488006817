import React from "react";

export const MyProductsTableHead = (props) => {
  const {
    exchangeRate,
  } = props

  return (
      <tr className="Table__tr Table__tr_padding Table__tr_border">
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Товар</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Характеристики</div>
        </th>

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#ff400075",
          }}
        >
          <div className="Th__title">
                        <span>
                          Похожее&nbsp;с&nbsp;1688
            <br/>
            <span style={{
              fontSize: 10,
              opacity: .75
            }}>({(!!exchangeRate && !!exchangeRate.cny && (<span>1¥&nbsp;:&nbsp;{new Intl.NumberFormat("ru-RU").format(
              (exchangeRate.cny / 100).toFixed(2))}₽</span>)) || "-"})</span>
              </span>
        </div>
        </th>

      </tr>
  );
};
