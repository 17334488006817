import React, { Fragment } from "react";
import {
  Tooltip,
  TextSearchMarker,
} from "@vokymlak/kabinet-ui";
import moment from "moment";

export const WbAvailabilityList = (props) => {
  const {
    list,
    searchQuery,
  } = props;

  return (
    !!list && (
      <Fragment>
        {list.map(item => (
          <tr
            className={
              "Table__tr Table__tr_padding Table__tr_border Table__tr_hover "
            }
            key={"item_" + item.uuid}
          >
            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
            >
              <Tooltip
                text={"Обновлено"}
                style={{ width: "100%", height: "100%" }}
              >
                <div className="Td__text Td__text_center">
                  {!!item.updated_at
                    ? moment(item.updated_at).format("DD.MM.YYYY")
                    : moment(item.created_at).format("DD.MM.YYYY")}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
            >
              <Tooltip text={"Категория"} style={{ width: "100%", height: "100%" }}>
                <div className="Td__text Td__text_center">
                  {(!!item.wb_category && !!item.wb_category.name &&  (
                      <TextSearchMarker
                        text={item.wb_category.name}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
            >
              <Tooltip
                text={"Предмет"}
                style={{ width: "100%", height: "100%" }}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.wb_subject && !!item.wb_subject.name && (
                    <TextSearchMarker
                      text={item.wb_subject.name}
                      search={searchQuery}
                    />
                  )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
            >
              <Tooltip
                text={"WB номер"}
                style={{ width: "100%", height: "100%" }}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.wb_subject && !!item.wb_subject.wb_number && (
                      <TextSearchMarker
                        text={item.wb_subject.wb_number}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
                width: 190,
              }}
            >
              <Tooltip
                text={"Оборачиваемость"}
                style={{ width: 190, height: "100%" }}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.turnover && item.turnover) ||
                    "-"}
                </div>
              </Tooltip>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
                width: 190,
              }}
            >
              <Tooltip
                text={"Доступность товара"}
                style={{ width: 190, height: "100%" }}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.availability && item.availability) ||
                    "-"}
                </div>
              </Tooltip>
            </td>
          </tr>
        ))}
      </Fragment>
    )
  );
};
