import React, {useRef} from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  Input,
  Modal,
  jsonFormatText,
  isValidateNullText,
} from "@vokymlak/kabinet-ui";
import {toast} from "sonner";

export const ModalAddMyPromoCode = (props) => {
  const inputPromoCode = useRef(null);

  const changeAdd = (event) => {
    event.preventDefault();

    const promoCode = jsonFormatText(inputPromoCode.current.value);

    if (
      isValidateNullText(promoCode)
    ) {
      toast.error('Укажите промо-код');
      return;
    }

    const jsonData = JSON.stringify({
      promo_code: promoCode,
    });

    axios
      .post("/api/my/tariff/promo-code", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then((response) => {
        // console.log(response)
        props.successfulRequest(response.data);
        toast.success('Промо-код применён');
        props.handleClose();
      })
      .catch((error) => {
        console.log(error)
        if (error.response.status === 404) {
          toast.error('Промо-код не действителен');
        }
      });
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">
          Применить промо-код
        </div>

        <div className="Form__field">
          <Input reference={inputPromoCode} label="Промо-код *"/>
        </div>

        <div className="Form__actions"
             style={{
               justifyContent: "flex-end"
             }}>
          <div className="Action__link"
               onClick={() => props.handleClose()}
               style={{
                 marginRight: 24
               }}>
            Отмена
          </div>
          <button className="Action__button Action__button_maxi Action__button_blue">
            Применить
          </button>
        </div>
      </form>
    </Modal>
  );
};
