import React, {createRef, useState} from 'react';
import "../components/Auth.scss";
import {InputText} from "../components/InputText/InputText";
import {Link} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {z} from "zod";
import {toast} from "sonner";
import logotype from "../components/logotype.png";
import {ReactComponent as IconForwardToInbox} from "../components/forward-to-inbox.svg";

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

export const Restore = () => {
  const [isRestoreSuccess, setIsRestoreSuccess] = useState(false);
  const [isRequest, setIsRequest] = useState(false);

  // Данные формы.
  const [formData, setFormData] = useState({
    email: null,
  });

  // Данные об ошибках полей формы.
  const [formErrors, setFormErrors] = useState({
    email: false,
  });


  const [recaptchaError, setRecaptchaError] = useState(false);

  const recaptchaRef = createRef();

  const changeFormData = (event) => {
    let {name, value} = event.target;

    let newValue = value.trim()

    if (newValue === '') {
      newValue = null
    }

    setFormData(prevData => ({
      ...prevData,
      [name]: newValue
    }));

    setFormErrors(prevError => ({
      ...prevError,
      [name]: false
    }));
  };

  const handleRestore = async () => {
    const FormDataSchema = z
      .object({
        email: z.string().email(),
      });

    try {
      FormDataSchema.parse({...formData});
    } catch (error) {
      if (error instanceof z.ZodError) {
        // Обработка ошибок.
        error.issues.forEach(err => {
          err.path.forEach(name => {
            setFormErrors(prevError => ({
              ...prevError,
              [name]: true
            }));
          })
        })
      }

      toast.error(`Пожалуйста, заполните корректно поле электронной почты`);
      return;
    }

    if (recaptchaError) {
      await onRecaptchaResolved();
    } else {
      recaptchaRef.current.execute();
    }
  }

  async function onRecaptchaResolved() {
    const jsonData = JSON.stringify({
      email: formData.email.toLowerCase(),
    });

    setIsRequest(true);

    axios.post(
      'api/auth/restore',
      jsonData,
      {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(() => {
        setIsRequest(false);
        setIsRestoreSuccess(true);
      })
      .catch(error => {
        setIsRequest(false);
        console.error(error);
        toast.error('Что то пошло не так, попробуйте восстановить доступ чуть позже');
      });
  }

  return (
    <div className="Auth"
         onContextMenu={(event) => event.preventDefault()}
    >
      {!isRestoreSuccess && (
        <div className="Form show">
          <div className="Form__logotype">
            <img src={logotype} alt={"MPUNIT"} height={23}/>
            <div style={{
              fontSize: 15,
              fontWeight: 400,
              marginTop: 6,
              marginBottom: 24
            }}>
              Личный кабинет
            </div>
          </div>

          <div className="Form__title">
            Восстановление доступа
            <div className="Text__signature">
              Введите адрес электронной почты, на него придет письмо с инструкцией по восстановлению пароля
            </div>
          </div>

          <div className="Form__field">
            <InputText
              label={"Электронная почта"}
              isRequired={true}
              name={'email'}
              type={'email'}
              value={formData.email}
              onChange={changeFormData}
              isError={formErrors['email']}
            />
          </div>

          <div className="Form__actions Form__actions_center">
            <button
              className="Action__button Action__button_all Action__button_login"
              style={{
                opacity: isRequest && 0.5,
                cursor: isRequest && "default"
              }}
              onClick={isRequest ? () => null : () => handleRestore()}
            >
              Отправить инструкцию
            </button>
          </div>

          <div className="Form__links">
            <Link className="Action__link Action__link_blue" to={"/login"}>Обратно к входу</Link>
          </div>
        </div>
      )}

      {isRestoreSuccess && (
        <div className="Form show" style={{
          backgroundColor: "inherit",
          boxShadow: "none",
          width: 426
        }}>
          <div className="Form__title" style={{
            textAlign: "center",
            opacity: .33
          }}>
            Восстановление доступа
          </div>

          <div className="Form__field" style={{
            display: "flex",
            justifyContent: "center"
          }}>
            <IconForwardToInbox height={128} fill={"#0064ff"}/>
          </div>

          <div className="Form__field" style={{
            display: "flex",
            justifyContent: "center"
          }}>
            <div style={{
              textAlign: "center"
            }}>
              <div style={{
                borderRadius: 48,
                padding: "8px 16px",
                backgroundColor: "#dbdbdb",
                display: "inline-block"
              }}>
                {formData.email}
              </div>
            </div>
          </div>

          <div className="Form__field" style={{
            display: "flex",
            justifyContent: "center"
          }}>
            <div style={{
              textAlign: "center"
            }}>
              Если у данного адреса электронной почты есть привязанная учётная запись, мы отправим на него инструкцию по восстановлению пароля.<br/>
              На всякий случай, пожалуйста, проверьте папку "Спам" в вашем почтовом ящике, если письмо не пришло в вашу основную входящую.
            </div>
          </div>

          <div className="Form__links">
            <Link className="Action__link Action__link_blue" to={"/login"}>Обратно к входу</Link>
          </div>
        </div>
      )}

      <ReCAPTCHA
        ref={recaptchaRef}
        locale="ru"
        size="invisible"
        sitekey={RECAPTCHA_KEY}
        onChange={onRecaptchaResolved}
        onErrored={() => setRecaptchaError(true)}
      />
    </div>
  );
}
