import React, {useState} from "react";
import ModalMpstatsSubjectsImport from "./ModalMpstatsSubjectsImport";
import {
  ButtonIcon, Pagination, Search, Select,
} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import ModalMpstatsSubjectsEdit from "./ModalMpstatsSubjectsEdit";
import {MpstatsSubjectsList} from "./MpstatsSubjectsList";
import ModalMpstatsSubjectPathImport from "./ModalMpstatsSubjectPathImport";
import ModalMpstatsSellersPathImport from "./ModalMpstatsSellersPathImport";
import ModalMpstatsGeographyPathImport from "./ModalMpstatsGeographyPathImport";
import ModalMpstatsSummaryPathImport from "./ModalMpstatsSummaryPathImport";
import ModalMpstatsSummaryScheduler from "./ModalMpstatsSummaryScheduler";
import ModalMpstatsSubjectScheduler from "./ModalMpstatsSubjectScheduler";
import ModalMpstatsGeographyScheduler from "./ModalMpstatsGeographyScheduler";
import ModalMpstatsSellersScheduler from "./ModalMpstatsSellersScheduler";
import ModalMpstatsCheckRatesPathImport from "./ModalMpstatsCheckRatesPathImport";
import ModalMpstatsCheckRatesScheduler from "./ModalMpstatsCheckRatesScheduler";

function MpstatsSubjectsPage(props) {
  const {
    list,
    setList,
    count,

    setIsUpdateList,
    isUpdateList,

    setModalImport,
    modalImport,

    setIsModalKeyEdit,
    isModalKeyEdit,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setModalItemSubjectImport,
    modalItemSubjectImport,
    setModalItemSellersImport,
    modalItemSellersImport,
    setModalItemGeographyImport,
    modalItemGeographyImport,
    setModalItemSummaryImport,
    modalItemSummaryImport,
    setModalItemCheckRatesImport,
    modalItemCheckRatesImport,
  } = props;

  const [isModalSummaryScheduler, setIsModalSummaryScheduler] = useState(false);
  const [isModalGeographyScheduler, setIsModalGeographyScheduler] = useState(false);
  const [isModalSellersScheduler, setIsModalSellersScheduler] = useState(false);
  const [isModalSubjectScheduler, setIsModalSubjectScheduler] = useState(false);
  const [isModalCheckRatesScheduler, setIsModalCheckRatesScheduler] = useState(false);

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="Page">
        <div
          className="Page__title"
          style={{
            marginTop: 0,
            marginBottom: 6,
          }}
        >
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
                marginRight: 6,
              }}
            >
              <div>
                MPSTATS данные
                {!!list && (
                  <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                    ({count})
                  </span>
                )}
              </div>
            </div>
            <span style={{ width: 220, marginLeft: 16 }}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{ marginLeft: 8 }}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 12 }}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 25,
                      title: 25,
                    },
                    {
                      value: 50,
                      title: 50,
                    },
                    {
                      value: 75,
                      title: 75,
                    },
                    {
                      value: 100,
                      title: 100,
                    },
                    {
                      value: 250,
                      title: 250,
                    },
                    {
                      value: 500,
                      title: 500,
                    },
                    {
                      value: 1000,
                      title: 1000,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{ marginLeft: 8 }}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}

            <ButtonIcon
              tooltipText={"Редактировать"}
              iconName={"custom-edit"}
              iconColor={"#202020"}
              size={36}
              style={{ marginLeft: 12 }}
              onClick={() => setIsModalKeyEdit(true)}
            />

            <ButtonIcon
              tooltipText={"Планировщик рекламной ставки"}
              iconName={"custom-priority"}
              iconColor={"#202020"}
              size={24}
              style={{ marginLeft: 12 }}
              onClick={() => setIsModalCheckRatesScheduler(true)}
            />

            <ButtonIcon
              tooltipText={"Планировщик топ товаров"}
              iconName={"custom-priority"}
              iconColor={"#202020"}
              size={24}
              style={{ marginLeft: 12 }}
              onClick={() => setIsModalSubjectScheduler(true)}
            />

            <ButtonIcon
              tooltipText={"Планировщик географии"}
              iconName={"custom-priority"}
              iconColor={"#202020"}
              size={24}
              style={{ marginLeft: 12 }}
              onClick={() => setIsModalGeographyScheduler(true)}
            />

            <ButtonIcon
              tooltipText={"Планировщик топ продавцов"}
              iconName={"custom-priority"}
              iconColor={"#202020"}
              size={24}
              style={{ marginLeft: 12 }}
              onClick={() => setIsModalSellersScheduler(true)}
            />

            <ButtonIcon
              tooltipText={"Планировщик суммарного отчёта"}
              iconName={"custom-priority"}
              iconColor={"#202020"}
              size={24}
              style={{ marginLeft: 12 }}
              onClick={() => setIsModalSummaryScheduler(true)}
            />

            <ButtonIcon
              tooltipText={"Импорт"}
              iconName={"custom-cloud-upload"}
              iconColor={"#202020"}
              size={26}
              style={{ marginLeft: 12 }}
              onClick={() => setModalImport(true)}
            />
          </div>
        </div>

        {!!list && (
          <div
            className="Page__content"
            style={{
              position: "relative",
            }}
          >
            {!!list && <MpstatsSubjectsList {...props} />}
          </div>
        )}

        {modalImport && (
          <ModalMpstatsSubjectsImport
            list={list}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalImport(false)}
          />
        )}

        {modalItemSubjectImport && (
          <ModalMpstatsSubjectPathImport
            object={modalItemSubjectImport}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemSubjectImport(null)}
          />
        )}

        {modalItemSellersImport && (
          <ModalMpstatsSellersPathImport
            object={modalItemSellersImport}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemSellersImport(null)}
          />
        )}

        {modalItemGeographyImport && (
          <ModalMpstatsGeographyPathImport
            object={modalItemGeographyImport}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemGeographyImport(null)}
          />
        )}

        {modalItemSummaryImport && (
          <ModalMpstatsSummaryPathImport
            object={modalItemSummaryImport}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemSummaryImport(null)}
          />
        )}

        {modalItemCheckRatesImport && (
          <ModalMpstatsCheckRatesPathImport
            object={modalItemCheckRatesImport}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemCheckRatesImport(null)}
          />
        )}

        {isModalKeyEdit && (
          <ModalMpstatsSubjectsEdit
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalKeyEdit(false)}
          />
        )}

        {isModalSummaryScheduler && (
          <ModalMpstatsSummaryScheduler
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalSummaryScheduler(false)}
          />
        )}

        {isModalSubjectScheduler && (
          <ModalMpstatsSubjectScheduler
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalSubjectScheduler(false)}
          />
        )}

        {isModalSellersScheduler && (
          <ModalMpstatsSellersScheduler
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalSellersScheduler(false)}
          />
        )}

        {isModalGeographyScheduler && (
          <ModalMpstatsGeographyScheduler
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalGeographyScheduler(false)}
          />
        )}

        {isModalCheckRatesScheduler && (
          <ModalMpstatsCheckRatesScheduler
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalCheckRatesScheduler(false)}
          />
        )}

      </div>
</ScrollBlock>
  );
}

export default MpstatsSubjectsPage;
