import React, {useState} from "react";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {Icon, useObject} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";
import MyTopPage from "./MyTopPage";
import {NavLink} from "react-router-dom";

function TariffMyTopWrapper() {
  const [isNotTariff, setIsNotTariff] = useState(true);
  const [isTariffRequest, setIsTariffRequest] = useState(true);

  const {
    object: [myTariff],
    update: [isUpdateMyTariff, setIsUpdateMyTariff],
  } = useObject({
    apiUrl: "/api/my/tariff",
    rootUrl: ROOT_URL,
    callbackResponse: () => {
      setIsNotTariff(false);
      setIsTariffRequest(false);
    },
    callbackError: (error) => {
      if (error.response.status === 402) {
        setIsNotTariff(true);
        setIsTariffRequest(false);
      }
    }
  });

  return (
    <>
      {(isTariffRequest || isNotTariff ||
        (!!myTariff && (
          myTariff.tariff_type.tag === 'ELEMENTARY' ||
          myTariff.tariff_type.tag === 'PROFESSIONAL'))) && (
        <ScrollBlock style={{width: "100%", height: "100%"}}>
          <div className="Page">
            <div style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              flexWrap: "nowrap",
              justifyContent: "center",
              alignItems: "center",
            }}>
              {/*{isTariffRequest && (*/}
              {/*  <Loading/>*/}
              {/*)}*/}

              {!isTariffRequest && (isNotTariff ||
                (!!myTariff && (
                  myTariff.tariff_type.tag === 'ELEMENTARY' ||
                  myTariff.tariff_type.tag === 'PROFESSIONAL'))) && (
                <iframe width="560" height="315" src="https://www.youtube.com/embed/HFV-16VaVsA?si=2qA0xvAN7cYyVmFP"
                        title="YouTube video player" frameBorder="0"
                        style={{
                          borderRadius: 11,
                          marginBottom: 24
                        }}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
              )}

              {!isTariffRequest && (isNotTariff ||
                (!!myTariff && (
                  myTariff.tariff_type.tag === 'ELEMENTARY' ||
                  myTariff.tariff_type.tag === 'PROFESSIONAL'))) && (
                <div style={{
                  fontSize: 17,
                  marginBottom: 42,
                  backgroundColor: "white",
                  padding: "24px",
                  width: 560,
                  borderRadius: 11,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}>
                  <div style={{
                    marginRight: 24,
                    height: 48
                  }}>
                    <Icon name={"custom-info"} size={42} color={'#20202075'}/>
                  </div>
                  <div>
                    {/*ТОП ниш:*/}
                    {/*<br/>*/}
                    В данном разделе вы можете посмотреть ниши которые в данный момент пользуются наибольшим спросом для
                    продаж на маркетплейсе.<br/>
                    Так же в этом разделе показаны наиболее популярные товары в этих нишах, для быстрого поиска идей
                    товаров для реализации.
                  </div>
                </div>
              )}

              {!isTariffRequest && (isNotTariff ||
                (!!myTariff && (
                  myTariff.tariff_type.tag === 'ELEMENTARY' ||
                  myTariff.tariff_type.tag === 'PROFESSIONAL'))) && (
                <>
                  {(!!myTariff && (
                    myTariff.tariff_type.tag === 'ELEMENTARY' ||
                    myTariff.tariff_type.tag === 'PROFESSIONAL')) && (
                    <div style={{
                      textAlign: "center",
                      fontSize: 19
                    }}>
                      Раздел доступен пользователям тарифного плана "Гуру".
                      <div>
                        Перейти в раздел,
                        <NavLink style={{
                          color: "#0064ff",
                          marginLeft: 4,
                          textDecoration: "underline"
                        }} to={"/my/tariff"}>
                          Тарифный план
                        </NavLink>
                        .
                      </div>
                    </div>
                  )}

                  {!isTariffRequest && isNotTariff && (
                    <div style={{
                      textAlign: "center",
                      fontSize: 19
                    }}>
                      Раздел доступен пользователям после приобретения тарифного плана.
                      <div>
                        Перейти в раздел,
                        <NavLink style={{
                          color: "#0064ff",
                          marginLeft: 4,
                          textDecoration: "underline"
                        }} to={"/my/tariff"}>
                          Тарифный план
                        </NavLink>
                        .
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </ScrollBlock>
      )}

      {!isTariffRequest && !isNotTariff &&
        (!!myTariff && (
          myTariff.tariff_type.tag === 'BETA' ||
          myTariff.tariff_type.tag === 'TRIAL' ||
          myTariff.tariff_type.tag === 'CORPORATE')) && (
          <MyTopPage myTariff={myTariff}/>
        )}
    </>
  );
}

export default TariffMyTopWrapper;
