import {useRef, useState} from "react";
import {ROOT_URL} from "../../../config/url";
import axios from "axios";
import {getErrorMessageByKey, useNotification} from "@vokymlak/kabinet-ui";
import moment from "moment";

export default function useParser() {
    const [isRequest, setIsRequest] = useState(false);
    const [object, setObject] = useState(null);

    const inputLinkUrl = useRef(null);
    const {notification} = useNotification();

    const onChangeParser = () => {
        setIsRequest(true)
        setObject(null)

        const linkUrl = inputLinkUrl.current.value

        if (!linkUrl || linkUrl.length === 0) {
            return
        }

        const jsonData = JSON.stringify({
            article: linkUrl,
        });

        axios.post('/api/parser/wb', jsonData,
            {
                withCredentials: false,
                baseURL: ROOT_URL,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('tokenSession')
                }
            })
            .then(response => {
                setIsRequest(false)
                setObject(!!response.data ? response.data : null)
            }).catch(error => {
            setIsRequest(false)
            notification(getErrorMessageByKey(error.response.data.error), "red");
        });
    }

    const openLink = (url) => {
        // Открываем ссылку в новом окне браузера.
        // noopener,noreferrer,resizable,scrollbars,status (доп. параметры).
        const newWindow = window.open(url, "_blank", "noopener, noreferrer");
        if (newWindow) {
            newWindow.opener = null;
        }
    };

    const option = (product) => {
        if (!product.price_history) {
            return null
        }

        const allPriceHistory = [...product.price_history]

        if (!!product.detail && !!product.detail.data &&
          !!product.detail.data.products) {
            let currentPrice = null;

            product.detail.data.products.forEach(p => {
                if (product.article === String(p.id)) {
                    currentPrice = {
                        "dt": moment().unix(),
                        "price": {
                            "RUB": p.salePriceU
                        }
                    }
                }
            })

            if (!!currentPrice) {
                allPriceHistory.push(currentPrice)
            }
        }

        return {
            grid: {
                left: "8px",
                right: "8px",
                bottom: "8px",
                top: "8px",
                containLabel: true,
            },
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "cross",
                    crossStyle: {
                        color: "#999",
                    },
                },
            },
            // xAxis: [
            //     {
            //         type: "category",
            //         data: priceHistory.map(pH => {return pH.dt}),
            //         axisPointer: {
            //             type: "shadow",
            //         },
            //     },
            // ],
            // yAxis: [
            //     {
            //         type: "value",
            //         // name: 'Контрольные точки',
            //         min: 0,
            //         max: 100000,
            //         interval: 50000,
            //         // axisLabel: {
            //         //     formatter: "{value}",
            //         // },
            //     },
            // ],
            // series: [
            //     {
            //         name: "Цена",
            //         type: "bar",
            //         data: priceHistory.map(pH => {return pH.price.RUB}),
            //         itemStyle: { color: "#0064ff" },
            //     },
            // ],

            xAxis: {
                type: 'category',
                data: allPriceHistory.map(pH => {return moment.unix(pH.dt).format("DD.MM")}),
            },
            yAxis: {
                type: 'value',
                min: 0,
                max: allPriceHistory.map(pH => {return pH.price.RUB / 100}).max,
                interval: allPriceHistory.map(pH => {return pH.price.RUB / 100}).max / 2,
            },
            series: [
                {
                    data: allPriceHistory.map(pH => {return pH.price.RUB / 100}),
                    type: 'line',
                    smooth: true
                }
            ],
            aria: {
                enabled: true,
                decal: {
                    show: true,
                },
            },
        };
    };

    return {
        object,
        inputLinkUrl,
        onChangeParser,
        isRequest,
        openLink,
        option,
    };
}
