import React, {useRef} from "react";
import {Input, Modal, useNotification,} from "@vokymlak/kabinet-ui";

function ModalMyCalculationEdit(props) {
  const {
    fulfillmentPrice,
    setFulfillmentPrice,
    taxPercent,
    setTaxPercent,
    deliveryPreUsdPrice,
    setDeliveryPreUsdPrice,
    marketingPercent,
    setMarketingPercent,
    additionalExpenses,
    setAdditionalExpenses,
  } = props

  const inputPrice = useRef(null);
  const inputAmount = useRef(null);
  const inputWeight = useRef(null);
  const inputMarketingPercent = useRef(null);
  const inputAdditionalExpenses = useRef(null);

  const {notification} = useNotification();

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : 0;
  };

  const changeEdit = (event) => {
    event.preventDefault();

    const weight = inputWeight.current.value;
    const price = inputPrice.current.value;
    const amount = inputAmount.current.value;
    const marketing = inputMarketingPercent.current.value;
    const additionalExpenses = inputAdditionalExpenses.current.value;

    setDeliveryPreUsdPrice(numberConvert(price))
    setFulfillmentPrice(numberConvert(amount))
    setTaxPercent(numberConvert(weight))
    setMarketingPercent(numberConvert(marketing))
    setAdditionalExpenses(numberConvert(additionalExpenses))

    props.handleClose();
  };

  return (
    <Modal handleClose={() => props.handleClose()}>

      <form className="Form" onSubmit={(event) => changeEdit(event)}>
        <div className="Form__title">
          Параметры расчёта
        </div>

        <div className="Form__field">
          <Input
            reference={inputPrice}
            type="number"
            label="Доставка 1 кг. (долл.)"
            value={deliveryPreUsdPrice / 100}
            maxNumber={99_999}
            minNumber={0}
            stepNumber={0.01}
          />
        </div>

        <div className="Form__field">
          <Input
            reference={inputAmount}
            type="number"
            label="Фулфилмент (руб.)"
            value={fulfillmentPrice / 100}
            maxNumber={999_999}
            minNumber={0}
            stepNumber={0.01}
          />
        </div>

        <div className="Form__field">
          <Input
            reference={inputMarketingPercent}
            type="number"
            label="Расходы на рекламу (%)"
            value={marketingPercent / 100}
            maxNumber={100}
            minNumber={0}
            stepNumber={0.01}
          />
        </div>

        <div className="Form__field Form__field_last">
          <Input
            reference={inputWeight}
            type="number"
            label="Налог (%)"
            value={taxPercent / 100}
            maxNumber={100}
            minNumber={0}
            stepNumber={0.01}
          />
        </div>

        <div className="Form__field Form__field_last">
          <Input
            reference={inputAdditionalExpenses}
            type="number"
            label="Доп. расходы (руб.)"
            value={additionalExpenses / 100}
            maxNumber={999_999_999}
            minNumber={0}
            stepNumber={0.01}
          />
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Применить
          </button>
        </div>

      </form>
    </Modal>
  );
}

export default ModalMyCalculationEdit;
