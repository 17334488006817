import React, {useEffect, useState} from "react";
import {getAnalysisData, housekeeperCalculationNew} from "./housekeeperCalculationNew";

export const MyCalculationTwoListNew = (props) => {
  const {
    exchangeRate,

    wbDelivery,
    wbReturnCost,

    object,

    item,

    isFbo,

    fulfillmentPrice,
    taxPercent,
    deliveryPreUsdPrice,
    marketingPercent,
    additionalExpenses,

    selectStorage,
    setSelectStorage,
  } = props;

  const [analysisData, setAnalysisData] = useState(null)
  const [housekeeperData, setHousekeeperData] = useState(null)

  useEffect(() => {
    setAnalysisData(getAnalysisData(object))
  }, [object])

  useEffect(() => {
    if (!!analysisData && !!item) {
      setHousekeeperData(housekeeperCalculationNew(
        item, object, exchangeRate,
        isFbo, fulfillmentPrice, taxPercent, deliveryPreUsdPrice, marketingPercent, additionalExpenses,
        wbDelivery, wbReturnCost, "BOX", analysisData,
        selectStorage
      ))
    }
  }, [
    item, analysisData,
    object, exchangeRate,
    isFbo, fulfillmentPrice, taxPercent, deliveryPreUsdPrice, marketingPercent, additionalExpenses,
    wbDelivery, wbReturnCost,
    selectStorage,
  ])

  return (
    !!housekeeperData &&
    <>

      <tr
        className={
          "Table__tr Table__tr_padding Table__tr_border"
        }
      >

        {/* Объем закупки, шт */}
        <td
          className="Table__td Table__td_border"
          rowSpan={2}
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                    {(housekeeperData.countBatches && (<>{new Intl.NumberFormat("ru-RU").format(
                      (housekeeperData.countBatches))}<span
                      style={{
                        fontSize: 9,
                        opacity: .75,
                        marginLeft: 4
                      }}>шт.</span></>)) || "-"}
                </span>
          </div>
        </td>

        {/* Оборачиваемость, д */}
        <td
          className="Table__td Table__td_border"
          rowSpan={2}
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                                 {(housekeeperData.turnover && (<>{new Intl.NumberFormat("ru-RU").format(
                                   (housekeeperData.turnover))}<span
                                   style={{
                                     fontSize: 9,
                                     opacity: .75,
                                     marginLeft: 4
                                   }}>д.</span></>)) || "-"}
                </span>
          </div>
        </td>

        {/* Процент выкупа */}
        <td
          className="Table__td Table__td_border"
          rowSpan={2}
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                                 {(housekeeperData.buyout && (<>{new Intl.NumberFormat("ru-RU").format(
                                   (housekeeperData.buyout / 100).toFixed(2))}<span
                                   style={{
                                     fontSize: 9,
                                     opacity: .75,
                                     marginLeft: 4
                                   }}>%</span></>)) || "-"}
                </span>
          </div>
        </td>

        {/* Потенциальные продажи/мес, шт */}
        <td
          className="Table__td Table__td_border"
          rowSpan={2}
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                                 {(housekeeperData.potentialSalesMonth && (<>{new Intl.NumberFormat("ru-RU").format(
                                   (housekeeperData.potentialSalesMonth))}<span
                                   style={{
                                     fontSize: 9,
                                     opacity: .75,
                                     marginLeft: 4
                                   }}>шт.</span></>)) || "-"}
                </span>
          </div>
        </td>

        {/* Вес партии, кг */}
        <td
          className="Table__td Table__td_border"
          rowSpan={2}
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                                 {(housekeeperData.batchesWeight && (<>{new Intl.NumberFormat("ru-RU").format(
                                   (housekeeperData.batchesWeight / 1000).toFixed(3))}<span
                                   style={{
                                     fontSize: 9,
                                     opacity: .75,
                                     marginLeft: 4
                                   }}>кг.</span></>)) || "-"}
                </span>
          </div>
        </td>

        {/* Вложения в партию, руб */}
        <td
          className="Table__td Table__td_border"
          rowSpan={2}
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                                 {(housekeeperData.costSalesMonth && (<>{new Intl.NumberFormat("ru-RU").format(
                                   (housekeeperData.costSalesMonth / 100).toFixed(2))}<span
                                   style={{
                                     fontSize: 9,
                                     opacity: .75,
                                     marginLeft: 4
                                   }}>руб.</span></>)) || "-"}
                </span>
          </div>
        </td>

        {/* Короб */}
        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#ffe08250"
          }}
        >
          <div className="Td__text">
            <span style={{fontSize: 10}}>Короб</span><br/>
            {!!housekeeperData.wbWarehouse && housekeeperData.wbWarehouse.wb_warehouse.name}
          </div>
        </td>

        {/* Расход на рекламу, % */}
        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#ffe08250"
          }}
          rowSpan={2}
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                                 {(housekeeperData.adBudget && (<>{new Intl.NumberFormat("ru-RU").format(
                                   (housekeeperData.adBudget / 100).toFixed(2))}<span
                                   style={{
                                     fontSize: 9,
                                     opacity: .75,
                                     marginLeft: 4
                                   }}>руб.</span></>)) || "-"}
                </span>
          </div>
        </td>

        {/* Коммисия */}
        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#ffe08250"
          }}
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                                 {(housekeeperData.commissionBudget && (<>{new Intl.NumberFormat("ru-RU").format(
                                   (housekeeperData.commissionBudget / 100).toFixed(2))}<span
                                   style={{
                                     fontSize: 9,
                                     opacity: .75,
                                     marginLeft: 4
                                   }}>руб.</span></>)) || "-"}
                </span>
          </div>
        </td>

        {/* Логистика */}
        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#ffe08250"
          }}
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                                 {(housekeeperData.logisticBudget && (<>{new Intl.NumberFormat("ru-RU").format(
                                   (housekeeperData.logisticBudget / 100).toFixed(2))}<span
                                   style={{
                                     fontSize: 9,
                                     opacity: .75,
                                     marginLeft: 4
                                   }}>руб.</span></>)) || "-"}
                </span>
          </div>
        </td>

        {/* Хранение */}
        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#ffe08250"
          }}
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                                 {(housekeeperData.storageBudget && (<>{new Intl.NumberFormat("ru-RU").format(
                                   (housekeeperData.storageBudget / 100).toFixed(2))}<span
                                   style={{
                                     fontSize: 9,
                                     opacity: .75,
                                     marginLeft: 4
                                   }}>руб.</span></>)) || "-"}
                </span>
          </div>
        </td>

        {/* Валовый доход с партии, руб */}
        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#bbdefb50"
          }}
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                                 {(housekeeperData.grossParticipation && (<>{new Intl.NumberFormat("ru-RU").format(
                                   (housekeeperData.grossParticipation / 100).toFixed(2))}<span
                                   style={{
                                     fontSize: 9,
                                     opacity: .75,
                                     marginLeft: 4
                                   }}>руб.</span></>)) || "-"}
                </span>
          </div>
        </td>

        {/* Расходы на партию, руб */}
        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#bbdefb50"
          }}
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                                 {(housekeeperData.partyExpenses && (<>{new Intl.NumberFormat("ru-RU").format(
                                   (housekeeperData.partyExpenses / 100).toFixed(2))}<span
                                   style={{
                                     fontSize: 9,
                                     opacity: .75,
                                     marginLeft: 4
                                   }}>руб.</span></>)) || "-"}
                </span>
          </div>
        </td>

        {/* Налог с партии, руб */}
        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#bbdefb50"
          }}
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                                 {(housekeeperData.taxParticipation && (<>{new Intl.NumberFormat("ru-RU").format(
                                   (housekeeperData.taxParticipation / 100).toFixed(2))}<span
                                   style={{
                                     fontSize: 9,
                                     opacity: .75,
                                     marginLeft: 4
                                   }}>руб.</span></>)) || "-"}
                </span>
          </div>
        </td>

        {/* Прибыль с партии, руб */}
        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#bbdefb50"
          }}
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                                 {(housekeeperData.profitParty && (<>{new Intl.NumberFormat("ru-RU").format(
                                   (housekeeperData.profitParty / 100).toFixed(2))}<span
                                   style={{
                                     fontSize: 9,
                                     opacity: .75,
                                     marginLeft: 4
                                   }}>руб.</span></>)) || "-"}
                </span>
          </div>
        </td>

        {/* Прибыль в процентах партия */}
        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#bbdefb50"
          }}
        >
          <div className="Td__text Td__text_center">
                  <span style={{}}>
                                 {(housekeeperData.profitPercentParty && (<>{new Intl.NumberFormat("ru-RU").format(
                                   (housekeeperData.profitPercentParty / 100).toFixed(2))}<span
                                   style={{
                                     fontSize: 9,
                                     opacity: .75,
                                     marginLeft: 4
                                   }}>%</span></>)) || "-"}
                </span>
          </div>
        </td>

      </tr>
    </>
  );
};
