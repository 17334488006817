import React, { Fragment } from "react";
import {
  Tooltip,
  TextSearchMarker, Input, ButtonIcon, jsonFormatText, isValidateNullText, getErrorMessageByKey, Icon,
} from "@vokymlak/kabinet-ui";
import moment from "moment";
import {ROOT_URL} from "../../../config/url";
import axios from "axios";
import {toast} from "sonner";

export const WbProductsList = (props) => {
  const {
    list,

    setIsUpdateList,
    isUpdateList,

    setSelectSpecification,
    selectSpecification,
    searchQuery,
    setIsModalObjectView,

    changeSelectContract,
    isSelectContract,
    selectContracts,

    setModalTbProductAdd,
    setModalTbProductView,

    modalProductEdit,
    setModalProductEdit,
  } = props;

  const changeRefresh = (article) => {
    const jsonData = JSON.stringify({
      wb_article: article,
    });

    axios
      .post("/api/wb-product/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        toast.success("В очереди на обновление");
        setIsUpdateList(!isUpdateList);
      })
      .catch((error) => {
        toast.error(getErrorMessageByKey(error.response.data.error));
      });
  };

  return (
    !!list && (
      <Fragment>
        {list.map(item => (
          <tr
            className={
              "Table__tr Table__tr_padding Table__tr_border Table__tr_hover " +
              (!!selectSpecification && selectSpecification === item.uuid
                ? " Table__tr_focus "
                : "") +
              (!!item.is_update && item.is_update
                ? " Table__tr_update "
                : "")
            }
            key={"item_" + item.uuid}
            style={{
              backgroundColor: !!item.status_update && item.status_update !== 200 && "#ff110033"
            }}
          >
            {/*<td*/}
            {/*  className="Table__td Table__td_border"*/}
            {/*  style={{*/}
            {/*    width: 24*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <div className="Td__text" style={{*/}
            {/*    width: 32,*/}
            {/*  }}>*/}
            {/*    <Input*/}
            {/*      type={"checkbox"}*/}
            {/*      onChange={() => changeSelectContract(item.uuid)}*/}
            {/*      isChecked={isSelectContract(selectContracts, item.uuid)}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</td>*/}

            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
              onClick={() => (
                setIsModalObjectView(item), setSelectSpecification(item.uuid)
              )}
            >
              <div className="Td__text Td__text_center">
                {(!!item.wb_subject && !!item.wb_subject.name && (
                    <TextSearchMarker
                      text={item.wb_subject.name}
                      search={searchQuery}
                    />
                  )) ||
                  "-"}
              </div>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
              onClick={() => setSelectSpecification(item.uuid)}
            >
              <div className="Td__text Td__text_center">
                {(!!item.wb_article_number && (
                    <a
                      target={"_blank"}
                      href={`https://www.wildberries.ru/catalog/${item.wb_article_number}/detail.aspx`}
                      style={{
                        cursor: "pointer",
                        color: "#2196f3",
                        textDecoration: "underline",
                      }}
                    >
                      <TextSearchMarker
                        text={item.wb_article_number}
                        search={searchQuery}
                      />
                    </a>
                  )) ||
                  "-"}
              </div>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
              onClick={() => (
                setIsModalObjectView(item), setSelectSpecification(item.uuid)
              )}
            >
              <div className="Td__text Td__text_center">
                {!!item.images &&
                  !!item.images[0].data && (
                    <div
                      key={item.uuid + ":" + item.images[0].uuid}
                      style={{
                        backgroundImage: `url("${ROOT_URL + "/api/integration/wb/product/image/" + item.images[0].uuid}")`,
                        borderRadius: 5,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        width: 100,
                        height: 74
                      }}
                    >
                    </div>
                  ) || "-"}
              </div>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
              onClick={() => (
                setIsModalObjectView(item), setSelectSpecification(item.uuid)
              )}
            >
              <div className="Td__text Td__text_center" style={{
                minWidth: 200
              }}>
                {(!!item.name && (
                    <TextSearchMarker
                      text={item.name}
                      search={searchQuery}
                    />
                  )) ||
                  "-"}
              </div>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
                width: 190,
              }}
              onClick={() => (
                setIsModalObjectView(item), setSelectSpecification(item.uuid)
              )}
            >
              <div className="Td__text Td__text_center">
                {(!!item.sale_price &&
                    new Intl.NumberFormat("ru-RU").format(
                      (item.sale_price / 100).toFixed(2)
                    )) ||
                  "-"}
              </div>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
              onClick={() => (
                setIsModalObjectView(item), setSelectSpecification(item.uuid)
              )}
            >
              <div className="Td__text Td__text_center">
                {(!!item.package_weight &&
                  new Intl.NumberFormat("ru-RU").format(
                    (item.package_weight / 1000).toFixed(3)
                  )) || "-"}
              </div>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
                width: 190,
              }}
              onClick={() => (
                setIsModalObjectView(item), setSelectSpecification(item.uuid)
              )}
            >
              <div className="Td__text Td__text_center">
                {(!!item.package_length &&
                    new Intl.NumberFormat("ru-RU").format(
                      (item.package_length / 10).toFixed(2)
                    )) ||
                  "-"}
                х
                {(!!item.package_height &&
                    new Intl.NumberFormat("ru-RU").format(
                      (item.package_height / 10).toFixed(2)
                    )) ||
                  "-"}
                х
                {(!!item.package_width &&
                    new Intl.NumberFormat("ru-RU").format(
                      (item.package_width / 10).toFixed(2)
                    )) ||
                  "-"}
              </div>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
                width: 190,
              }}
              onClick={() => (
                setIsModalObjectView(item), setSelectSpecification(item.uuid)
              )}
            >
              <div className="Td__text Td__text_center">
                {(!!item.created_at && moment(item.created_at).format("YYYY.MM.DD HH:mm")) ||
                  "-"}
              </div>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
                width: 190,
              }}
              onClick={() => (
                setIsModalObjectView(item), setSelectSpecification(item.uuid)
              )}
            >
              <div className="Td__text Td__text_center">
                {(!!item.updated_at && moment(item.updated_at).format("YYYY.MM.DD HH:mm")) ||
                  "-"}
              </div>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
                width: 190,
              }}
              onClick={() => (
                setIsModalObjectView(item), setSelectSpecification(item.uuid)
              )}
            >
              <div className="Td__text Td__text_center">
                {(!!item.status_update && item.status_update) ||
                  "-"}
              </div>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
                width: 0,
              }}
              onClick={() => (
                setIsModalObjectView(item), setSelectSpecification(item.uuid)
              )}
            >
              <div className="Td__text Td__text_center">
                {!!item.taobao_products && (
                  <Icon name={'custom-circle-good'} size={24} color={'#ff400075'}/>
                  )}
              </div>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
                width: 0,
              }}
            >
              <div className="Td__text Td__text_center">
                <ButtonIcon
                  tooltipText={"Обновить"}
                  iconName={"custom-change-circle"}
                  iconColor={"#0064ff"}
                  size={28}
                  onClick={() => changeRefresh(item.wb_article_number)}
                />
              </div>
            </td>
          </tr>
        ))}
      </Fragment>
    )
  );
};
